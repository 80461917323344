import React from 'react';
import PermIdentity from '@material-ui/icons/PermIdentity';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import TitleCard from '../../mui-components/Card/TitleCard';
import SpreadsheetIncomesFilter from '../../containers/domain/spreadsheet-income/SpreadsheetIncomesFilter';

const MySpreadsheetIncomesFilterView = () => (
  <GridContainer>
    <ItemGrid xs={12} sm={12} md={9} lg={7}>
      <TitleCard
        icon={PermIdentity}
        titleColor="primary"
        title="Filtro das minhas receitas"
        content={<SpreadsheetIncomesFilter page="my-spreadsheet-incomes-listing" />}
      />
    </ItemGrid>
  </GridContainer>
);

export default MySpreadsheetIncomesFilterView;
