import employeeActions from '../../../actions/employeeActions';
import employeeInputFields from './employeeInputFields';
import generateEntityCreator from '../../../hocs/generateEntityCreator';

const EmployeeCreator = generateEntityCreator({
  entityActions: employeeActions,
  returnRoutePath: 'employees-listing',
  fields: employeeInputFields,
});

export default EmployeeCreator;
