// ##############################
// // // RankingListItem styles
// #############################

import {
  card,
  defaultFont,
  grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const kanbanContainerStyle = {
  card: {
    ...card,
    boxShadow: 'none'
  }
};

export default kanbanContainerStyle;
