const partnerEnterpriseInputFields = [
  {
    name: 'name',
    label: 'Nome',
  },
  {
    name: 'cnpj',
    label: 'CNPJ',
    width: 120,
  },
  {
    name: 'municipalRegistration',
    label: 'Inscrição municipal',
    width: 150,
  },
  {
    name: 'defaultAssessorPercentageOfTransfer',
    label: 'Repasse do assessor (padrão)',
    type: 'percentage',
    width: 230,
  },
  {
    name: 'assessorTransferDelay',
    label: 'Meses para pagamento',
    type: 'number',
    width: 200,
  },
  {
    name: 'street',
    label: 'Rua',
  },
  {
    name: 'number',
    label: 'Número',
    width: 90,
  },
  {
    name: 'complement',
    label: 'Complemento',
    width: 170,
  },
  {
    name: 'neighborhood',
    label: 'Bairro',
    width: 170,
  },
  {
    name: 'city',
    label: 'Cidade',
    width: 170,
  },
  {
    name: 'state',
    label: 'Estado',
    type: 'select',
    options: ['AC','AL','AM','AP','BA','CE','DF','ES','GO','MA','MG','MS','MT','PA','PB','PE','PI','PR','RJ','RN','RO','RR','RS','SC','SE','SP','TO'],
    width: 120,
  },
  {
    name: 'country',
    label: 'País',
    type: 'select',
    options: ['Brasil', 'Estados Unidos'],
    width: 150,
  },
  {
    name: 'cep',
    label: 'CEP',
    width: 90,
  },
];

export default partnerEnterpriseInputFields;
