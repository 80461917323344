import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

const style = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: '260px',
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: 10000,
  },
  spinnerWrapper: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    color: '#cca656',
  },
};

const OverlaySpinner = ({ visible, classes }) => {
  if (visible) {
    return (
      <div className={classes.overlay}>
        <div className={classes.spinnerWrapper}>
          <CircularProgress size={50} color="inherit" />
        </div>
      </div>
    );
  }
  return null;
};

OverlaySpinner.propTypes = {
  classes: PropTypes.object.isRequired,
  visible: PropTypes.bool,
};

OverlaySpinner.defaultProps = {
  visible: true,
};

export default withStyles(style)(OverlaySpinner);
