// ##############################
// // // SimpleCard styles
// #############################

import {
  card,
  defaultFont,
  successColor,
  dangerColor,
  infoColor,
  purpleColor,
  grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const simpleCardStyle = {
  card: {
    ...card,
    margin: "40px 0 0",
    boxShadow: "none"
  },
  cardContent: {
    textAlign: "right",
    paddingTop: "10px",
    padding: "15px 0px"
  },
  cardCategory: {
    fontSize: "1.3em",
    marginBottom: "0",
    color: grayColor,
    margin: "0 0 10px",
    ...defaultFont
  },
  cardTitle: {
    margin: "0",
    ...defaultFont,
    fontSize: "1.825em"
  },

  orangeSimpleCardColor: {
    color: '#cda855'
  },
  greenSimpleCardColor: {
    color: successColor
  },
  redSimpleCardColor: {
    color: dangerColor
  },
  blueSimpleCardColor: {
    color: infoColor
  },
  purpleSimpleCardColor: {
    color: purpleColor
  },
  normalSimpleCardFontWeight: {
    fontWeight: "normal"
  },
  boldSimpleCardFontWeight: {
    fontWeight: "bold"
  },
  bolderSimpleCardFontWeight: {
    fontWeight: "bolder"
  },
  lighterSimpleCardFontWeight: {
    fontWeight: "lighter"
  },
};

export default simpleCardStyle;
