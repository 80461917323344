import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import AuthRoute from './AuthRoute';
import pagesRoutes from './routes/pagesRoutes';

const PagesRouter = () => (
  <Switch>
    {pagesRoutes.map((route, key) => {
      if (route.redirect) {
        return <Redirect from={route.path} to={route.pathTo} key={key} />;
      }
      if (route.collapse) {
        return null;
      }
      return <AuthRoute {...route} key={key} />;
    })}
  </Switch>
);

export default PagesRouter;
