import myClientActions from '../../../actions/myClientActions';
import clientInputFields from './clientInputFields';
import generateEntityEditor from '../../../hocs/generateEntityEditor';

const MyClientEditor = generateEntityEditor({
  formName: 'clientEditionForm',
  entitySingularString: 'client',
  entityPluralString: 'clients',
  entityActions: myClientActions,
  returnRoutePath: '/my-clients-listing',
  fields: clientInputFields,
});

export default MyClientEditor;
