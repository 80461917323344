import employeeActions from '../../../actions/employeeActions';
import employeeTableColumns from './employeeTableColumns';
import generateEntityTable from '../../../hocs/generateEntityTable';

const EmployeesTable = generateEntityTable({
  entityPluralString: 'employees',
  entityActions: employeeActions,
  editorRoutePath: '/employee-edition',
  columns: employeeTableColumns,
});

export default EmployeesTable;
