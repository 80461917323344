
const clearToZeroIfNaN = (value) => {
  if (!value || isNaN(value)) {
    return 0;
  }
  return value;
};

const renderDefaultValue = value => value || ' ';

const renderDate = (value) => {
  if (!value) return '';
  if (value.includes('/')) return value; // já tá em dd/mm/yyyy
  const date = new Date(value);
  return date.toLocaleDateString('pt-BR');
};

const renderNumber = (value, column) => {
  const { precision = 0 } = column;
  const number = clearToZeroIfNaN(value);
  return number.toFixed(precision);
};

const renderDottedNumber = (value) => {
  const number = clearToZeroIfNaN(value);
  return number.toLocaleString('pt-BR');
};

const renderBrCurrency = (value) => {
  const number = clearToZeroIfNaN(value);
  return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

const renderPercentage = (value, column) => {
  const { precision = 2 } = column;
  const number = clearToZeroIfNaN(value);
  return `${number.toLocaleString('pt-BR', {minimumFractionDigits: precision, maximumFractionDigits: precision})}%`;
};

export default (type, value) => {
  switch(type) {
    case 'date': return renderDate(value);
    case 'number': return renderNumber(value, type);
    case 'dottedNumber': return renderDottedNumber(value);
    case 'brCurrency': return renderBrCurrency(value);
    case 'currency': return renderBrCurrency(value);
    case 'percentage': return renderPercentage(value, type);
    default: return renderDefaultValue(value);
  }
};
