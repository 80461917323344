const isNullOrUndefined = value => value === null || value === undefined;

export default (objectsArray, propertyName) => (
  objectsArray.sort((obj1, obj2) => {
    const property1 = obj1[propertyName];
    const property2 = obj2[propertyName];

    if (isNullOrUndefined(property1)) {
      if (isNullOrUndefined(property2)) {
        return 0;
      }
      return 1;
    }
    if (isNullOrUndefined(property2)) {
      return -1;
    }

    if (property1 < property2) {
      return -1;
    } else if (property1 > property2) {
      return 1;
    }
    return 0;
  })
);
