import createReducer from '../../../../utils/createReducer';
import types from '../../../../actions/types';

const initialState = {
  visible: false,
  place: 'tr',
  message: '',
  color: 'info',
};

const reducer = createReducer(initialState, {

  [types.NOTIFICATION_SHOW]: (state, action) => ({
    ...state,
    visible: true,
    message: action.message || initialState.message,
    color: action.color || initialState.color,
    place: action.place || initialState.place,
  }),

  [types.NOTIFICATION_CLOSE]: state => ({
    ...state,
    visible: false,
  }),

});

export const getNotification = state => state.ducks.NotificationsManager;

export default reducer;
