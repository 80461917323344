
import React from 'react';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import RegularCard from '../../mui-components/Card/RegularCard';
import ContractTypesTable from '../../containers/domain/contract-type/ContractTypesTable';

const ContractTypesListingView = () => (
  <GridContainer>
    <ItemGrid xs={12}>
      <RegularCard
        title="Lista de tipos de contrato"
        content={<ContractTypesTable />}
      />
    </ItemGrid>
  </GridContainer>
);

export default ContractTypesListingView;
