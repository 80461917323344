import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { isLoading } from './reducer';
import OverlaySpinner from '../../../../components/miscellaneous/OverlaySpinner';

const ApiRequestLoadingManager = ({ loading }) => (
  <OverlaySpinner visible={loading} />
);

ApiRequestLoadingManager.propTypes = {
  loading: PropTypes.bool,
};

ApiRequestLoadingManager.defaultProps = {
  loading: false,
};

const mapStateToProps = state => ({
  loading: isLoading(state),
});

export default connect(mapStateToProps)(ApiRequestLoadingManager);
