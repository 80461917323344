import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import { Card, CardContent, Typography } from '@material-ui/core';
import ItemGrid from '../../mui-components/Grid/GridItem';

import funnelItemStyle from "assets/jss/material-dashboard-pro-react/components/funnelItemStyle";

function FunnelItem({ ...props }) {
  const {
    classes,
    title,
    description,
    size,
    customColor
  } = props;
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <ItemGrid xs={12} sm={12} md={size} lg={size} style={customColor} className={classes.cardButton}>
          <Typography component="h2" className={classes.cardTitle}>{title}</Typography>
        </ItemGrid>
      </CardContent>
      <Typography component="h2" className={classes.cardDescription}>{description}</Typography>
    </Card>
  );
}

FunnelItem.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.node,
  description: PropTypes.node,
  size: PropTypes.node,
  customColor: PropTypes.node,
};

export default withStyles(funnelItemStyle)(FunnelItem);
