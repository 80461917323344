
import spreadsheetIncomeActions from '../../../actions/spreadsheetIncomeActions';
import spreadsheetIncomeTableColumns, {
  spreadsheetIncomeTableSummaries,
  spreadsheetIncomeTableLeftFixedColumns,
} from './spreadsheetIncomeTableColumns';
import generateEntityTable from '../../../hocs/generateEntityTable';

const SpreadsheetIncomesTable = generateEntityTable({
  entityPluralString: 'spreadsheetIncomes',
  entityActions: spreadsheetIncomeActions,
  editorRoutePath: '/spreadsheet-income-edition',
  columns: spreadsheetIncomeTableColumns,
  leftFixedColumns: spreadsheetIncomeTableLeftFixedColumns,
  summaries: spreadsheetIncomeTableSummaries,
  operations: [],
});

export default SpreadsheetIncomesTable;
