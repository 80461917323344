
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import RegularCard from '../../mui-components/Card/RegularCard';
import HeaderCard from '../../mui-components/Cards/HeaderCard';
import FunnelItem from '../../mui-components/Cards/FunnelItem';
import KanbanContainer from '../../mui-components/Kanban/KanbanContainer';
import KanbanColumn from '../../mui-components/Kanban/KanbanColumn';
import KanbanCard from '../../mui-components/Kanban/KanbanCard';

import sanitizeValue from '../../utils/sanitizeValue';
import cacheActions from '../../actions/cacheActions';
import reportActions from '../../actions/reportActions';
import notificationActions from '../../containers/general/notification/NotificationsManager/actions';
import { getEntity } from '../../reducers/entitiesReducer';

import avatar from '../../assets/img/faces/marc.jpg';

class TasksReportView extends React.Component {
  componentWillMount() {
    this.onMount();
  }

  onMount = () => {
    this.props.dispatch(cacheActions.cleanCache());
    this.fetchEntities().catch(this.onFetchFail);
  };

  onFetchFail = apiResponse => this.props.dispatch(notificationActions.handleApiError(apiResponse));

  fetchEntities = () => this.props.dispatch(reportActions.tasks());

  render () {
    const {entity} = this.props;
    const funnelColors = ["#2E4B48","#64B6AC","#94ECBE","#CEB477","#D6C18F"]
    const funnelSizes = [12,10,8,6,4]

    return (
      <GridContainer>
        <ItemGrid xs={12} sm={12} md={12} lg={12} className='last'>
          <HeaderCard
            cardTitle="Clientes"
            headerColor="orange"
            content={
              entity && entity.funnel.map((item, index) => {
                return <FunnelItem key={index} title={item.name} description={`Clientes: ${item.count} (Atual) • ${item.total} (Histórico)  • ${sanitizeValue('percentage', item.percentage)}`} size={funnelSizes[index]} customColor={{background: funnelColors[index]}} />
              })
            }
          />
        </ItemGrid>
        <ItemGrid xs={12} sm={12} md={12} lg={12}>
          <HeaderCard
            cardTitle="Kanban"
            headerColor="orange"
            content={
              <KanbanContainer content={
                entity && entity.kanban.map((column, index) => {
                  return <KanbanColumn key={index} name={column.name} content={
                    column.cards.map((card, index) => {
                      return <KanbanCard client={card.client} task={card.task} columnName={column.name} />
                    })
                  } />
                })
              } />
            }
          />
        </ItemGrid>
      </GridContainer>
    )
  }
}


TasksReportView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  entity: PropTypes.object,
};

const mapStateToProps = state => ({
  entity: getEntity('reports', 3)(state),
});

export default connect(mapStateToProps)(TasksReportView);
