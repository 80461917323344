
import React from 'react';
import PermIdentity from '@material-ui/icons/PermIdentity';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import TitleCard from '../../mui-components/Card/TitleCard';
import ContractTypeCreator from '../../containers/domain/contract-type/ContractTypeCreator';

const ContractTypeCreationView = () => (
  <GridContainer>
    <ItemGrid xs={12} sm={12} md={9} lg={7}>
      <TitleCard
        icon={PermIdentity}
        titleColor="primary"
        title="Cadastro de tipo de contrato"
        content={<ContractTypeCreator />}
      />
    </ItemGrid>
  </GridContainer>
);

export default ContractTypeCreationView;
