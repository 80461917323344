import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Button from './Button';

class SimpleMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  openMenu = event => this.setState({ anchorEl: event.currentTarget });

  closeMenu = () => this.setState({ anchorEl: null });

  handleItemClick = (item) => {
    const { action } = item;
    this.closeMenu();
    return action && action();
  };

  render() {
    const { anchorEl } = this.state;
    const { name, items } = this.props;

    return (
      <div>
        <Button color="primary" variant="contained" onClick={this.openMenu}>
          {name}
          <ArrowDropDownIcon />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleItemClick}
        >
          {items.length > 0 ? (
            this.renderItems()
          ) : (
            <MenuItem>Nenhuma operação disponível</MenuItem>
          )}
        </Menu>
      </div>
    );
  }

  renderItems() {
    const { items } = this.props;
    return items.map(item => (
      <MenuItem key={item.label} onClick={() => this.handleItemClick(item)}>
        {item.label}
      </MenuItem>
    ));
  }
}

SimpleMenu.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func,
    }),
  ).isRequired,
};

export default SimpleMenu;
