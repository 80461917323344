// ##############################
// // // RankingListItem styles
// #############################

import {
  card,
  defaultFont,
  grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const rankingListItemStyle = {
  card: {
    ...card,
    boxShadow: "none",
  },
  cardContent: {
    textAlign: "center",
    paddingTop: "10px",
    padding: "15px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around"
  },
  cardPosition: {
    marginBottom: "0",
    color: grayColor,
    margin: "0 0 10px",
    fontWeight: "bold",
    textAlign: "right",
    width: "20%",
    ...defaultFont
  },
  cardAvatar: {
    width: "20%",
    maxWidth: "100px",
    borderRadius: "50%",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.25)"
  },
  cardScore: {
    margin: "0",
    ...defaultFont,
    fontSize: "1.825em",
    fontWeight: "bold",
    width: "50%",
    color: '#cda855'
  },
};

export default rankingListItemStyle;
