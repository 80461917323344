
import React from 'react';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import RegularCard from '../../mui-components/Card/RegularCard';
import NotIdentifiedSpreadsheetIncomesTable from '../../containers/domain/spreadsheet-income/NotIdentifiedSpreadsheetIncomesTable';

const NotIdentifiedSpreadsheetIncomesListingView = () => (
  <GridContainer>
    <ItemGrid xs={12}>
      <RegularCard
        title="Lista de receitas não-identificadas"
        content={<NotIdentifiedSpreadsheetIncomesTable />}
      />
    </ItemGrid>
  </GridContainer>
);

export default NotIdentifiedSpreadsheetIncomesListingView;
