import { store } from '../../../store';
import employeeActions from '../../../actions/employeeActions';
import clientActions from '../../../actions/clientActions';
import internEnterpriseActions from '../../../actions/internEnterpriseActions';
import partnerEnterpriseActions from '../../../actions/partnerEnterpriseActions';
import { getAbility } from '../../../reducers/sessionReducer';
import sortObjectsArrayByProperty from '../../../utils/sortObjectsArrayByProperty';

const spreadsheetIncomeTableColumns = [
  {
    name: 'clientCpf',
    title: 'CPF',
    getCellValue: row => (row.client || {}).cpf,
    width: 115,
  },
  {
    name: 'clientId',
    title: 'Cliente',
    type: 'object-select',
    meta: { valueKey: 'id', labelKey: 'name' },
    options: [],
    async: {
      fetch: () => store.dispatch(clientActions.fetchAllSimple()),
      fetchDelay: 500,
      mapSuccessfulResult: response => sortObjectsArrayByProperty(Object.values(response.data.entities.clients), 'name'),
      mapProps: ({ response, ...others }) => ({ ...others, options: response }),
    },
    getCellValue: row => (row.client || {}).name,
    width: 138,
  },
  {
    name: 'assessorId',
    title: 'Assessor',
    notRenderable: getAbility(store.getState()).cannot('update', 'SpreadsheetIncome'),
    type: 'object-select',
    meta: { valueKey: 'id', labelKey: 'name' },
    options: [],
    async: {
      fetch: () => store.dispatch(employeeActions.fetchAllSimple()),
      fetchDelay: 500,
      mapSuccessfulResult: response => sortObjectsArrayByProperty(Object.values(response.data.entities.employees), 'name'),
      mapProps: ({ response, ...others }) => ({ ...others, options: response }),
    },
    getCellValue: row => (row.assessor || {}).name,
    width: 121,
  },
  {
    name: 'spreadsheetYear',
    title: 'Fechamento',
    getCellValue: row => `${(row.spreadsheet || {}).referenceYear}/${(row.spreadsheet || {}).referenceMonth}`,
    width: 100,
  },
  {
    name: 'internEnterpriseId',
    title: 'Empresa',
    type: 'object-select',
    meta: { valueKey: 'id', labelKey: 'name' },
    options: [],
    async: {
      fetch: () => store.dispatch(internEnterpriseActions.fetchAll()),
      fetchDelay: 500,
      mapSuccessfulResult: response => sortObjectsArrayByProperty(Object.values(response.data.entities.internEnterprises), 'name'),
      mapProps: ({ response, ...others }) => ({ ...others, options: response }),
    },
    getCellValue: row => (row.internEnterprise || {}).name,
    width: 121,
    visible: false,
  },
  {
    name: 'partnerEnterpriseId',
    title: 'Fornecedor',
    type: 'object-select',
    meta: { valueKey: 'id', labelKey: 'name' },
    options: [],
    async: {
      fetch: () => store.dispatch(partnerEnterpriseActions.fetchAll()),
      fetchDelay: 500,
      mapSuccessfulResult: response => sortObjectsArrayByProperty(Object.values(response.data.entities.partnerEnterprises), 'name'),
      mapProps: ({ response, ...others }) => ({ ...others, options: response }),
    },
    getCellValue: row => (row.partnerEnterprise || {}).name,
    width: 121,
  },
  {
    name: 'clientIdentifier',
    title: 'Identificador',
    width: 123,
    visible: false,
  },
  {
    name: 'product',
    title: 'Produto',
    width: 120,
  },
  {
    name: 'productCategory',
    title: 'Categoria',
    width: 99,
    visible: false,
  },
  {
    name: 'partnerGrossProfit',
    title: 'Receita bruta do fornecedor',
    type: 'brCurrency',
    width: 190,
    visible: false,
  },
  {
    name: 'partnerNetProfit',
    title: 'Receita líquida do fornecedor',
    type: 'brCurrency',
    width: 198,
    visible: false,
  },
  {
    name: 'companyTransferFee',
    title: 'Repasse bruto da Confiança',
    type: 'percentage',
    width: 213,
    visible: false,
  },
  {
    name: 'companyGrossProfit',
    title: 'Receita bruta da Confiança',
    type: 'brCurrency',
    width: 188,
    visible: false,
  },
  {
    name: 'companyTaxFee',
    title: 'Imposto',
    type: 'percentage',
    width: 104,
    visible: false,
  },
  {
    name: 'companyTaxValue',
    title: 'Valor do imposto',
    type: 'brCurrency',
    width: 133,
    visible: false,
  },
  {
    name: 'assessorTransferFee',
    title: 'Repasse líquido do assessor',
    type: 'percentage',
    width: 213,
  },
  {
    name: 'assessorProfit',
    title: 'Receita líquida do assessor',
    type: 'brCurrency',
    width: 187,
  },
  {
    name: 'companyNetProfit',
    title: 'Receita líquida da Confiança',
    notRenderable: getAbility(store.getState()).cannot('update', 'SpreadsheetIncome'),
    type: 'brCurrency',
    width: 199,
  },
];

const spreadsheetIncomeTableSummaries = [
  { columnName: 'clientId', type: 'count' },
  { columnName: 'partnerGrossProfit', type: 'brCurrencySum' },
  { columnName: 'partnerNetProfit', type: 'brCurrencySum' },
  { columnName: 'companyGrossProfit', type: 'brCurrencySum' },
  { columnName: 'companyTaxValue', type: 'brCurrencySum' },
  { columnName: 'assessorProfit', type: 'brCurrencySum' },
  { columnName: 'companyNetProfit', type: 'brCurrencySum' },
];

const spreadsheetIncomeTableLeftFixedColumns = ['clientId', 'assessorId'];

export { spreadsheetIncomeTableSummaries, spreadsheetIncomeTableLeftFixedColumns };
export default spreadsheetIncomeTableColumns;
