import myselfActions from '../../../actions/myselfActions';
import employeeInputFields from './employeeInputFields';
import generateEntityEditor from '../../../hocs/generateEntityEditor';

const EmployeeEditor = generateEntityEditor({
  entitySingularString: 'employee',
  entityPluralString: 'employees',
  entityActions: myselfActions,
  returnRoutePath: '/dashboard',
  fields: employeeInputFields,
});

export default EmployeeEditor;
