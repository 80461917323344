
import React from 'react';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import RegularCard from '../../mui-components/Card/RegularCard';
import SpreadsheetsTable from '../../containers/domain/spreadsheet/SpreadsheetsTable';

const SpreadsheetsListingView = () => (
  <GridContainer>
    <ItemGrid xs={12}>
      <RegularCard
        title="Lista de planilhas de receitas"
        content={<SpreadsheetsTable />}
      />
    </ItemGrid>
  </GridContainer>
);

export default SpreadsheetsListingView;
