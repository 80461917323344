import * as schemas from '../schemas';
const schema = { report: schemas.report };

const dasboard = () => ({
  API_CALL: {
    httpMethod: 'GET',
    endpoint: 'reports/dashboard',
    schema,
  },
});

const spreadsheetIncomes = () => ({
  API_CALL: {
    httpMethod: 'GET',
    endpoint: 'reports/spreadsheet_incomes',
    schema,
  },
});

const clients = () => ({
  API_CALL: {
    httpMethod: 'GET',
    endpoint: 'reports/clients',
    schema,
  },
});

const tasks = () => ({
  API_CALL: {
    httpMethod: 'GET',
    endpoint: 'reports/tasks',
    schema,
  },
});

export default {
  dasboard,
  spreadsheetIncomes,
  clients,
  tasks
};
