
import React from 'react';
import PropTypes from 'prop-types';
import PermIdentity from '@material-ui/icons/PermIdentity';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import TitleCard from '../../mui-components/Card/TitleCard';
import TaskEditor from '../../containers/domain/task/TaskEditor';

const TaskEditionView = ({ match }) => (
  <GridContainer>
    <ItemGrid xs={12} sm={12} md={9} lg={7}>
      <TitleCard
        icon={PermIdentity}
        titleColor="primary"
        title="Atualização de tarefa"
        content={<TaskEditor id={match.params.id} />}
      />
    </ItemGrid>
  </GridContainer>
);

TaskEditionView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
};

export default TaskEditionView;
