/**
 * @param {Object|String} data string or keys of object are named in form of camel case
 * @param {number} depth to which level of keys should it process
 * @return {Object|String} string or keys of object are named in form of snake
 */
export default (data, depth = 10) => {
  if (typeof data === 'object') {
    return processKeys(data, snakelize, depth);
  }
  return snakelize(data);

};

// snakelize a string formed in underscore
const snakelize = (key) => {
  const separator = '_';
  const split = /(?=[A-Z])/;

  return key.split(split).join(separator).toLowerCase();
};

// camelize/snakelize keys of an object
// @param {number} depth to which level of keys should it process
const processKeys = (obj, processer, depth) => {
  if (depth === 0 || typeof obj !== 'object' || obj === null || (Array.isArray(obj) && !(obj[0] instanceof(Object))) ) {
    return obj;
  }

  const result = {};
  const keys = Object.keys(obj);

  for (let i = 0; i < keys.length; i += 1) {
    result[processer(keys[i])] = processKeys(obj[keys[i]], processer, depth - 1);
  }

  return result;
};
