import React, { Component } from 'react';

const withFetch = ({
  fetch,
  onStart = () => {},
  onSuccess = () => {},
  onFailure = () => {},
  onFinish = () => {},
  retry = false,
  retryDelay = 5000,
  fetchDelay = 0,
  mapProps = props => props,
  mapSuccessfulResult = result => result,
  mapFailedResult = result => result,
}) => WrappedComponent => class extends Component {

  state = {
    fetching: true,
    response: undefined,
  };

  componentWillMount() {
    if (fetchDelay === 0) {
      this.execute();
    } else {
      setTimeout(this.execute, fetchDelay);
    }
  }

  execute = () => {
    this.startFetching();
    fetch()
      .then(this.onFetchSucceed)
      .catch(this.onFetchFail);
    onStart();
  };

  startFetching = () => this.setState({ fetching: true, response: undefined });

  finishFetching = response => this.setState({ fetching: false, response });

  onFetchSucceed = (response) => {
    const mappedResponse = mapSuccessfulResult(response);
    this.finishFetching(mappedResponse);
    onSuccess(mappedResponse);
    onFinish(true, mappedResponse);
  };

  onFetchFail = (error) => {
    const mappedError = mapFailedResult(error);
    this.finishFetching(mappedError);
    onFailure(mappedError);
    onFinish(false, mappedError);
    if (retry) {
      setTimeout(this.execute, retryDelay);
    }
  };

  render() {
    const { fetching, response } = this.state;
    const props = mapProps({ ...this.props, fetching, response });
    return <WrappedComponent {...props} />;
  }
};

export default withFetch;
