import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import thunkMiddleware from 'redux-thunk';
import loggerMiddleware from 'redux-logger';

import rootReducer from './reducers/rootReducer';
import environment from './utils/environment';
import apiMiddleware from './middlewares/apiMiddleware';
import DevTools from './containers/general/debug/DevTools';

const history = createHistory({ basename: '/' });
const routeMiddleware = routerMiddleware(history);

const loadStore = () => {
  const persistedState = getPersistedState();
  const enhancer = createEnhancer();
  const store = createStore(rootReducer, persistedState, enhancer);

  store.subscribe(() => saveState(store.getState()));

  return store;
};

const getPersistedState = () => {
  const persistedState = JSON.parse(localStorage.getItem('reduxState'));
  return persistedState || undefined;
};

const createEnhancer = () => {
  const middlewares = [apiMiddleware, thunkMiddleware, routeMiddleware];

  if (environment.isDebug()) {
    middlewares.push(loggerMiddleware);
    return compose(
      applyMiddleware(...middlewares),
      DevTools.instrument(),
    );
  }

  return compose(applyMiddleware(...middlewares));
};

const saveState = ({ session }) => {
  const savedState = { session };
  const serializedState = JSON.stringify(savedState);
  localStorage.setItem('reduxState', serializedState);
};

const store = loadStore();

export { store, history };
