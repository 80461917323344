import { container } from '../../../assets/jss/material-dashboard-pro-react';

const style = {

  content: {
    paddingTop: '18vh',
    minHeight: 'calc(100vh - 80px)',
    position: 'relative',
    zIndex: '4',
  },

  container,

};

export default style;
