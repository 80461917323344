import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextField as MuiTextField } from 'redux-form-material-ui';
import percentageMask from '../../utils/masks/percentageMask';

const ConfFormPercentageField = ({ name, label, disabled }) => (
  <Field
    disabled={disabled}
    name={name}
    label={label}
    fullWidth
    component={MuiTextField}
    {...percentageMask}
  />
);

ConfFormPercentageField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ConfFormPercentageField.defaultProps = {
  disabled: false,
};

export default ConfFormPercentageField;
