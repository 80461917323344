import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import ConfTableCellEditorField from './ConfTableCellEditorField';
import withFetch from '../../../hocs/withFetch';

class ConfTableCellEditor extends Component {

  fieldName = 'editorField';

  componentWillMount() {
    this.prepareAsyncEditorField();
  }

  prepareAsyncEditorField = () => {
    const { column } = this.props;
    if (column.async) {
      const fieldProps = this.buildFieldProps();
      const AsyncCellEditorField = withFetch(column.async)(ConfTableCellEditorField);
      this.asyncFieldComponent = <AsyncCellEditorField {...fieldProps} />;
    }
  };

  buildFieldProps = () => {
    const { column, handleSubmit } = this.props;
    return {
      ...column,
      name: this.fieldName,
      handleSubmit,
    };
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit}>
          {this.renderField()}
        </form>
      </div>
    );
  }

  renderField = () => {
    const { column } = this.props;
    if (column.async) {
      return this.asyncFieldComponent;
    }
    const fieldProps = this.buildFieldProps();
    return <ConfTableCellEditorField {...fieldProps} />;
  };

}

ConfTableCellEditor.propTypes = {
  column: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm()(ConfTableCellEditor);
