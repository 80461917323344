
import React from 'react';
import PropTypes from 'prop-types';
import PermIdentity from '@material-ui/icons/PermIdentity';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import TitleCard from '../../mui-components/Card/TitleCard';
import PartnerEnterpriseEditor from '../../containers/domain/partner-enterprise/PartnerEnterpriseEditor';

const PartnerEnterpriseEditionView = ({ match }) => (
  <GridContainer>
    <ItemGrid xs={12} sm={12} md={9} lg={7}>
      <TitleCard
        icon={PermIdentity}
        titleColor="primary"
        title="Atualização de fornecedor"
        content={<PartnerEnterpriseEditor id={match.params.id} />}
      />
    </ItemGrid>
  </GridContainer>
);

PartnerEnterpriseEditionView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
};

export default PartnerEnterpriseEditionView;
