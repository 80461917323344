import _ from 'lodash';
import moment from 'moment';

class ConfTableFilteringHelper {

  filters = null;

  filterValues = null;

  constructor(filters, filterValues, rowsIndexes) {
    this.filters = filters;
    this.filterValues = filterValues;
    this.rowsIndexes = rowsIndexes;
  }

  filterDataList = (dataList) => {
    const filteredDataList = [];
    dataList.forEach((row, index) => {
      if (this.rowsIndexes.includes(index) || this.isDataVisibleAfterApplyFilters(row, this.filters)) {
        filteredDataList.push(row);
      }
    });
    return filteredDataList;
  };

  isDataVisibleAfterApplyFilters = (row, filters) => {
    let dataVisibility = true;
    for (const filter of filters) {
      if (!this.isDataVisibleAfterApplyFilter(row, filter)) {
        dataVisibility = false;
        break;
      }
    }
    return dataVisibility;
  };

  isDataVisibleAfterApplyFilter = (row, filter) => {
    const { type = 'text' } = filter;

    switch (type) {
      case 'text':
        return this.isDataVisibleAfterApplyTextFilter(row, filter);
      case 'number':
      case 'dottedNumber':
      case 'brCurrency':
      case 'percentage':
        return this.isDataVisibleAfterApplyNumericRangeFilter(row, filter);
      case 'date':
        return this.isDataVisibleAfterApplyDateRangeFilter(row, filter);
      case 'checkbox':
        return this.isDataVisibleAfterApplyOnOffFilter(row, filter);
      case 'select':
        return this.isDataVisibleAfterApplyCheckboxesFilter(row, filter);
      case 'object-select':
        return this.isDataVisibleAfterApplyObjectSelectFilter(row, filter);
      default:
        return true;
    }
  };

  isDataVisibleAfterApplyTextFilter = (row, filter) => {
    const filterValue = this.filterValues[filter.name];
    const dataValue = row[filter.name] || '';
    if (!filterValue) {
      return true;
    }
    const deburredFilterValue = _.lowerCase(_.deburr(filterValue));
    const deburredDataValue = _.lowerCase(_.deburr(dataValue));
    return deburredDataValue.includes(deburredFilterValue);
  };

  isDataVisibleAfterApplyNumericRangeFilter = (row, filter) => {
    let { minValue, maxValue } = this.filterValues[filter.name] || {};
    let data = row[filter.name];
    data = parseFloat(data);
    minValue = parseFloat(minValue);
    maxValue = parseFloat(maxValue);
    return !(
      (minValue && (isNaN(data) || data < minValue))
      || (maxValue && (isNaN(data) || data > maxValue))
    );
  };

  isDataVisibleAfterApplyDateRangeFilter = (row, filter) => {
    let { minValue, maxValue } = this.filterValues[filter.name] || {};
    let data = row[filter.name];
    const dateFormat = ['DD-MM-YYYY', 'YYYY-MM-DD'];
    data = moment(data, dateFormat);
    minValue = moment(minValue, dateFormat);
    maxValue = moment(maxValue, dateFormat);
    return !(
      (minValue.isValid() && (!data.isValid() || data.isBefore(minValue)))
      || (maxValue.isValid() && (!data.isValid() || data.isAfter(maxValue)))
    );
  };

  isDataVisibleAfterApplyOnOffFilter = (row, filter) => {
    const onOffFilterValue = this.filterValues[filter.name];
    const data = row[filter.name];

    if (!onOffFilterValue) {
      return true;
    }
    const { on, off } = onOffFilterValue;

    if (data) {
      return !!on;
    }
    return !!off;
  };

  isDataVisibleAfterApplyCheckboxesFilter = (row, filter) => {
    const checkboxNames = this.filterValues[filter.name];
    const data = row[filter.name];

    if (!checkboxNames) {
      return true;
    }

    let isAllCheckboxUnmarked = true;
    const checkboxNamesKeys = Object.keys(checkboxNames);
    for (const checkboxNameKey of checkboxNamesKeys) {
      const checkboxValue = checkboxNames[checkboxNameKey];
      if (checkboxValue) {
        isAllCheckboxUnmarked = false;
      }
      if (data === checkboxNameKey && checkboxValue) {
        return true;
      }
    }

    return isAllCheckboxUnmarked;
  };

  isDataVisibleAfterApplyObjectSelectFilter = (row, filter) => {
    const filterValue = this.filterValues[filter.name];
    const data = row[filter.name];
    return !filterValue || data === filterValue;
  };

}

export default ConfTableFilteringHelper;
