
import internEnterpriseActions from '../../../actions/internEnterpriseActions';
import internEnterpriseInputFields from './internEnterpriseInputFields';
import generateEntityCreator from '../../../hocs/generateEntityCreator';

const InternEnterpriseCreator = generateEntityCreator({
  entityActions: internEnterpriseActions,
  returnRoutePath: 'intern-enterprises-listing',
  fields: internEnterpriseInputFields,
});

export default InternEnterpriseCreator;
