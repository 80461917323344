import React from 'react';
import { Toolbar } from '@devexpress/dx-react-grid-material-ui';

const ConfTableToolbarRoot = props => (
  <Toolbar.Root
    {...props}
    style={{ minHeight: 40 }}
  />
);

export default ConfTableToolbarRoot;
