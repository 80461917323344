import { combineReducers } from 'redux';

import NotificationsManager from '../containers/general/notification/NotificationsManager/reducer';
import PopupsManager from '../containers/general/popup/PopupsManager/reducer';
import ApiRequestLoadingManager from '../containers/general/loading/ApiRequestLoadingManager/reducer';

export default combineReducers({
  NotificationsManager,
  PopupsManager,
  ApiRequestLoadingManager,
});
