import React from 'react';
import PermIdentity from '@material-ui/icons/PermIdentity';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import TitleCard from '../../mui-components/Card/TitleCard';
import ClientCreator from '../../containers/domain/client/ClientCreator';

const ClientCreationView = () => (
  <GridContainer>
    <ItemGrid xs={12} sm={12} md={9} lg={7}>
      <TitleCard
        icon={PermIdentity}
        titleColor="primary"
        title="Cadastro de cliente"
        content={<ClientCreator />}
      />
    </ItemGrid>
  </GridContainer>
);

export default ClientCreationView;
