import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextField } from 'redux-form-material-ui';

const TextFilter = ({ name, title }) => (
  <Field
    name={name}
    label={title}
    component={TextField}
  />
);

TextFilter.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default TextFilter;
