import React from 'react';
import PermIdentity from '@material-ui/icons/PermIdentity';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import TitleCard from '../../mui-components/Card/TitleCard';
import ClientsMerging from '../../containers/domain/client/ClientsMerging';

const ClientsMergingView = () => (
  <GridContainer>
    <ItemGrid xs={12}>
      <TitleCard
        icon={PermIdentity}
        titleColor="primary"
        title="Combinação de clientes"
        content={<ClientsMerging />}
      />
    </ItemGrid>
  </GridContainer>
);

export default ClientsMergingView;
