import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AddAlert from '@material-ui/icons/Notifications';

import Snackbars from '../../../../mui-components/Snackbar/Snackbar';
import actions from './actions';
import { getNotification } from './reducer';

const NotificationsManager = ({ notification, dispatch }) => (
  <Snackbars
    open={notification.visible}
    place={notification.place}
    color={notification.color}
    icon={AddAlert}
    message={notification.message}
    closeNotification={() => dispatch(actions.closeNotification())}
    close
  />
);

NotificationsManager.propTypes = {
  dispatch: PropTypes.func.isRequired,
  notification: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  notification: getNotification(state),
});

export default connect(mapStateToProps)(NotificationsManager);
