import React from 'react';
import PropTypes from 'prop-types';

import Card from './Card';
import CardHeader from './CardHeader';
import CardBody from './CardBody';
import CardText from './CardText';

const TitleCard = ({ titleColor, title, content }) => (
  <Card>
    <CardHeader color={titleColor} text>
      <CardText color="primary">
        <h4>{title}</h4>
      </CardText>
    </CardHeader>
    <CardBody>
      {content}
    </CardBody>
  </Card>
);

TitleCard.propTypes = {
  titleColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
};

TitleCard.defaultProps = {
  titleColor: 'orange',
};

export default TitleCard;
