
import taskActions from '../../../actions/taskActions';
import taskInputFields from './taskInputFields';
import generateEntityEditor from '../../../hocs/generateEntityEditor';

const TaskEditor = generateEntityEditor({
  entitySingularString: 'task',
  entityPluralString: 'tasks',
  entityActions: taskActions,
  returnRoutePath: '/acompanhamento-de-tarefas',
  fields: taskInputFields,
});

export default TaskEditor;
