
import React from 'react';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import RegularCard from '../../mui-components/Card/RegularCard';
import InternEnterprisesTable from '../../containers/domain/intern-enterprise/InternEnterprisesTable';

const InternEnterprisesListingView = () => (
  <GridContainer>
    <ItemGrid xs={12}>
      <RegularCard
        title="Lista de empresas"
        content={<InternEnterprisesTable />}
      />
    </ItemGrid>
  </GridContainer>
);

export default InternEnterprisesListingView;
