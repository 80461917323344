import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getSearchParam } from '../../reducers/routerSelectors';

import PermIdentity from '@material-ui/icons/PermIdentity';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import TitleCard from '../../mui-components/Card/TitleCard';
import ClientsTaskCreator from '../../containers/domain/task/ClientsTaskCreator';

class ClientsNewTaskView extends Component {
  render() {
    const { client_ids, description, primaryStage, secondaryStage } = this.props;
    return (
      <GridContainer>
        <ItemGrid xs={12}>
          <TitleCard
            icon={PermIdentity}
            titleColor="primary"
            title="Nova tarefa para clientes"
            content={<ClientsTaskCreator initialValues={{client_ids: client_ids, description: description, primaryStage: primaryStage, secondaryStage: secondaryStage}} />}
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

ClientsNewTaskView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  client_ids: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  let client_ids = getSearchParam(state)('client_ids')
  client_ids = Array.isArray(client_ids) ? client_ids : Array(client_ids)

  let description = getSearchParam(state)('description')
  let primaryStage = getSearchParam(state)('primaryStage')
  let secondaryStage = getSearchParam(state)('secondaryStage')
  return { client_ids: client_ids, description: description, primaryStage: primaryStage, secondaryStage: secondaryStage }
};

export default connect(mapStateToProps)(ClientsNewTaskView);
