import merge from 'lodash/merge';

import types from '../actions/types';

const initialState = {};

const mergeEntitiesCase = (state, entities) => merge({}, state, entities);

const deleteEntitiesCase = (state, deletedEntities) => {
  const nextState = { ...state };
  const prevEntities = state;

  const deletedEntitiesKeys = Object.keys(deletedEntities);
  deletedEntitiesKeys.forEach((theseEntitiesKey) => {
    const theseDeletedEntities = deletedEntities[theseEntitiesKey];

    const theseDeletedEntitiesIds = Object.keys(theseDeletedEntities);
    const thesePrevEntities = prevEntities[theseEntitiesKey];

    const thesePrevEntitiesIds = Object.keys(thesePrevEntities);

    const theseNonDeletedEntitiesIds = thesePrevEntitiesIds
      .filter(key => !theseDeletedEntitiesIds.includes(key));

    const theseNextEntities = theseNonDeletedEntitiesIds
      .reduce((prev, id) => ({ ...prev, [id]: thesePrevEntities[id] }), {});
    nextState[theseEntitiesKey] = theseNextEntities;
  });
  return nextState;
};

const entitiesReducer = (state = initialState, action) => {
  const { type, response, entitiesDeleted } = action;

  if (type === types.ENTITY_CLEAN_CACHE) {
    return initialState;
  }

  if (response && response.data && response.data.entities) {
    const { entities } = response.data;
    if (!entitiesDeleted) {
      return mergeEntitiesCase(state, entities);
    }
    return deleteEntitiesCase(state, entities);
  }

  return state;
};

export const getEntities = schema => state => (
  state.entities[schema] || {}
);

export const getEntitiesArray = schema => state => (
  Object.values(getEntities(schema)(state))
);

export const getEntity = (schema, id) => (state) => {
  const schemaEntities = getEntities(schema)(state);
  return schemaEntities ? schemaEntities[id] : undefined;
};

export default entitiesReducer;
