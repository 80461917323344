
import React from 'react';
import PermIdentity from '@material-ui/icons/PermIdentity';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import TitleCard from '../../mui-components/Card/TitleCard';
import SpreadsheetCreator from '../../containers/domain/spreadsheet/SpreadsheetCreator';

const Comp = () => (
  <div>
    <div
      style={{ position: 'absolute', top: 650, right: 20 }}
      dangerouslySetInnerHTML={{ __html:
        'Regras provisórias para importação de planilha:<br>' +
        "1) Nome da planilha com caracteres especiais e 'ç'<br>" +
        '2) Espaço e caracteres especiais no nome da aba.<br>' +
        '3) Remover congelamento de linhas e colunas<br>',
      }}
    />
    <SpreadsheetCreator />
  </div>
);

const SpreadsheetCreationView = () => (
  <GridContainer>
    <ItemGrid xs={12} sm={12} md={9} lg={7}>
      <TitleCard
        icon={PermIdentity}
        titleColor="primary"
        title="Importação de planilha de receitas"
        content={<Comp />}
      />
    </ItemGrid>
  </GridContainer>
);

export default SpreadsheetCreationView;
