import React, { Component } from 'react';
import { connect } from 'react-redux';

import clientActions from '../../../actions/clientActions';
import clientInputFields from './clientInputFields';
import generateEntityCreator from '../../../hocs/generateEntityCreator';
import { getLoggedUser } from '../../../reducers/sessionReducer';

class MultipleClientEditor extends Component {
  componentWillMount() {
    const { initialValues, user  } = this.props;
    const returnRoutePath = user.accessProfile === 'Gestor' ? 'clients-listing' : 'my-clients-listing'

    clientActions.create = (params) => {
      const { client_ids, ...clientParams } = params;
      return {
        API_CALL: {
          httpMethod: 'PUT',
          endpoint: 'clients/multiple-edition',
          params: { client_ids: client_ids, client: clientParams },
        },
      };
    };

    this.DefaultFormWithProps = generateEntityCreator({
      entityActions: clientActions,
      returnRoutePath: returnRoutePath,
      fields: clientInputFields,
      submitLabel: 'Atualizar',
      initialValues: initialValues
    });
  }

  render() {
    const { DefaultFormWithProps } = this;
    return (<DefaultFormWithProps />);
  }
}

const mapStateToProps = state => {
  return {
    user: getLoggedUser(state),
  }
};

export default connect(mapStateToProps)(MultipleClientEditor);
