import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@devexpress/dx-react-grid-material-ui';

const ConfTableRow = ({ style, ...restProps }) => (
  <Table.Row {...restProps} style={{ height: 20, ...style }} />
);

ConfTableRow.propTypes = {
  style: PropTypes.object,
};

ConfTableRow.defaultProps = {
  style: {},
};

export default ConfTableRow;
