import { push } from "react-router-redux";
import QueryString from "query-string";

import { store } from "../../../store";
import notificationActions from "../../general/notification/NotificationsManager/actions";
import cacheActions from "../../../actions/cacheActions";
import clientActions from "../../../actions/clientActions";
import employeeActions from "../../../actions/employeeActions";
import contractTypeActions from "../../../actions/contractTypeActions";
import professionActions from "../../../actions/professionActions";
import { getAbility, getAccessProfile } from "../../../reducers/sessionReducer";
import sortObjectsArrayByProperty from "../../../utils/sortObjectsArrayByProperty";
import { confirmAlert } from "react-confirm-alert";
import _ from "lodash";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const clientTableColumns = [
  {
    name: "name",
    title: "Nome",
    editable: getAbility(store.getState()).can("update", "Client"),
    width: 170,
  },
  {
    name: "cpf",
    title: "CPF",
    editable: getAbility(store.getState()).can("update", "Client"),
    width: 110,
  },
  {
    name: "advisorId",
    title: "Assessor responsável",
    editable: true,
    notRenderable: getAbility(store.getState()).cannot("update", "Client"),
    type: "object-select",
    meta: { valueKey: "id", labelKey: "name" },
    options: [],
    async: {
      fetch: () => store.dispatch(employeeActions.fetchAll()),
      fetchDelay: 500,
      mapSuccessfulResult: (response) => sortObjectsArrayByProperty(Object.values(response.data.entities.employees), "name"),
      mapProps: ({ response, ...others }) => ({ ...others, options: response }),
    },
    getCellValue: (row) => (row.advisor || {}).name,
    width: 159,
  },
  {
    name: "activeContractType",
    title: "Contrato ativo",
    type: "object-select",
    meta: { valueKey: "id", labelKey: "name" },
    options: [],
    async: {
      fetch: () => store.dispatch(contractTypeActions.fetchAll()),
      fetchDelay: 500,
      mapSuccessfulResult: (response) => sortObjectsArrayByProperty(Object.values(response.data.entities.contractTypes), "name"),
      mapProps: ({ response, ...others }) => ({ ...others, options: response }),
    },
    getCellValue: (row) => ((row.activeContract || {}).contractType || {}).name,
    width: 125,
  },
  {
    name: "activeContractStartDate",
    title: "Início do contrato",
    type: "date",
    getCellValue: (row) => (row.activeContract || {}).startDate,
    width: 136,
    visible: false,
  },
  {
    name: "activeContractEndDate",
    title: "Fim do contrato",
    type: "date",
    getCellValue: (row) => (row.activeContract || {}).endDate,
    width: 129,
    visible: false,
  },
  {
    name: "xpCode",
    title: "Código XP",
    width: 98,
  },
  {
    name: "xpDigitVerifier",
    title: "Verificador Bolsa XP",
    width: 98,
    editable: true,
    type: "select",
    options: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    visible: false,
  },
  {
    name: "btgCode",
    title: "Código BTG",
    width: 98,
    editable: true,
  },
  {
    name: "btgDigitVerifier",
    title: "Verificador Bolsa BTG",
    width: 98,
    editable: true,
    type: "select",
    options: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    visible: false,
  },
  {
    name: "investmentProfile",
    title: "Perfil XP",
    editable: true,
    type: "select",
    options: ["Conservador", "Conservador moderado", "Moderado", "Moderado agressivo", "Agressivo"],
    width: 157,
    visible: false,
  },
  {
    name: "internInvestmentProfile",
    title: "Perfil Confiança",
    editable: true,
    type: "select",
    options: ["Conservador", "Conservador moderado", "Moderado", "Moderado agressivo", "Agressivo"],
    width: 157,
  },
  {
    name: "funnelStage",
    title: "Status Funil",
    editable: false,
    type: "select",
    options: ["1 - Primeiro contato", "2 - Habilitação", "3+ - Habilitado"],
    width: 157,
  },
  {
    name: "accreditedInvestor",
    title: "Investidor qualificado",
    editable: true,
    type: "checkbox",
    width: 200,
    visible: false,
  },
  {
    name: "net",
    title: "NET XP",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    precision: 2,
    width: 200,
  },
  {
    name: "netBtg",
    title: "NET BTG Assessoria",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    precision: 2,
    width: 200,
  },
  {
    name: "netBtgConsultancy",
    title: "NET BTG Consultoria",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    precision: 2,
    width: 200,
  },
  {
    name: "netBb",
    title: "NET BB",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    precision: 2,
    width: 200,
  },
  {
    name: "netSantander",
    title: "NET Santander",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    precision: 2,
    width: 200,
  },
  {
    name: "netItau",
    title: "NET Itaú",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    precision: 2,
    width: 200,
  },
  {
    name: "netBradesco",
    title: "NET Bradesco",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    precision: 2,
    width: 200,
  },
  {
    name: "netCaixa",
    title: "NET Caixa",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    precision: 2,
    width: 200,
  },
  {
    name: "netAvenue",
    title: "NET Avenue",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    precision: 2,
    width: 200,
  },
  {
    name: "netOutros",
    title: "NET Outros",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    precision: 2,
    width: 200,
  },
  {
    name: "netTotal",
    title: "NET Total",
    editable: false,
    type: "brCurrency",
    precision: 2,
    width: 200,
  },
  {
    name: "oldNet",
    title: "NET XP antigo",
    editable: false,
    type: "brCurrency",
    precision: 2,
    width: 200,
  },
  {
    name: "responsibleBtg",
    title: "Responsável BTG",
    editable: getAbility(store.getState()).can("update", "Client"),
    width: 200,
    visible: false,
  },
  {
    name: "btgProfile",
    title: "Perfil BTG",
    editable: getAbility(store.getState()).can("update", "Client"),
    width: 200,
  },
  {
    name: "btgBankAccount",
    title: "Conta corrente BTG",
    editable: getAbility(store.getState()).can("update", "Client"),
    width: 200,
    visible: false,
  },
  {
    name: "fundsBtg",
    title: "Fundos BTG",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    precision: 2,
    width: 200,
    visible: false,
  },
  {
    name: "fixedIncomeBtg",
    title: "Renda fixa BTG",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    precision: 2,
    width: 200,
    visible: false,
  },
  {
    name: "variableIncomeBtg",
    title: "Renda variável BTG",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    precision: 2,
    width: 200,
    visible: false,
  },
  {
    name: "socialSecurityBtg",
    title: "Previdência BTG",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    precision: 2,
    width: 200,
    visible: false,
  },
  {
    name: "derivativesBtg",
    title: "Derivativos BTG",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    precision: 2,
    width: 200,
    visible: false,
  },
  {
    name: "plDeclaredBtg",
    title: "PL Declarado BTG",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    precision: 2,
    width: 200,
    visible: false,
  },
  {
    name: "assessorNetProfitMean",
    title: "Receita líquida média",
    editable: false,
    type: "brCurrency",
    precision: 2,
    width: 200,
  },
  {
    name: "assessorNetProfitTotal",
    title: "Receita líquida Total",
    editable: false,
    type: "brCurrency",
    precision: 2,
    width: 200,
  },
  {
    name: "insurancePremium",
    title: "Prêmio de seguro",
    editable: false,
    type: "brCurrency",
    precision: 2,
    width: 200,
  },
  {
    name: "ciCode",
    title: "Código CI",
    editable: getAbility(store.getState()).can("update", "Client"),
    width: 94,
    visible: false,
  },
  {
    name: "icatuCode",
    title: "Matrícula Icatu",
    editable: getAbility(store.getState()).can("update", "Client"),
    width: 122,
    visible: false,
  },
  {
    name: "mongeralCode",
    title: "Matrícula Mongeral",
    editable: getAbility(store.getState()).can("update", "Client"),
    width: 148,
    visible: false,
  },
  {
    name: "relationshipType",
    title: "Status",
    editable: true,
    type: "select",
    // filterInitialValue: { 'Potencial cliente': true, 'Cliente': true },
    options: ["Ex-cliente", "Potencial cliente", "Cliente", "Sem interesse"],
    width: 116,
  },
  {
    name: "email",
    title: "E-mail",
    editable: getAbility(store.getState()).can("update", "Client"),
    width: 170,
  },
  {
    name: "alternativeEmail",
    title: "E-mail alternativo",
    editable: getAbility(store.getState()).can("update", "Client"),
    width: 140,
    visible: false,
  },
  {
    name: "telephone",
    title: "Telefone",
    editable: getAbility(store.getState()).can("update", "Client"),
    width: 110,
  },
  {
    name: "city",
    title: "Cidade",
    editable: getAbility(store.getState()).can("update", "Client"),
    width: 120,
    visible: false,
  },
  {
    name: "birthday",
    title: "Data de nascimento",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "date",
    width: 148,
  },
  {
    name: "civilStatus",
    title: "Estado civil",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "select",
    options: ["Casado", "Divorciado", "Solteiro", "Viúvo", "Separado"],
    width: 140,
    visible: false,
  },
  {
    name: "professionId",
    title: "Profissão",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "object-select",
    meta: { valueKey: "id", labelKey: "name" },
    options: [],
    async: {
      fetch: () => store.dispatch(professionActions.fetchAll()),
      fetchDelay: 500,
      mapSuccessfulResult: (response) => sortObjectsArrayByProperty(Object.values(response.data.entities.professions), "name"),
      mapProps: ({ response, ...others }) => ({ ...others, options: response }),
    },
    getCellValue: (row) => (row.profession || {}).name,
    width: 152,
  },
  {
    name: "professionRole",
    title: "Cargo",
    editable: getAbility(store.getState()).can("update", "Client"),
    width: 101,
    visible: false,
  },
  {
    name: "workCompany",
    title: "Companhia",
    editable: getAbility(store.getState()).can("update", "Client"),
    width: 106,
    visible: false,
  },
  {
    name: "lifeInsurance",
    title: "Seguro de vida",
    editable: getAbility(store.getState()).can("update", "Client"),
    width: 120,
    visible: false,
  },
  {
    name: "foresight",
    title: "Previdência",
    editable: getAbility(store.getState()).can("update", "Client"),
    width: 104,
    visible: false,
  },
  {
    name: "income",
    title: "Renda mensal",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    width: 117,
    visible: false,
  },
  {
    name: "monthlyExpenses",
    title: "Gastos mensais",
    editable: getAbility(store.getState()).can("update", "Client"),
    type: "brCurrency",
    width: 126,
    visible: false,
  },
  {
    name: "savingCapacity",
    title: "Capacidade de poupança",
    editable: true,
    type: "brCurrency",
    width: 179,
  },
  {
    name: "segmentation",
    title: "Segmentação",
    editable: true,
    type: "select",
    options: ["A", "B", "C", "D"],
    width: 117,
  },
  {
    name: "referral",
    title: "Indicação",
    editable: getAbility(store.getState()).can("update", "Client"),
    width: 95,
    visible: false,
  },
  { name: "group1", title: "Grupo 1", editable: true, type: "checkbox", width: 90, visible: false },
  { name: "group2", title: "Grupo 2", editable: true, type: "checkbox", width: 90, visible: false },
  { name: "group3", title: "Grupo 3", editable: true, type: "checkbox", width: 90, visible: false },
  { name: "group4", title: "Grupo 4", editable: true, type: "checkbox", width: 90, visible: false },
  { name: "group5", title: "Grupo 5", editable: true, type: "checkbox", width: 90, visible: false },
  { name: "group6", title: "Grupo 6", editable: true, type: "checkbox", width: 90, visible: false },
  { name: "group7", title: "Grupo 7", editable: true, type: "checkbox", width: 90, visible: false },
  { name: "group8", title: "Grupo 8", editable: true, type: "checkbox", width: 90, visible: false },
  { name: "group9", title: "Grupo 9", editable: true, type: "checkbox", width: 90, visible: false },
  { name: "group10", title: "Grupo 10", editable: true, type: "checkbox", width: 90, visible: false },
  {
    name: "description",
    title: "Descrição",
    editable: true,
    width: 2000,
  },
];

const clientTableLeftFixedColumns = ["operations", "name"];

const clientTableSummaries = [
  { columnName: "name", type: "count" },
  { columnName: "net", type: "brCurrencySum" },
  { columnName: "netBtg", type: "brCurrencySum" },
  { columnName: "netBtgConsultancy", type: "brCurrencySum" },
  { columnName: "netBb", type: "brCurrencySum" },
  { columnName: "netSantander", type: "brCurrencySum" },
  { columnName: "netItau", type: "brCurrencySum" },
  { columnName: "netBradesco", type: "brCurrencySum" },
  { columnName: "netCaixa", type: "brCurrencySum" },
  { columnName: "netAvenue", type: "brCurrencySum" },
  { columnName: "netOutros", type: "brCurrencySum" },
  { columnName: "netTotal", type: "brCurrencySum" },
  { columnName: "oldNet", type: "brCurrencySum" },
  { columnName: "assessorNetProfitMean", type: "brCurrencyMean" },
  { columnName: "assessorNetProfitTotal", type: "brCurrencySum" },
  { columnName: "insurancePremium", type: "brCurrencySum" },
];

const clientSelectionOperations = [
  {
    label: "Combinar clientes",
    action: (selectedClients) => {
      const params = {
        client1: selectedClients[0].id,
        client2: selectedClients[1].id,
      };
      const paramsString = QueryString.stringify(params);
      store.dispatch(push(`/clients-merging?${paramsString}`));
    },
    renderCondition: (selectedClients) => selectedClients.length === 2,
  },
  {
    label: "Criar tasks",
    action: (selectedClients) => {
      const params = {
        client_ids: selectedClients.map((item) => item.id),
      };
      const paramsString = QueryString.stringify(params);
      store.dispatch(push(`/clients-task-creation?${paramsString}`));
    },
    renderCondition: (selectedClients) => selectedClients.length > 0,
  },
  {
    label: "Editar itens",
    action: (selectedClients) => {
      const params = {
        client_ids: selectedClients.map((item) => item.id),
      };
      const paramsString = QueryString.stringify(params);
      store.dispatch(push(`/multiple-client-edition?${paramsString}`));
    },
    renderCondition: (selectedClients) => selectedClients.length > 1,
  },
  {
    label: "Apagar itens",
    action: (selectedClients) => {
      const params = { client_ids: selectedClients.filter((item) => item != null).map((item) => item.id) };

      confirmAlert({
        title: "Confirmação de exclusão",
        message: "Deseja mesmo apagar os registros.",
        buttons: [
          {
            label: "Sim",
            onClick: () => {
              store
                .dispatch(clientActions.destroyAll(params))
                .then((response) => {
                  store.dispatch(notificationActions.showNotification("Apagado com sucesso", "success"));
                  store.dispatch(cacheActions.cleanCache());
                  store.dispatch(clientActions.fetchAll());
                })
                .catch((apiResponse) => {
                  const message = apiResponse.data.errors[0].detail;
                  const color = "danger";
                  store.dispatch(notificationActions.showNotification(message, color));
                  store.dispatch(cacheActions.cleanCache());
                  store.dispatch(clientActions.fetchAll());
                });
            },
          },
          {
            label: "Não",
            onClick: () => {},
          },
        ],
      });
    },
    renderCondition: (selectedClients) => selectedClients.length > 1,
  },
  {
    label: "Enviar email",
    action: (selectedClients) => {
      const params = {
        client_ids: selectedClients.map((item) => item.id),
      };
      const paramsString = QueryString.stringify(params);
      store.dispatch(push(`/send-client-emails?${paramsString}`));
    },
    renderCondition: (selectedClients) => selectedClients.length > 1,
  },
  {
    label: "Exportar para XLS",
    action: (selectedClients) => {
      selectedClients = selectedClients.map((client) => {
        const keys = Object.keys(client);
        keys.forEach((key) => {
          const column = _.find(clientTableColumns, ["name", key]);
          client[key] = column && column.getCellValue ? column.getCellValue(client) : client[key];
        });
        return client;
      });

      let csvData = selectedClients.map((client) => {
        return _.mapKeys(client, (value, key) => {
          const column = _.find(clientTableColumns, ["name", key]);
          return column ? column.title : key;
        });
      });

      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { Clientes: ws }, SheetNames: ["Clientes"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "clientes" + fileExtension);
    },
    renderCondition: (selectedClients) => selectedClients.length > 0 && getAccessProfile(store.getState()) === "Gestor",
  },
];

export { clientTableSummaries, clientTableLeftFixedColumns, clientSelectionOperations };
export default clientTableColumns;
