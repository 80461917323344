import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import authActions from '../../../actions/authActions';
import ChangePasswordForm from '../../../components/domain/user/ChangePasswordForm/index';
import notificationActions from '../../general/notification/NotificationsManager/actions';
import { getSearchParam } from '../../../reducers/routerSelectors';
import { store } from '../../../store';
import { push } from 'react-router-redux';

class ChangePassword extends React.Component {

  submit = ({ password }) => {
    const { token } = this.props;
    this.updatePassword(password, token).then(response => {
      store.dispatch(notificationActions.showNotification('Senha alterada com sucesso', 'success'));
      store.dispatch(push('/pages/login-page'));
    }).catch(this.onChangePasswordFail)
  };

  updatePassword = (password, token) => {
    const { dispatch } = this.props;
    const action = authActions.updatePassword(password, token);
    return dispatch(action);
  };

  onChangePasswordFail = () => {
    const { dispatch } = this.props;
    const message = 'Operação falhou. Não foi possível realizar a autenticação para acesso ao sistema.';
    const color = 'danger';
    const place = 'tc';
    dispatch(notificationActions.showNotification(message, color, place));
  };

  render() {
    return (
      <ChangePasswordForm form="password" onSubmit={this.submit} />
    );
  }
}

ChangePassword.propTypes = {
  dispatch: PropTypes.func.isRequired,
  client_ids: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  const token = getSearchParam(state)('token')
  return { token: token }
};

export default connect(mapStateToProps)(ChangePassword);
