import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { getFormValues } from 'redux-form';

import DefaultForm from '../components/conf-form/ConfForm';
import notificationActions from '../containers/general/notification/NotificationsManager/actions';
import popupActions from '../containers/general/popup/PopupsManager/actions';
import withFetch from './withFetch';

const generateEntityEditor = ({
  formName = 'entityEditor',
  entityPluralString,
  entitySingularString,
  entityActions,
  returnRoutePath,
  fields,
  submitLabel,
  resetLabel,
}) => connect(state => ({
  formValues: getFormValues(formName)(state) || {},
  state,
}))(class extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const config = {
      fetch: this.fetchEntity,
      onFailure: this.onFetchFail,
      mapSuccessfulResult: this.mapFetchResult,
      mapProps: this.mapFetchProps,
    };
    this.DefaultFormWithFetch = withFetch(config)(DefaultForm);
  }

  fetchEntity = () => {
    const { dispatch, id } = this.props;
    return dispatch(entityActions.fetch(id));
  };

  editEntity = (inputs) => {
    const { dispatch, id } = this.props;
    return dispatch(entityActions.update(id, inputs));
  };

  mapFetchResult = (response) => {
    const { result, entities } = response.data;
    const thisEntities = entities[entityPluralString];
    const entityId = result[entitySingularString];
    const entity = thisEntities[entityId];
    return entity;
  };

  mapFetchProps = ({ fetching, response, ...others }) => ({
    loading: fetching,
    initialValues: response,
    ...others,
  });

  onFetchFail = (apiResponse) => {
    const { dispatch } = this.props;
    const message = 'Operação falhou. Não foi possível acessar os dados.';
    const color = 'danger';
    dispatch(notificationActions.showNotification(message, color));
    dispatch(popupActions.handleApiResponse(apiResponse));
    dispatch(push(returnRoutePath));
  };

  onSubmitSucceed = () => {
    const { dispatch } = this.props;
    const message = 'Operação concluída. Os dados foram atualizados com sucesso.';
    const color = 'success';
    dispatch(notificationActions.showNotification(message, color));
    dispatch(push(returnRoutePath));
  };

  onSubmitFail = (frontendErrors, d, apiResponse) => {
    const { dispatch } = this.props;
    const message = 'Operação falhou. Não foi possível atualizar os dados.';
    const color = 'danger';
    dispatch(notificationActions.showNotification(message, color));
    dispatch(popupActions.handleApiResponse(apiResponse));
  };

  computeFields = () => {
    const { formValues, dispatch, state } = this.props;
    if (fields instanceof Function) {
      return fields(formValues, dispatch, state);
    }
    return fields;
  }

  render() {
    const computedFields = this.computeFields();
    const { DefaultFormWithFetch } = this;
    return (
      <DefaultFormWithFetch
        form={formName}
        fields={computedFields}
        submitLabel={submitLabel || 'Atualizar'}
        resetLabel={resetLabel || 'Restaurar'}
        onSubmit={this.editEntity}
        onSubmitSuccess={this.onSubmitSucceed}
        onSubmitFail={this.onSubmitFail}
      />
    );
  }
});

export default generateEntityEditor;
