import { store } from '../../../store';
import employeeActions from '../../../actions/employeeActions';
import { getAbility } from '../../../reducers/sessionReducer';
import sortObjectsArrayByProperty from '../../../utils/sortObjectsArrayByProperty';

const prospectInputFields = [
  {
    name: 'name',
    label: 'Nome',
  },
  {
    name: 'userId',
    label: 'Assessor responsável',
    notRenderable: getAbility(store.getState()).cannot('update', 'Client'),
    type: 'object-select',
    meta: { valueKey: 'id', labelKey: 'name' },
    options: [],
    async: {
      fetch: () => store.dispatch(employeeActions.fetchAll()),
      fetchDelay: 500,
      mapSuccessfulResult: response => sortObjectsArrayByProperty(Object.values(response.data.entities.employees), 'name'),
      mapProps: ({ response, ...others }) => ({ ...others, options: response }),
    },
    width: 320,
  },
  {
    name: 'email',
    label: 'E-mail',
  },
  {
    name: 'telephone',
    label: 'Telefone',
    width: 150,
  },
  {
    name: 'description',
    label: 'Descrição',
  },
  {
    name: 'referral',
    label: 'Indicação',
  },
];

export default prospectInputFields;
