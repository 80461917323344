import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ChartistGraph from 'react-chartist';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import InfoIcon from '@material-ui/icons/Info';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import style from '../../assets/jss/material-dashboard-pro-react/components/chartsStyle.js';
import withStyles from '@material-ui/core/styles/withStyles';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import HeaderCard from '../../mui-components/Cards/HeaderCard';
import Table from '../../mui-components/Table/Table';
import StatsCard from '../../mui-components/Cards/StatsCard';

import {
  stackedBarChart,
  barChart,
  scatterChart
} from "../../variables/charts";

import sanitizeValue from '../../utils/sanitizeValue';
import cacheActions from '../../actions/cacheActions';
import { getEntity } from '../../reducers/entitiesReducer';
import notificationActions from '../../containers/general/notification/NotificationsManager/actions';
import reportActions from '../../actions/reportActions';

class ClientsReportView extends React.Component {
  componentWillMount() {
    this.onMount();
  }

  onMount = () => {
    this.props.dispatch(cacheActions.cleanCache());
    this.fetchEntities().catch(this.onFetchFail);
  };

  onFetchFail = apiResponse => this.props.dispatch(notificationActions.handleApiError(apiResponse));

  fetchEntities = () => this.props.dispatch(reportActions.clients());

  render () {
    const {classes, entity} = this.props;
    const clientTableHead = ['Segmentação','Classe','Quantidade','Percentual','Percentual acumulado'];
    const clientTableNETHead = ['Segmentação','Classe','NET','Percentual','Percentual acumulado'];
    let clientsByContractOverTimeHead = ['Fornecedor'];
    let clientsByContractOverTimeBody = [];
    let customBarChart = barChart;
    customBarChart.options.axisY = {
      labelInterpolationFnc: function(value) {
        if(value >= 1000000) {
          return (value/1000000) + 'M'
        } else if (value >= 1000) {
          return (value/1000) + 'k'
        } else {
          return value
        }
      }
    }

    if(entity) {
      scatterChart.xAxis = {title: { text: 'NET' } };
      scatterChart.yAxis = {title: { text: 'Receita líquida média' } };
      scatterChart.series = entity.netVsNetProfitMean;

      entity.clientsByContractOverTime.labels.map((name) => {
        clientsByContractOverTimeHead = clientsByContractOverTimeHead.concat(name)
      })
      clientsByContractOverTimeBody = entity.clientsByContractOverTime.series.map((serie, index) => {
        let items = [ entity.clientsByContractOverTime.names[index] ];
        serie.map((value) => { items = items.concat(value) });
        return items;
      })
    }

    return (
      <>
      <GridContainer>
        <ItemGrid xs={12} sm={12} md={12} lg={12} className='last'>
          <GridContainer className='last'>
            {entity && entity.inlineInfos.map((info, index) => {
              const description = sanitizeValue(info.type, info.value)
              const icon = info.type === 'currency' ? AttachMoneyIcon : InfoIcon
              return <ItemGrid key={index} xs={12} sm={4} md={4} lg={4}>
                <StatsCard icon={icon} title={info.name} description={description} />
              </ItemGrid>
            })}
          </GridContainer>
        </ItemGrid>
      </GridContainer>
        <GridContainer>
          <ItemGrid xs={12} sm={12} md={12} lg={12}>
            {entity &&
              <HeaderCard
                cardTitle="Clientes total por tipo de contrato"
                headerColor="orange"
                content={<Table tableHead={clientsByContractOverTimeHead} tableData={clientsByContractOverTimeBody} />}
              />
            }
            {/*<HeaderCard
              cardTitle="Clientes total por tipo de contrato"
              headerColor="orange"
              content={entity &&
                <>
                  <ChartistGraph
                    data={entity.clientsByContractOverTime}
                    type="Bar"
                    stackBars
                    options={stackedBarChart.options}
                    listener={stackedBarChart.animation}
                  />
                  {entity.clientsByContractOverTime.names.map((name, index) => {
                    return <span key={index}><i className={"fas fa-circle " + classes[`chartColor${index}`]} />{` ${name} `}</span>
                  })}
                </>
              }
            />*/}
          </ItemGrid>
        </GridContainer>

        <GridContainer>
          <ItemGrid xs={12} sm={12} md={6} lg={6}>
            <HeaderCard
              cardTitle="NET total no tempo"
              headerColor="orange"
              content={entity &&
                <ChartistGraph
                  data={entity.netOverTime}
                  type="Bar"
                  options={customBarChart.options}
                  listener={customBarChart.animation}
                />
              }
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={6} lg={6}>
            <HeaderCard
              cardTitle="Ticket Médio no tempo"
              headerColor="orange"
              content={entity &&
                <ChartistGraph
                  data={entity.netProfitMeansOverTime}
                  type="Line"
                  options={customBarChart.options}
                  listener={customBarChart.animation}
                />
              }
            />
          </ItemGrid>
        </GridContainer>

        <GridContainer>
          <ItemGrid xs={12} sm={12} md={6} lg={6}>
            <HeaderCard
              cardTitle="Clientes total por classe"
              headerColor="orange"
              content={entity && <Table tableHead={clientTableHead} tableData={entity.clientsByClassCount} />}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={6} lg={6}>
            <HeaderCard
              cardTitle="Clientes total por NET"
              headerColor="orange"
              content={entity && <Table tableHead={clientTableNETHead} tableData={entity.clientsByClassNet} />}
            />
          </ItemGrid>
        </GridContainer>

        <GridContainer>
          <ItemGrid xs={12} sm={12} md={12} lg={12}>
            <HeaderCard
              cardTitle="Relação entre NET e Receita média dos clientes"
              headerColor="orange"
              content={entity &&
                <HighchartsReact
                  highcharts={Highcharts}
                  options={scatterChart}
                />
              }
            />
          </ItemGrid>
        </GridContainer>
      </>
    )
  }
}

ClientsReportView.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  entity: PropTypes.object,
};

const mapStateToProps = state => ({
  entity: getEntity('reports', 2)(state),
});

export default connect(mapStateToProps)(withStyles(style)(ClientsReportView));
