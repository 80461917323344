import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Card from '../Card/Card';
import CardBody from '../Card/CardBody';

import kanbanContainerStyle from "assets/jss/material-dashboard-pro-react/components/kanbanContainerStyle";

const KanbanContainer = ({ content, classes }) => (
  <Card className={classes.card}>
    {content}
  </Card>
);

KanbanContainer.propTypes = {
  content: PropTypes.any.isRequired,
  classes: PropTypes.any.isRequired
};

export default withStyles(kanbanContainerStyle)(KanbanContainer);
