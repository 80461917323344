import { push } from 'react-router-redux';

import types from './types';
import { store } from '../store';

const login = (email, password) => ({
  API_CALL: {
    requestType: types.LOGIN_REQUEST,
    successType: types.LOGIN_SUCCESS,
    failureType: types.LOGIN_FAILURE,
    endpoint: 'login',
    params: { email, password },
    onSuccess: () => store.dispatch(push('/')),
  },
});

const getPasswordToken = (email) => ({
  API_CALL: {
    requestType: types.LOGIN_REQUEST,
    successType: types.LOGIN_SUCCESS,
    failureType: types.LOGIN_FAILURE,
    endpoint: 'users/password',
    params: { email }
  },
});

const updatePassword = (password, token) => ({
  API_CALL: {
    requestType: types.LOGIN_REQUEST,
    successType: types.LOGIN_SUCCESS,
    failureType: types.LOGIN_FAILURE,
    httpMethod: 'PUT',
    endpoint: 'users/password',
    params: { password, token }
  },
});

const logout = () => ({
  type: types.LOGOUT,
});

export default {
  login,
  logout,
  getPasswordToken,
  updatePassword,
};
