import employeeActions from '../../../actions/employeeActions';
import employeeInputFields from './employeeInputFields';
import generateEntityEditor from '../../../hocs/generateEntityEditor';

const EmployeeEditor = generateEntityEditor({
  entitySingularString: 'employee',
  entityPluralString: 'employees',
  entityActions: employeeActions,
  returnRoutePath: '/employees-listing',
  fields: employeeInputFields,
});

export default EmployeeEditor;
