
import contractTypeActions from '../../../actions/contractTypeActions';
import contractTypeTableColumns from './contractTypeTableColumns';
import generateEntityTable from '../../../hocs/generateEntityTable';

const ContractTypesTable = generateEntityTable({
  entityPluralString: 'contractTypes',
  entityActions: contractTypeActions,
  editorRoutePath: '/contract-type-edition',
  columns: contractTypeTableColumns,
});

export default ContractTypesTable;
