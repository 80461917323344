import { store } from '../../../store';
import partnerEnterpriseActions from '../../../actions/partnerEnterpriseActions';
import sortObjectsArrayByProperty from '../../../utils/sortObjectsArrayByProperty';

const employeeInputFields = [
  {
    name: 'name',
    label: 'Nome',
  },
  {
    name: 'email',
    label: 'E-mail',
  },
  {
    name: 'cpf',
    label: 'CPF',
    width: 120,
  },
  {
    name: 'accessProfile',
    label: 'Perfil de acesso',
    type: 'select',
    options: ['Gestor', 'Assessor'],
    width: 150,
  },
  {
    name: 'ciCode',
    label: 'Código CI',
    width: 120,
  },
  {
    name: 'xpCode',
    label: 'Código XP',
    width: 120,
  },
  {
    name: 'telephone',
    label: 'Telefone',
    width: 120,
  },
  {
    name: 'employeeFunctionId',
    label: 'Função',
    type: 'object-select',
    meta: { valueKey: 'id', labelKey: 'name' },
    options: [
      { id: 1, name: 'Vendedor' },
      { id: 2, name: 'Secretária(o)' },
    ],
    width: 150,
  },
  {
    name: 'addresses',
    label: 'Endereços',
    type: 'array',
    itemField: {
      label: 'Endereço',
      type: 'group',
      fields: [
        { name: 'name', label: 'Nome', width: 330 },
        { name: 'cep', label: 'CEP', width: 100 },
        { name: 'street', label: 'Logradouro', width: 450 },
        { name: 'number', label: 'Nº', width: 35 },
        { name: 'complement', label: 'Complemento' },
        { name: 'neighborhood', label: 'Bairro', width: 160 },
        { name: 'city', label: 'Cidade', width: 160 },
        { name: 'state', label: 'Estado', type: 'select', options: ['AC','AL','AM','AP','BA','CE','DF','ES','GO','MA','MG','MS','MT','PA','PB','PE','PI','PR','RJ','RN','RO','RR','RS','SC','SE','SP','TO'], width: 75 },
        { name: 'country', label: 'País', type: 'select', options: ['Brasil', 'Estados Unidos'], width: 90 },
      ],
    },
  },
  {
    name: 'remunerationTables',
    label: 'Tabela de repasses',
    type: 'array',
    itemField: {
      label: 'Repasse',
      type: 'group',
      fields: [
        {
          name: 'enterpriseId',
          label: 'Fornecedor',
          type: 'object-select',
          meta: { valueKey: 'id', labelKey: 'name' },
          options: [],
          async: {
            fetch: () => store.dispatch(partnerEnterpriseActions.fetchAll()),
            fetchDelay: 500,
            mapSuccessfulResult: response => sortObjectsArrayByProperty(Object.values(response.data.entities.partnerEnterprises), 'name'),
            mapProps: ({ response, ...others }) => ({ ...others, options: response }),
          },
          width: 180,
        },
        { name: 'percentageOfTransfer', label: '% transf', type: 'number', width: 80 },
      ],
    },
  },
  {
    name: 'password',
    label: 'Senha',
    type: 'password',
  },
];

export default employeeInputFields;
