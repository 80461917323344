import { AbilityBuilder } from '@casl/ability';

const abilities = {

  Gestor: AbilityBuilder.define((can, cannot) => {
    can('manage', 'all');
    cannot('create', [
      'MyClient',
      'MyTask',
    ]);
  }),

  Assessor: AbilityBuilder.define((can) => {
    can('read', [
      'MainDashboard',
      'DashboardSpreadsheetIncome',
      'DashboardClients',
      'Dashboard',
      'MyClient',
      'MyTask',
      'MySpreadsheetIncome',
    ]);
    can('create', [
      'MyClient',
      'MyTask',
      'MultipleTask',
      'ClientSpreadsheet',
      'MultipleClientEmail',
    ]);
    can('update', [
      'Myself',
      'MyClient',
      'MyTask',
      'MultipleClient',
    ]);
    can('delete', [
      'MyClient',
      'MyTask',
    ]);
  }),

  Visitante: AbilityBuilder.define(() => {}),

};

export default abilities;
