import myClientActions from '../../../actions/myClientActions';
import clientInputFields from './clientInputFields';
import generateEntityCreator from '../../../hocs/generateEntityCreator';

const MyClientCreator = generateEntityCreator({
  entityActions: myClientActions,
  returnRoutePath: 'my-clients-listing',
  fields: clientInputFields,
});

export default MyClientCreator;
