
import partnerEnterpriseActions from '../../../actions/partnerEnterpriseActions';
import partnerEnterpriseInputFields from './partnerEnterpriseInputFields';
import generateEntityCreator from '../../../hocs/generateEntityCreator';

const PartnerEnterpriseCreator = generateEntityCreator({
  entityActions: partnerEnterpriseActions,
  returnRoutePath: 'partner-enterprises-listing',
  fields: partnerEnterpriseInputFields,
});

export default PartnerEnterpriseCreator;
