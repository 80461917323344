import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import QueryString from 'query-string';

import SpreadsheetIncomesFilterForm from '../../../components/domain/spreadsheet-income/SpreadsheetIncomesFilterForm';
import partnerEnterpriseActions from '../../../actions/partnerEnterpriseActions';
import notificationActions from '../../general/notification/NotificationsManager/actions';
import { getEntitiesArray } from '../../../reducers/entitiesReducer';

class SpreadsheetIncomesFilter extends React.Component {

  componentWillMount() {
    this.fetchPartnerEnterprises()
      .catch(this.onOperationFail);
  }

  fetchPartnerEnterprises = () => this.props.dispatch(partnerEnterpriseActions.fetchAll());

  onOperationFail = apiResponse => this.props.dispatch(notificationActions.handleApiError(apiResponse));

  submit = (inputs) => {
    const { page } = this.props;
    const params = QueryString.stringify(inputs);
    const url = `${page}?${params}`;
    this.props.dispatch(push(url));
  }

  render() {
    const { partnerEnterprises } = this.props;
    const initialValues = {
      year: `${new Date().getFullYear()}`,
      month: `${new Date().getMonth() + 1}`,
      incomeListingType: 'Financeiro',
    };
    return (
      <SpreadsheetIncomesFilterForm
        form="spreadsheetIncomesFilter"
        partnerEnterprises={partnerEnterprises}
        onSubmit={this.submit}
        initialValues={initialValues}
      />
    );
  }

}

SpreadsheetIncomesFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  partnerEnterprises: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  partnerEnterprises: getEntitiesArray('partnerEnterprises')(state),
});

export default connect(mapStateToProps)(SpreadsheetIncomesFilter);
