import types from '../actions/types';
import ciApi from '../services/ciApi';

export const API_CALL = 'API_CALL';

const defaultRequestType = types.API_CALL_REQUEST;
const defaultSuccessType = types.API_CALL_SUCCESS;
const defaultFailureType = types.API_CALL_FAILURE;

const apiMiddleware = store => next => (action) => {
  const apiCall = action[API_CALL];
  if (!apiCall) {
    return next(action);
  }

  const {
    httpMethod = 'POST',
    requestType = defaultRequestType,
    successType = defaultSuccessType,
    failureType = defaultFailureType,
    onStart = () => {},
    onSuccess = () => {},
    onFailure = () => {},
    onFinish = () => {},
    params = {},
    queryParams = {},
    attachments,
    schema,
    entitiesDeleted,
  } = apiCall;

  let { endpoint } = apiCall;
  endpoint = createEndpoint(endpoint, store.getState());

  const finalAction = { ...action };
  delete finalAction[API_CALL];

  const dispatchRequestAction = (request) => {
    next({
      type: requestType,
      request,
      ...finalAction,
    });
    if (requestType !== defaultRequestType) {
      next({
        type: defaultRequestType,
        request,
        ...finalAction,
      });
    }
    onStart();
  };

  const dispatchResponseAction = (response) => {
    next({
      type: successType,
      response,
      entitiesDeleted,
      ...finalAction,
    });
    if (successType !== defaultSuccessType) {
      next({
        type: defaultSuccessType,
        response,
        entitiesDeleted,
        ...finalAction,
      });
    }
    onSuccess(response);
    onFinish(true);
  };

  const dispatchErrorAction = (response) => {
    next({
      type: failureType,
      response,
      ...finalAction,
    });
    if (failureType !== defaultFailureType) {
      next({
        type: defaultFailureType,
        request,
        ...finalAction,
      });
    }
    onFailure(response);
    onFinish(false);
  };

  const request = { httpMethod, endpoint, queryParams, params, attachments, schema };
  return callApi(request, dispatchRequestAction, dispatchResponseAction, dispatchErrorAction);
};

const createEndpoint = (endpointBuilder, state) => {
  if (typeof endpointBuilder === 'function') {
    return endpointBuilder(state);
  }
  return endpointBuilder;
};

const callApi = (request, onStart, onSuccess, onError) => {
  onStart(request);
  return ciApi.call(request).then(
    (response) => {
      onSuccess(response);
      return response;
    },
    (error) => {
      onError(error);
      return Promise.reject(error);
    },
  );
};

export default apiMiddleware;
