// ##############################
// // // RankingListItem styles
// #############################

import {
  card,
  defaultFont,
  grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const kanbanColumnStyle = {
  card: {
    ...card,
    boxShadow: 'none',
    borderRadius: '0',
    borderLeft: 'solid 1px #CCC',
    borderRight: 'solid 1px #CCC',
    width: '14.285%',
    float: 'left',
    margin: 0
  },
  cardTitle: {
    color: '#666',
    textAlign: 'center',
    fontSize: '1.1em',
    padding: '0 1.5em'
  },
  cardCards: {
    padding: '1em 0.8em'
  }
};

export default kanbanColumnStyle;
