import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getSearchParam } from '../../reducers/routerSelectors';

import PermIdentity from '@material-ui/icons/PermIdentity';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import TitleCard from '../../mui-components/Card/TitleCard';
import MultipleClientEditor from '../../containers/domain/client/MultipleClientEditor';

class MultipleClientEditionView extends Component {
  render() {
    const { client_ids } = this.props;
    return (
      <GridContainer>
        <ItemGrid xs={12} sm={12} md={9} lg={7}>
          <TitleCard
            icon={PermIdentity}
            titleColor="primary"
            title="Atualização de cliente"
            content={<MultipleClientEditor initialValues={{client_ids: client_ids}} />}
          />
        </ItemGrid>
      </GridContainer>
    )
  }
}

MultipleClientEditionView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  client_ids: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  let client_ids = getSearchParam(state)('client_ids')
  client_ids = Array.isArray(client_ids) ? client_ids : Array(client_ids)

  return { client_ids: client_ids }
};

export default connect(mapStateToProps)(MultipleClientEditionView);
