import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Card from './Card';
import CardBody from './CardBody';

const style = {
  card: {
    marginTop: 0,
  },
};

const RegularCard = ({ content, classes }) => (
  <Card className={classes.card}>
    <CardBody>
      {content}
    </CardBody>
  </Card>
);

RegularCard.propTypes = {
  content: PropTypes.any.isRequired,
  classes: PropTypes.any.isRequired,
};

export default withStyles(style)(RegularCard);
