import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormControl from '@material-ui/core/FormControl';
import { Select } from 'redux-form-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';

const style = {
  selectWrapper: {
    width: '100%',
  },
};

const ConfFormObjectSelectField = ({ name, label, options, meta, disabled, classes, onChange }) => (
  <FormControl className={classes.selectWrapper}>
    <InputLabel htmlFor={name}>{label}</InputLabel>
    <Field
      name={name}
      placeholder={label}
      disabled={disabled}
      component={Select}
      onChange={onChange}
    >
      <MenuItem key={-1} value=""><em>Nenhum</em></MenuItem>
      {options && options.map && options.map(option => (
        <MenuItem
          key={option[meta.valueKey]}
          value={option[meta.valueKey]}
        >
          {option[meta.labelKey]}
        </MenuItem>
      ))}
    </Field>
  </FormControl>
);

ConfFormObjectSelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  meta: PropTypes.shape({
    valueKey: PropTypes.string.isRequired,
    labelKey: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
};

ConfFormObjectSelectField.defaultProps = {
  options: [],
  disabled: false,
};

export default withStyles(style)(ConfFormObjectSelectField);
