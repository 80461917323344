import employeeActions from "../../../actions/employeeActions";
import { getAccessProfile } from "../../../reducers/sessionReducer";
import sortObjectsArrayByProperty from "../../../utils/sortObjectsArrayByProperty";
import _ from "lodash";

const clientSpreadsheetInputFields = (formValues, dispatch, state) => {
  let fields = [
    {
      name: "month",
      label: "Mês",
      type: "number",
      width: 90,
    },
    {
      name: "year",
      label: "Ano",
      type: "number",
      width: 90,
    },
    {
      name: "headersLine",
      label: "Linha dos cabeçalhos",
      width: 180,
    },
    {
      name: "sheetName",
      label: "Nome da aba",
      width: 180,
    },
    {
      name: "file",
      label: "Arquivo de planilha",
      type: "file",
    },
    {
      name: "headers",
      label: "Cabeçalhos",
      type: "group",
      fields: [
        { name: "name", label: "Nome", width: 200 },
        { name: "email", label: "E-mail", width: 200 },
        { name: "alternativeEmail", label: "E-mail alternativo", width: 200 },
        { name: "cpf", label: "CPF", width: 200 },
        { name: "xpCode", label: "Código XP", width: 200 },
        { name: "icatuCode", label: "Código Icatu", width: 200 },
        { name: "mongeralCode", label: "Código Mongeral", width: 200 },
        { name: "btgCode", label: "Código BTG", width: 200 },
        { name: "telephone", label: "Telefone", width: 200 },
        { name: "alternativeTelephone", label: "Telefone alternativo", width: 200 },
        { name: "investmentProfile", label: "Perfil XP", width: 200 },
        { name: "internInvestmentProfile", label: "Perfil Confiança", width: 200 },
        { name: "income", label: "Renda mensal", width: 200 },
        { name: "incomeYear", label: "Renda anual", width: 200 },
        { name: "monthlyExpenses", label: "Gastos mensais", width: 200 },
        { name: "net", label: "NET XP", width: 200 },
        { name: "netBtg", label: "NET BTG Assessoria", width: 200 },
        { name: "netBtgConsultancy", label: "NET BTG Consultoria", width: 200 },
        { name: "netBb", label: "NET BB", width: 200 },
        { name: "netSantander", label: "NET Santander", width: 200 },
        { name: "netItau", label: "NET Itaú", width: 200 },
        { name: "netBradesco", label: "NET Bradesco", width: 200 },
        { name: "netCaixa", label: "NET Caixa", width: 200 },
        { name: "netAvenue", label: "NET Avenue", width: 200 },
        { name: "netOutros", label: "NET Outros", width: 200 },
        { name: "responsibleBtg", label: "Responsável BTG", width: 200 },
        { name: "civilStatus", label: "Estado Civil", width: 200 },
        { name: "btgProfile", label: "Perfil BTG", width: 200 },
        { name: "btgBankAccount", label: "Conta corrente BTG", width: 200 },
        { name: "fundsBtg", label: "Fundos BTG", width: 200 },
        { name: "fixedIncomeBtg", label: "Renda fixa BTG", width: 200 },
        { name: "variableIncomeBtg", label: "Renda variável BTG", width: 200 },
        { name: "socialSecurityBtg", label: "Previdência BTG", width: 200 },
        { name: "derivativesBtg", label: "Derivativos BTG", width: 200 },
        { name: "plDeclaredBtg", label: "PL Declarado BTG", width: 200 },
        { name: "savingCapacity", label: "Capacidade de poupança", width: 200 },
        { name: "profession", label: "Profissão", width: 200 },
        { name: "professionRole", label: "Cargo que exerce", width: 200 },
        { name: "accreditedInvestor", label: "É investidor qualificado", width: 200 },
        { name: "city", label: "Cidade", width: 200 },
        { name: "birthday", label: "Data de nascimento", width: 200 },
        { name: "ciCode", label: "Código CI", width: 200 },
        { name: "referral", label: "Indicação", width: 200 },
        { name: "description", label: "Informações importantes", width: 200 },
      ],
    },
  ];

  if (getAccessProfile(state) === "Gestor") {
    fields.unshift({
      name: "assessorId",
      label: "Assessor",
      type: "object-select",
      meta: { valueKey: "id", labelKey: "name" },
      options: [],
      async: {
        fetch: () => dispatch(employeeActions.fetchAll()),
        fetchDelay: 500,
        mapSuccessfulResult: (response) => sortObjectsArrayByProperty(Object.values(response.data.entities.employees), "name"),
        mapProps: ({ response, ...others }) => ({ ...others, options: response }),
      },
      width: 320,
    });
  } else {
    _.remove(fields, (field) => field.name !== "file");
  }
  return fields;
};

export default clientSpreadsheetInputFields;
