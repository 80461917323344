import React from 'react';
import PropTypes from 'prop-types';
import { TableSelection } from '@devexpress/dx-react-grid-material-ui';

const ConfTableSelection = ({ style, ...restProps }) => (
  <TableSelection {...restProps} style={{ ...style }} />
);

ConfTableSelection.propTypes = {
  style: PropTypes.object,
};

ConfTableSelection.defaultProps = {
  style: {},
};

export default ConfTableSelection;
