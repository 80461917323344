
import internEnterpriseActions from '../../../actions/internEnterpriseActions';
import internEnterpriseTableColumns from './internEnterpriseTableColumns';
import generateEntityTable from '../../../hocs/generateEntityTable';

const InternEnterprisesTable = generateEntityTable({
  entityPluralString: 'internEnterprises',
  entityActions: internEnterpriseActions,
  editorRoutePath: '/intern-enterprise-edition',
  columns: internEnterpriseTableColumns,
});

export default InternEnterprisesTable;
