import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import ExitToApp from '@material-ui/icons/ExitToApp';

import headerLinksStyle from '../../assets/jss/material-dashboard-pro-react/components/headerLinksStyle';
import authActions from '../../actions/authActions';

class HeaderLinks extends React.Component {

  state = {
    open: false,
  };

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  logout = () => this.props.dispatch(authActions.logout());

  render() {
    const { classes, rtlActive } = this.props;
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive,
    });
    return (
      <div className={wrapper}>
        <IconButton
          color="inherit"
          aria-label="Logout"
          onClick={this.logout}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          classes={{
            label: rtlActive ? classes.labelRTL : '',
          }}
        >
          <ExitToApp className={rtlActive ? `${classes.links} ${classes.linksRTL}` : classes.links} />
          <Hidden mdUp>
            <p className={classes.linkText}>
              {rtlActive ? 'rtl' : 'Logout'}
            </p>
          </Hidden>
        </IconButton>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

HeaderLinks.defaultProps = {
  rtlActive: false,
};

export default connect()(withStyles(headerLinksStyle)(HeaderLinks));
