import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Accordion from '../../mui-components/Accordion/Accordion';
import GenericField from './ConfFormGenericField';

const style = {
  box: {
    width: '100%',
    padding: '8px 16px',
    backgroundColor: 'rgba(200, 200, 200, 0.125)',
    borderRadius: '4px',
    border: '1px solid rgba(200, 200, 200, 0.25)',
  },
};

const ConfFormFieldsAccordion = ({ label, fields, classes }) => (
  <Accordion
    collapses={[{
      title: label,
      content: (
        <div className={classes.box}>
          {fields.map((field, index) => (
            <GenericField key={index} {...field} />
          ))}
        </div>
      ),
    }]}
  />
);

ConfFormFieldsAccordion.propTypes = {
  label: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(ConfFormFieldsAccordion);
