import clientActions from '../../../actions/clientActions';
import prospectInputFields from './prospectInputFields';
import generateEntityCreator from '../../../hocs/generateEntityCreator';

const ProspectCreator = generateEntityCreator({
  entityActions: clientActions,
  returnRoutePath: '/acompanhamento-de-tarefas',
  fields: prospectInputFields,
});

export default ProspectCreator;
