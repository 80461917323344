
import contractTypeActions from '../../../actions/contractTypeActions';
import contractTypeInputFields from './contractTypeInputFields';
import generateEntityCreator from '../../../hocs/generateEntityCreator';

const ContractTypeCreator = generateEntityCreator({
  entityActions: contractTypeActions,
  returnRoutePath: 'contract-types-listing',
  fields: contractTypeInputFields,
});

export default ContractTypeCreator;
