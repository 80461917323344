import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@devexpress/dx-react-grid-material-ui';

import ConfTableBaseCellValue from './ConfTableBaseCellValue';

const ConfTableNonEditableCell = (props) => {
  const { value, column } = props;
  return (
    <Table.Cell {...props}>
      <div style={{ minHeight: 20 }}>
        <ConfTableBaseCellValue value={value} column={column} />
      </div>
    </Table.Cell>
  );
};

ConfTableNonEditableCell.propTypes = {
  value: PropTypes.any,
  column: PropTypes.any.isRequired,
};

ConfTableNonEditableCell.defaultProps = {
  value: '',
};

export default ConfTableNonEditableCell;
