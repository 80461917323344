// ##############################
// // // RankingListItem styles
// #############################

import {
  card,
  defaultFont,
  grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const kanbanCardStyle = {
  card: {
    ...card,
    boxShadow: "none",
    background: '#EEEEEE',
    padding: '.5em',
    margin: '0 0 0.8em',
  },
  cardContent: {
    textAlign: "center",
    paddingTop: "10px",
    padding: "15px 0",
    alignItems: "center",
    justifyContent: "space-around"
  },
  cardTitle: {
    ...defaultFont,
    margin: "0",
    fontSize: "1.1em",
    textAlign: "left",
    fontWeight: "bold",
    textAlign: "center",
  },
  cardDescription: {
    ...defaultFont,
    margin: "0",
    fontSize: "1em",
    textAlign: "center",
    marginBottom: '10px'
  },
  cardButtonEdit: {
    width: '100%',
    fontSize: ".8em",
    marginTop: '10px',
    color: '#cca756',
    border: '1px solid #cca756',
    '&:hover': {
      background: '#cca756',
      color: 'white'
    },
  },
  cardButtonLost: {
    width: '100%',
    fontSize: ".8em",
    marginTop: '10px',
    background: '#050E12',
    '&:hover': {
      background: '#333',
    },
  },
  cardButtonFinish: {
    background: '#cca756',
    width: '100%',
    fontSize: ".8em",
    marginTop: '10px',
    '&:hover': {
      background: '#deaf45',
      color: 'white'
    },
  },
};

export default kanbanCardStyle;
