import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@devexpress/dx-react-grid-material-ui';

const ConfTableOperationsCell = (props) => {
  const { row, column } = props;
  const { operations } = column;
  return (
    <Table.Cell {...props}>
      {operations.map(operation => (
        // eslint-disable-next-line
        <a
          key={operation.title}
          style={{ marginRight: 15, lineHeight: 1 }}
          onClick={() => operation.execute(row.id)}
        >
          {operation.content || operation.title}
        </a>
      ))}
    </Table.Cell>
  );
};

ConfTableOperationsCell.propTypes = {
  row: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

export default ConfTableOperationsCell;
