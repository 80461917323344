import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';

import popupActions from '../../general/popup/PopupsManager/actions';
import clientActions from '../../../actions/clientActions';
import notificationActions from '../../general/notification/NotificationsManager/actions';
import { getSearchParam, getSearch } from '../../../reducers/routerSelectors';
import DefaultTable from '../../general/conf-table/ConfTable';
import clientTableColumns from './clientTableColumns';
import Button from '../../../components/miscellaneous/Button';


class ClientsMerging extends Component {

  state = {
    client1: {},
    client2: {},
    selectedClientId: null,
  }
  
  componentWillMount() {
    this.loadClients();
  }

  saveClients = (clients) => {
    this.setState({
      client1: clients[0],
      client2: clients[1],
    });
  }

  loadClients = async () => {
    try {
      const { clientId1, clientId2 } = this.props;
      const results = await this.fetchClients();
      this.saveClients([
        results[0].data.entities.clients[clientId1],
        results[1].data.entities.clients[clientId2],
      ]);
    } catch (error) {
      const errorMessage = 'Não foi possível encontrar os clientes selecionados.';
      this.props.dispatch(popupActions.openErrorPopup(errorMessage));
    }
  }

  fetchClients = async () => {
    const { clientId1, clientId2 } = this.props;

    return Promise.all([
      this.fetchClient(clientId1),
      this.fetchClient(clientId2),
    ]);
  }

  getPrimaryClient = () => {
    const { client1, client2, selectedClientId } = this.state;
    return client1.id === selectedClientId ? client1.id : client2.id;
  }

  getSecundaryClient = () => {
    const { client1, client2, selectedClientId } = this.state;
    return client2.id === selectedClientId ? client1.id : client2.id;
  }

  saveMerge = async () => {
    try {
      await this.mergeClients(this.getPrimaryClient(), this.getSecundaryClient());
      this.onSubmitSucceed();
    } catch (error) {
      const errorMessage = 'Não foi possível fazer a operação de combinação.';
      this.props.dispatch(popupActions.openErrorPopup(errorMessage));
    }
  }

  onSubmitSucceed = () => {
    const { dispatch } = this.props;
    const message = 'Operação concluída. Os dados foram cadastrados com sucesso.';
    const color = 'success';
    dispatch(notificationActions.showNotification(message, color));
    dispatch(push('clients-listing'));
  }

  mergeClients = (clientId1, clientId2) => this.props.dispatch(clientActions.merge(clientId1, clientId2));

  fetchClient = id => this.props.dispatch(clientActions.fetch(id));

  updateSelectedClients = (selectedClients) => {
    if (selectedClients[0]) {
      this.setState({ selectedClientId: selectedClients[0].id });
    } else {
      this.setState({ selectedClientId: null });
    }
  }
  
  render() {
    return (
      <React.Fragment>
        <p style={{ fontSize: 16 }}>
          Selecione um cliente primário:
        </p>
        <DefaultTable
          id="MergingTable"
          columns={clientTableColumns}
          dataList={[this.state.client1, this.state.client2]}
          onChangeColumnSelection={this.updateSelectedClients}
          maxSelections={1}
          height={190}
          enableFilters={false}
          enableSelection
        />
        <Button
          color="primary"
          variant="contained"
          type="submit"
          style={{ marginTop: 15 }}
          onClick={this.saveMerge}
          disabled={!this.state.selectedClientId}
        >
          Salvar Combinação
        </Button>
      </React.Fragment>
    );
  }
}

ClientsMerging.propTypes = {
  dispatch: PropTypes.func.isRequired,
  clientId1: PropTypes.string.isRequired,
  clientId2: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  clientId1: getSearchParam(state)('client1'),
  clientId2: getSearchParam(state)('client2'),
  search: getSearch(state),
});

export default connect(mapStateToProps)(ClientsMerging);
