import _ from 'lodash';
import axios from 'axios';
import QueryString from 'query-string';
import { normalize } from 'normalizr';

import { store } from '../store';
import { getToken } from '../reducers/sessionReducer';
import environmentUtil from '../utils/environment';
import camelize from '../utils/camelize';
import snakelize from '../utils/snakelize';
import runtimeEnv from '@mars/heroku-js-runtime-env';

const getApiBaseUrl = () => {
  const env = runtimeEnv();
  const host = env.REACT_APP_API_URL;
  const protocol = env.REACT_APP_API_PROTOCOL;
  const apiPath = env.REACT_APP_API_PATH;
  return `${protocol}://${host}${apiPath}`;
};

const getRequestConfigHeaders = () => {
  const headers = {};
  const token = getToken(store.getState());
  if (token) {
    headers.Authorization = `Token ${token}`;
    headers.Accept = 'application/json';
  }
  return headers;
};

const getRequestConfig = () => {
  const baseURL = getApiBaseUrl();
  const headers = getRequestConfigHeaders();
  const timeout = 300000;

  return { baseURL, timeout, headers };
};

const snakelizeEndpoint = (endpoint) => {
  let formattedEndpoint = '';
  const pieces = endpoint.split('/');
  for (const piece of pieces) {
    formattedEndpoint += `${_.snakeCase(piece)}/`;
  }
  return formattedEndpoint;
};

const stringifyQueryParams = (queryParams) => {
  const snakelizedQueryParams = {};
  Object.keys(queryParams).forEach((key) => {
    const snakelizedKey = _.snakeCase(key);
    snakelizedQueryParams[snakelizedKey] = queryParams[key];
  });
  return `?${QueryString.stringify(snakelizedQueryParams)}`;
};

const isHttpStatusSuccessful = status => (status >= 200 && status < 300);

const formatApiResult = (result) => {
  if (isHttpStatusSuccessful(result.status)) {
    return {
      data: result.data,
      httpStatus: result.status,
      isSuccess: true,
    };
  } if (result.response) {
    return {
      data: result.response.data,
      httpStatus: result.response.status,
      isSuccess: false,
    };
  }
  return {
    data: result.request,
    httpStatus: 0,
    isSuccess: false,
  };
};

const formatResponse = (response, schema) => {
  let formattedResponse = camelize(response);
  if (schema) {
    formattedResponse.data = normalize(formattedResponse.data, schema);
  }
  formattedResponse = formatApiResult(formattedResponse);
  return formattedResponse;
};

axios.interceptors.request.use((axiosRequest) => {
  if (environmentUtil.isDebug()) {
    console.log('Start request', axiosRequest); // eslint-disable-line
  }
  return axiosRequest;
});

axios.interceptors.response.use((axiosResponse) => {
  if (environmentUtil.isDebug()) {
    console.log('Receive response', axiosResponse); // eslint-disable-line
  }
  return axiosResponse;
});

const httpMethods = {
  get: (endpoint, params, config) => axios.get(endpoint, config),
  post: (endpoint, params, config) => axios.post(endpoint, params, config),
  put: (endpoint, params, config) => axios.put(endpoint, params, config),
  delete: (endpoint, params, config) => axios.delete(endpoint, config),
};

const buildPayload = (params, attachments) => {
  const snakelizedParams = snakelize(params);

  if (attachments) {
    const formData = new FormData();
    formData.append('json', JSON.stringify(snakelizedParams));
    Object.entries(attachments).forEach(([key, value]) => formData.append(key, value));
    return formData;
  }
  return snakelizedParams;
};

const ciApi = {

  call: ({
    httpMethod = 'post',
    endpoint,
    queryParams = {},
    params = {},
    attachments = null,
    schema = null,
  }) => {
    const requestConfig = getRequestConfig();
    const snakelizedEndpoint = snakelizeEndpoint(endpoint);
    const stringifiedQueryParams = stringifyQueryParams(queryParams);
    const finalEndpoint = snakelizedEndpoint + stringifiedQueryParams;
    const payload = buildPayload(params, attachments);
    const sendRequest = httpMethods[httpMethod.toLowerCase()];
    const promise = sendRequest(finalEndpoint, payload, requestConfig);
    return promise.then(
      response => formatResponse(response, schema),
      error => Promise.reject(formatResponse(error)),
    );
  },

};

export default ciApi;
