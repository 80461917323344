import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';

// @material-ui/icons
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';

import tasksStyle from '../../assets/jss/material-dashboard-pro-react/components/tasksStyle';

class Tasks extends React.Component {
  render() {
    const { classes, tasks, handleToggle, handleEdit, handleDelete } = this.props;
    return (
      <Table className={classes.table}>
        <TableBody>
          {tasks.filter((task) => !task.done).map(task => (
            <TableRow key={task.id} className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <Checkbox
                  checked={task.done}
                  tabIndex={-1}
                  onClick={handleToggle(task)}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              </TableCell>
              <TableCell className={classes.tableCell}>
                {task.contactDate}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {task.client && task.client.name}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {task.secondaryStage}
              </TableCell>
              <TableCell className={classes.tableActions}>
                <IconButton
                  aria-label="Edit"
                  className={classes.tableActionButton}
                  onClick={handleEdit(task)}
                >
                  <Edit
                    className={
                      `${classes.tableActionButtonIcon} ${classes.edit}`
                    }
                  />
                </IconButton>
                <IconButton
                  aria-label="Close"
                  className={classes.tableActionButton}
                  onClick={handleDelete(task)}
                >
                  <Close
                    className={
                      `${classes.tableActionButtonIcon} ${classes.close}`
                    }
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

Tasks.propTypes = {
  classes: PropTypes.object.isRequired,
  handleToggle: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object),
};

export default withStyles(tasksStyle)(Tasks);
