import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { getAbility } from '../reducers/sessionReducer';

const defaultPublicRoutePath = '/pages/login-page';

const PrivateRoute = ({ component: Component, permission, ability, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (ability.can(permission.action, permission.subject)) {
        return <Component {...props} />;
      }
      return <Redirect to={defaultPublicRoutePath} />;
    }}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  permission: PropTypes.shape({
    action: PropTypes.string,
    subject: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  }).isRequired,
  ability: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  ability: getAbility(state),
});

export default connect(mapStateToProps)(PrivateRoute);
