
import myTaskActions from '../../../actions/myTaskActions';
import taskInputFields from './taskInputFields';
import generateEntityEditor from '../../../hocs/generateEntityEditor';

const MyTaskEditor = generateEntityEditor({
  entitySingularString: 'task',
  entityPluralString: 'tasks',
  entityActions: myTaskActions,
  returnRoutePath: '/acompanhamento-de-tarefas',
  fields: taskInputFields,
});

export default MyTaskEditor;
