const employeeTableColumns = [
  {
    name: 'name',
    title: 'Nome',
    editable: true,
    width: 190,
  },
  {
    name: 'cpf',
    title: 'CPF',
    editable: true,
    width: 100,
  },
  {
    name: 'accessProfile',
    title: 'Perfil de acesso',
    editable: true,
    type: 'select',
    options: ['Gestor', 'Assessor'],
    width: 126,
  },
  {
    name: 'employeeFunctionId',
    title: 'Função',
    editable: true,
    type: 'object-select',
    meta: { valueKey: 'id', labelKey: 'name' },
    options: [
      { id: 1, name: 'Vendedor' },
      { id: 2, name: 'Secretária(o)' },
    ],
    getCellValue: row => (row.employeeFunction || {}).name,
    width: 93,
  },
  {
    name: 'xpCode',
    title: 'Código XP',
    editable: true,
    width: 100,
  },
  {
    name: 'ciCode',
    title: 'Código CI',
    editable: true,
    width: 100,
  },
  {
    name: 'email',
    title: 'E-mail',
    editable: true,
    width: 170,
  },
  {
    name: 'telephone',
    title: 'Telefone',
    editable: true,
    width: 115,
  },
];

export default employeeTableColumns;
