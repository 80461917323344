import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import PagesRouter from '../../routing/PagesRouter';
import style from './style';
import bgImage from '../../assets/img/register.jpeg';

const PagesLayout = ({ classes }) => (
  <div>
    <div className={classes.wrapper}>
      <div className={classes.fullPage}>
        <PagesRouter />
        <div
          className={classes.fullPageBackground}
          style={{ backgroundImage: `url(${bgImage})` }}
        />
      </div>
    </div>
  </div>
);

PagesLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(PagesLayout);
