import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import { Card, CardContent, Typography } from '@material-ui/core';

import simpleCardStyle from "assets/jss/material-dashboard-pro-react/components/simpleCardStyle";

function SimpleCard({ ...props }) {
  const {
    classes,
    title,
    description,
    descriptionColor,
    descriptionStyle
  } = props;
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography component="p" className={classes.cardCategory}>
          {title}
        </Typography>
        <Typography
          component="h2"
          className={
            classes.cardTitle+ " headline " +
            classes[descriptionColor + "SimpleCardColor"] + " " +
            classes[descriptionStyle + "SimpleCardFontWeight"]
          }
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
}

SimpleCard.defaultProps = {
  descriptionStyle: "normal",
};

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.node,
  description: PropTypes.node,
  descriptionColor: PropTypes.oneOf(["orange", "green", "red", "blue", "purple"]),
  descriptionStyle: PropTypes.oneOf(["normal", "bold", "bolder", "lighter"]),
};

export default withStyles(simpleCardStyle)(SimpleCard);
