
import partnerEnterpriseActions from '../../../actions/partnerEnterpriseActions';
import partnerEnterpriseTableColumns from './partnerEnterpriseTableColumns';
import generateEntityTable from '../../../hocs/generateEntityTable';

const PartnerEnterprisesTable = generateEntityTable({
  entityPluralString: 'partnerEnterprises',
  entityActions: partnerEnterpriseActions,
  editorRoutePath: '/partner-enterprise-edition',
  columns: partnerEnterpriseTableColumns,
});

export default PartnerEnterprisesTable;
