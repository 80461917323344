import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';

import { getEntitiesArray } from '../../../reducers/entitiesReducer';
import { successColor, dangerColor } from '../../../assets/jss/material-dashboard-pro-react';
import spreadsheetActions from '../../../actions/spreadsheetActions';
import spreadsheetTableColumns from './spreadsheetTableColumns';
import notificationActions from '../../general/notification/NotificationsManager/actions';
import popupActions from '../../general/popup/PopupsManager/actions';
import cacheActions from '../../../actions/cacheActions';
import DefaultTable from '../../general/conf-table/ConfTable';
import _ from 'lodash';

class SpreadsheetsTable extends Component {

  state = {
    id: `${Math.random()}`,
  };

  operations = [
    {
      title: 'Excluir',
      content: <CloseIcon fontSize="small" nativeColor={dangerColor} />,
      execute: id => this.onRowDelete(id),
    },
    {
      title: 'Reimportar',
      content: <RefreshIcon nativeColor={successColor} />,
      execute: id => this.onRowReimport(id),
    },
  ];

  componentWillMount() {
    this.onMount();
  }

  fetchSpreadsheets = () => this.props.dispatch(spreadsheetActions.fetchAll());

  reimportSpreadsheet = id => this.props.dispatch(spreadsheetActions.reimport(id))

  deleteSpreadsheet = id => this.props.dispatch(spreadsheetActions.delete(id));

  onMount = () => {
    this.props.dispatch(cacheActions.cleanCache());
    this.fetchSpreadsheets()
      .catch(this.onOperationFail);
  };

  onRowReimport = (id) => {
    this.reimportSpreadsheet(id)
      .then(this.onReimportSucceed)
      .catch(this.onOperationFail);
  }

  onRowDelete = (id) => {
    this.deleteSpreadsheet(id)
      .then(this.onDeleteSucceed)
      .catch(this.onOperationFail);
  };

  onOperationFail = apiResponse => this.props.dispatch(popupActions.handleApiResponse(apiResponse));

  onReimportSucceed = () => {
    const { dispatch } = this.props;
    const message = 'A planilha foi reimportada com sucesso.';
    dispatch(notificationActions.showSuccessNotification(message));
  };

  onDeleteSucceed = () => {
    const { dispatch } = this.props;
    const message = 'A planilha foi excluída com sucesso.';
    dispatch(notificationActions.showSuccessNotification(message));
  };

  render() {
    let { entities } = this.props;
    entities = _.sortBy(entities, 'id').reverse()
    const { id } = this.state;
    return (
      <DefaultTable
        id={id}
        columns={spreadsheetTableColumns}
        dataList={entities}
        operations={this.operations}
      />
    );
  }
}

SpreadsheetsTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  entities: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  entities: getEntitiesArray('spreadsheets')(state),
});

export default connect(mapStateToProps)(SpreadsheetsTable);
