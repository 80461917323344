import { store } from '../../../store';
import partnerEnterpriseActions from '../../../actions/partnerEnterpriseActions';
import sortObjectsArrayByProperty from '../../../utils/sortObjectsArrayByProperty';

const spreadsheetTableColumns = [
  {
    name: 'referenceYear',
    title: 'Ano',
    type: 'number',
    width: 64,
  },
  {
    name: 'referenceMonth',
    title: 'Mês',
    type: 'number',
    width: 65,
  },
  {
    name: 'partnerEnterpriseId',
    title: 'Fornecedor',
    type: 'object-select',
    meta: { valueKey: 'id', labelKey: 'name' },
    options: [],
    async: {
      fetch: () => store.dispatch(partnerEnterpriseActions.fetchAll()),
      fetchDelay: 500,
      mapSuccessfulResult: response => sortObjectsArrayByProperty(Object.values(response.data.entities.partnerEnterprises), 'name'),
      mapProps: ({ response, ...others }) => ({ ...others, options: response }),
    },
    getCellValue: row => (row.partnerEnterprise || {}).name,
    width: 136,
  },
  {
    name: 'sheetName',
    title: 'Nome da aba',
    width: 200,
  },
  {
    name: 'status',
    title: 'Status',
    width: 90,
  },
  {
    name: 'errorMessage',
    title: 'Detalhes',
    width: 300,
  },
];

export default spreadsheetTableColumns;
