import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import TitleCard from '../../mui-components/Card/TitleCard';
import SpreadsheetIncomesTable from '../../containers/domain/spreadsheet-income/SpreadsheetIncomesTable';
import { getSearchParam } from '../../reducers/routerSelectors';

const SpreadsheetIncomesListingView = ({ cardTitle }) => (
  <GridContainer>
    <ItemGrid xs={12}>
      <TitleCard
        title={computeCardTitle(cardTitle)}
        titleColor="primary"
        content={<SpreadsheetIncomesTable />}
      />
    </ItemGrid>
  </GridContainer>
);

SpreadsheetIncomesListingView.propTypes = {
  cardTitle: PropTypes.shape({
    month: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    incomeListingType: PropTypes.string.isRequired,
  }).isRequired,
};

const computeCardTitle = ({ month, year, incomeListingType }) => {
  if (month && year && incomeListingType) {
    return `Lista de receitas - ${incomeListingType} - ${month}/${year}`;
  }
  return 'Lista de receitas';
};

const mapStateToProps = state => ({
  cardTitle: {
    month: getSearchParam(state)('month'),
    year: getSearchParam(state)('year'),
    incomeListingType: getSearchParam(state)('incomeListingType'),
  },
});

export default connect(mapStateToProps)(SpreadsheetIncomesListingView);
