import clientActions from '../../../actions/clientActions';
import clientInputFields from './clientInputFields';
import generateEntityEditor from '../../../hocs/generateEntityEditor';

const ClientEditor = generateEntityEditor({
  entitySingularString: 'client',
  entityPluralString: 'clients',
  entityActions: clientActions,
  returnRoutePath: '/clients-listing',
  fields: clientInputFields,
});

export default ClientEditor;
