import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from 'redux-form-material-ui';

const CheckboxesFilter = ({ name, title, options }) => (
  <FormControl>
    <FormLabel>{title}</FormLabel>
    {options.map(checkboxName => (
      <FormControlLabel
        key={checkboxName}
        label={checkboxName}
        control={<Field name={`${name}.${checkboxName}`} component={Checkbox} />}
      />
    ))}
  </FormControl>
);

CheckboxesFilter.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.array,
};

CheckboxesFilter.defaultProps = {
  options: [],
};

export default CheckboxesFilter;
