import React from "react";
import PropTypes from "prop-types";

// material-ui components
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { store } from '../../store';
import taskActions from '../../actions/taskActions';
import { getLoggedUser } from '../../reducers/sessionReducer';

import withStyles from '@material-ui/core/styles/withStyles';
import { Card, CardContent, Typography, Button } from '@material-ui/core';
import ItemGrid from '../../mui-components/Grid/GridItem';
import moment from 'moment';
import kanbanCardStyle from "assets/jss/material-dashboard-pro-react/components/kanbanCardStyle";

function KanbanCard({ ...props }) {
  const {
    classes,
    client,
    task,
    columnName,
    user
  } = props;

  const taskEditionUrl = user.accessProfile === 'Gestor' ? 'task-edition' : 'my-task-edition'
  const newTaskAction = (skipNextStep) => {
    //store.dispatch(push(`/clients-task-creation?client_ids=${props.client.id}&primaryStage=1 - Primeiro contato&secondaryStage=1.1 - Agendar a reunião de apresentação dos Planos Confiança`))
    store.dispatch(taskActions.update(props.task.id, {done: true, skipNextStep: skipNextStep})).then((response) => {
      if(response.data.entities.tasks[props.task.id].nextTaskId == undefined) {
        window.location.reload();
      } else {
        store.dispatch(push(`/${taskEditionUrl}/${response.data.entities.tasks[props.task.id].nextTaskId}`));
      }
    })
  }

  return (
    <Card className={classes.card}>
      <div className={classes.cardContent}>
        <Typography component="h2" className={classes.cardTitle}>{client.name}</Typography>
        <Typography component="h2" className={classes.cardTitle}>{moment(task.contactDate).format('DD/MM/YYYY')}</Typography>
      </div>
      <Typography component="h2" className={classes.cardDescription}>{task.description}</Typography>
      <Button className={classes.cardButtonEdit} color="primary" variant="outlined" onClick={() => store.dispatch(push(`/${taskEditionUrl}/${task.id}`)) }>Editar</Button>
      { !['1.2','2.1.2'].includes(columnName.match(/[0-9]\.*/g).join('')) &&
        <Button className={classes.cardButtonLost} color="primary" variant="contained" onClick={() => newTaskAction(true)}>Perdeu interesse</Button>
      }
      <Button className={classes.cardButtonFinish} color="secondary" variant="contained" onClick={() => newTaskAction(false)}>Continuar</Button>
    </Card>
  );
}

KanbanCard.propTypes = {
  classes: PropTypes.object.isRequired,
  client: PropTypes.node,
  task: PropTypes.node,
  columnName: PropTypes.node
};

const mapStateToProps = state => {
  return {
    user: getLoggedUser(state),
  }
};

export default connect(mapStateToProps)(withStyles(kanbanCardStyle)(KanbanCard));
