import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { store } from '../../../store';
import { getAccessProfile } from '../../../reducers/sessionReducer';

const Dashboard = ({ dispatch }) => {
  dispatch(push('/main-dashboard'));
  return <div />;
};

Dashboard.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

export default connect()(Dashboard);

// import React from 'react';
// import PropTypes from 'prop-types';
// import ChartistGraph from 'react-chartist';
// import { VectorMap } from 'react-jvectormap';
// import withStyles from '@material-ui/core/styles/withStyles';
// import Tooltip from '@material-ui/core/Tooltip';
// import ContentCopy from '@material-ui/icons/ContentCopy';
// import Store from '@material-ui/icons/Store';
// import InfoOutline from '@material-ui/icons/InfoOutline';
// import Warning from '@material-ui/icons/Warning';
// import DateRange from '@material-ui/icons/DateRange';
// import LocalOffer from '@material-ui/icons/LocalOffer';
// import Update from '@material-ui/icons/Update';
// import ArrowUpward from '@material-ui/icons/ArrowUpward';
// import AccessTime from '@material-ui/icons/AccessTime';
// import Accessibility from '@material-ui/icons/Accessibility';
// import Refresh from '@material-ui/icons/Refresh';
// import Edit from '@material-ui/icons/Edit';
// import Place from '@material-ui/icons/Place';
// import ArtTrack from '@material-ui/icons/ArtTrack';
//
// import GridContainer from '../../../mui-components/Grid/GridContainer';
// import ItemGrid from '../../../mui-components/Grid/GridItem';
// import Table from '../../../mui-components/Table/Table';
// import Button from '../../../mui-components/CustomButtons/Button';
// import StatsCard from '../../../mui-components/Cards/StatsCard';
// import ChartCard from '../../../mui-components/Cards/ChartCard';
// import IconCard from '../../../mui-components/Card/CardIcon';
// import ImagePriceCard from '../../../mui-components/Cards/ImagePriceCard';
//
// import {
//   dailySalesChart,
//   emailsSubscriptionChart,
//   completedTasksChart,
// } from '../../../variables/charts';
//
// import style from './style';
//
// import priceImage1 from '../../../assets/img/card-2.jpeg';
// import priceImage2 from '../../../assets/img/card-3.jpeg';
// import priceImage3 from '../../../assets/img/card-1.jpeg';
//
// const usFlag = require('../../../assets/img/flags/US.png');
// const deFlag = require('../../../assets/img/flags/DE.png');
// const auFlag = require('../../../assets/img/flags/AU.png');
// const gbFlag = require('../../../assets/img/flags/GB.png');
// const roFlag = require('../../../assets/img/flags/RO.png');
// const brFlag = require('../../../assets/img/flags/BR.png');
//
// const mapData = {
//   AU: 760,
//   BR: 550,
//   CA: 120,
//   DE: 1300,
//   FR: 540,
//   GB: 690,
//   GE: 200,
//   IN: 200,
//   RO: 600,
//   RU: 300,
//   US: 2920,
// };
//
// const Dashboard = ({ classes }) => (
//   <div>
//     <GridContainer>
//       <ItemGrid xs={12} sm={6} md={6} lg={3}>
//         <StatsCard
//           icon={ContentCopy}
//           titleColor="primary"
//           title="Used Space"
//           description="49/50"
//           small="GB"
//           statIcon={Warning}
//           stattitleColor="danger"
//           statLink={{ text: 'Get More Space...', href: '#pablo' }}
//         />
//       </ItemGrid>
//       <ItemGrid xs={12} sm={6} md={6} lg={3}>
//         <StatsCard
//           icon={Store}
//           titleColor="green"
//           title="Revenue"
//           description="$34,245"
//           statIcon={DateRange}
//           statText="Last 24 Hours"
//         />
//       </ItemGrid>
//       <ItemGrid xs={12} sm={6} md={6} lg={3}>
//         <StatsCard
//           icon={InfoOutline}
//           titleColor="red"
//           title="Fixed Issues"
//           description="75"
//           statIcon={LocalOffer}
//           statText="Tracked from Github"
//         />
//       </ItemGrid>
//       <ItemGrid xs={12} sm={6} md={6} lg={3}>
//         <StatsCard
//           icon={Accessibility}
//           titleColor="blue"
//           title="Followers"
//           description="+245"
//           statIcon={Update}
//           statText="Just Updated"
//         />
//       </ItemGrid>
//     </GridContainer>
//     <GridContainer>
//       <ItemGrid xs={12}>
//         <TitleCard
//           icon={InfoOutline}
//           title="Global Sales by Top Locations"
//           titleColor="green"
//           content={
//             <GridContainer justify="space-between">
//               <ItemGrid xs={12} sm={12} md={5}>
//                 <Table
//                   tableData={[
//                     [
//                       <img src={usFlag} alt="us_flag" />,
//                       'USA',
//                       '2.920',
//                       '53.23%',
//                     ],
//                     [
//                       <img src={deFlag} alt="us_flag" />,
//                       'Germany',
//                       '1.300',
//                       '20.43%',
//                     ],
//                     [
//                       <img src={auFlag} alt="us_flag" />,
//                       'Australia',
//                       '760',
//                       '10.35%',
//                     ],
//                     [
//                       <img src={gbFlag} alt="us_flag" />,
//                       'United Kingdom',
//                       '690',
//                       '7.87%',
//                     ],
//                     [
//                       <img src={roFlag} alt="us_flag" />,
//                       'Romania',
//                       '600',
//                       '5.94%',
//                     ],
//                     [
//                       <img src={brFlag} alt="us_flag" />,
//                       'Brasil',
//                       '550',
//                       '4.34%',
//                     ],
//                   ]}
//                 />
//               </ItemGrid>
//               <ItemGrid xs={12} sm={12} md={6}>
//                 <VectorMap
//                   map="world_mill"
//                   backgroundColor="transparent"
//                   zoomOnScroll={false}
//                   containerStyle={{
//                     width: '100%',
//                     height: '280px',
//                   }}
//                   containerClassName="map"
//                   regionStyle={{
//                     initial: {
//                       fill: '#e4e4e4',
//                       'fill-opacity': 0.9,
//                       stroke: 'none',
//                       'stroke-width': 0,
//                       'stroke-opacity': 0,
//                     },
//                   }}
//                   series={{
//                     regions: [
//                       {
//                         values: mapData,
//                         scale: ['#AAAAAA', '#444444'],
//                         normalizeFunction: 'polynomial',
//                       },
//                     ],
//                   }}
//                 />
//               </ItemGrid>
//             </GridContainer>
//           }
//         />
//       </ItemGrid>
//     </GridContainer>
//     <GridContainer>
//       <ItemGrid xs={12} sm={12} md={4}>
//         <ChartCard
//           chart={
//             <ChartistGraph
//               className="ct-chart-white-colors"
//               data={dailySalesChart.data}
//               type="Line"
//               options={dailySalesChart.options}
//               listener={dailySalesChart.animation}
//             />
//           }
//           underChart={
//             <div>
//               <Tooltip
//                 id="tooltip-top"
//                 title="Refresh"
//                 placement="bottom"
//                 classes={{ tooltip: classes.tooltip }}
//               >
//                 <Button color="infoNoBackground" justIcon>
//                   <Refresh className={classes.underChartIcons} />
//                 </Button>
//               </Tooltip>
//               <Tooltip
//                 id="tooltip-top"
//                 title="Change Date"
//                 placement="bottom"
//                 classes={{ tooltip: classes.tooltip }}
//               >
//                 <Button color="defaultNoBackground" justIcon>
//                   <Edit className={classes.underChartIcons} />
//                 </Button>
//               </Tooltip>
//             </div>
//           }
//           hover
//           chartColor="blue"
//           title="Daily Sales"
//           text={
//             <span>
//               <span className={classes.successText}>
//                 <ArrowUpward className={classes.upArrowCardCategory} /> 55%
//               </span>{' '}
//               increase in today sales.
//             </span>
//           }
//           statIcon={AccessTime}
//           statText="updated 4 minutes ago"
//         />
//       </ItemGrid>
//       <ItemGrid xs={12} sm={12} md={4}>
//         <ChartCard
//           chart={
//             <ChartistGraph
//               className="ct-chart-white-colors"
//               data={emailsSubscriptionChart.data}
//               type="Bar"
//               options={emailsSubscriptionChart.options}
//               responsiveOptions={emailsSubscriptionChart.responsiveOptions}
//               listener={emailsSubscriptionChart.animation}
//             />
//           }
//           underChart={
//             <div>
//               <Tooltip
//                 id="tooltip-top"
//                 title="Refresh"
//                 placement="bottom"
//                 classes={{ tooltip: classes.tooltip }}
//               >
//                 <Button color="infoNoBackground" justIcon>
//                   <Refresh className={classes.underChartIcons} />
//                 </Button>
//               </Tooltip>
//               <Tooltip
//                 id="tooltip-top"
//                 title="Change Date"
//                 placement="bottom"
//                 classes={{ tooltip: classes.tooltip }}
//               >
//                 <Button color="defaultNoBackground" justIcon>
//                   <Edit className={classes.underChartIcons} />
//                 </Button>
//               </Tooltip>
//             </div>
//           }
//           hover
//           chartColor="orange"
//           title="Email Subscriptions"
//           text="Last Campaign Performance"
//           statIcon={AccessTime}
//           statText="campaign sent 2 days ago"
//         />
//       </ItemGrid>
//       <ItemGrid xs={12} sm={12} md={4}>
//         <ChartCard
//           chart={
//             <ChartistGraph
//               className="ct-chart-white-colors"
//               data={completedTasksChart.data}
//               type="Line"
//               options={completedTasksChart.options}
//               listener={completedTasksChart.animation}
//             />
//           }
//           underChart={
//             <div>
//               <Tooltip
//                 id="tooltip-top"
//                 title="Refresh"
//                 placement="bottom"
//                 classes={{ tooltip: classes.tooltip }}
//               >
//                 <Button color="infoNoBackground" justIcon>
//                   <Refresh className={classes.underChartIcons} />
//                 </Button>
//               </Tooltip>
//               <Tooltip
//                 id="tooltip-top"
//                 title="Change Date"
//                 placement="bottom"
//                 classes={{ tooltip: classes.tooltip }}
//               >
//                 <Button color="defaultNoBackground" justIcon>
//                   <Edit className={classes.underChartIcons} />
//                 </Button>
//               </Tooltip>
//             </div>
//           }
//           hover
//           chartColor="red"
//           title="Completed Tasks"
//           text="Last Campaign Performance"
//           statIcon={AccessTime}
//           statText="campaign sent 2 days ago"
//         />
//       </ItemGrid>
//     </GridContainer>
//     <h3>Manage Listings</h3>
//     <br />
//     <GridContainer>
//       <ItemGrid xs={12} sm={12} md={4}>
//         <ImagePriceCard
//           image={priceImage1}
//           title="Cozy 5 Stars Apartment"
//           text="The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to &quot;Naviglio&quot; where you can enjoy the main night life in Barcelona."
//           price="$899/night"
//           statIcon={Place}
//           statText="Barcelona, Spain"
//           hover
//           underImage={
//             <div>
//               <Tooltip
//                 id="tooltip-top"
//                 title="View"
//                 placement="bottom"
//                 classes={{ tooltip: classes.tooltip }}
//               >
//                 <Button color="defaultNoBackground" justIcon>
//                   <ArtTrack className={classes.underChartIcons} />
//                 </Button>
//               </Tooltip>
//               <Tooltip
//                 id="tooltip-top"
//                 title="Edit"
//                 placement="bottom"
//                 classes={{ tooltip: classes.tooltip }}
//               >
//                 <Button color="successNoBackground" justIcon>
//                   <Refresh className={classes.underChartIcons} />
//                 </Button>
//               </Tooltip>
//               <Tooltip
//                 id="tooltip-top"
//                 title="Remove"
//                 placement="bottom"
//                 classes={{ tooltip: classes.tooltip }}
//               >
//                 <Button color="dangerNoBackground" justIcon>
//                   <Edit className={classes.underChartIcons} />
//                 </Button>
//               </Tooltip>
//             </div>
//           }
//         />
//       </ItemGrid>
//       <ItemGrid xs={12} sm={12} md={4}>
//         <ImagePriceCard
//           image={priceImage2}
//           title="Office Studio"
//           text="The place is close to Metro Station and bus stop just 2 min by walk and near to &quot;Naviglio&quot; where you can enjoy the night life in London, UK."
//           price="$1.119/night"
//           statIcon={Place}
//           statText="London, UK"
//           hover
//           underImage={
//             <div>
//               <Tooltip
//                 id="tooltip-top"
//                 title="View"
//                 placement="bottom"
//                 classes={{ tooltip: classes.tooltip }}
//               >
//                 <Button color="defaultNoBackground" justIcon>
//                   <ArtTrack className={classes.underChartIcons} />
//                 </Button>
//               </Tooltip>
//               <Tooltip
//                 id="tooltip-top"
//                 title="Edit"
//                 placement="bottom"
//                 classes={{ tooltip: classes.tooltip }}
//               >
//                 <Button color="successNoBackground" justIcon>
//                   <Refresh className={classes.underChartIcons} />
//                 </Button>
//               </Tooltip>
//               <Tooltip
//                 id="tooltip-top"
//                 title="Remove"
//                 placement="bottom"
//                 classes={{ tooltip: classes.tooltip }}
//               >
//                 <Button color="dangerNoBackground" justIcon>
//                   <Edit className={classes.underChartIcons} />
//                 </Button>
//               </Tooltip>
//             </div>
//           }
//         />
//       </ItemGrid>
//       <ItemGrid xs={12} sm={12} md={4}>
//         <ImagePriceCard
//           image={priceImage3}
//           title="Beautiful Castle"
//           text="The place is close to Metro Station and bus stop just 2 min by walk and near to &quot;Naviglio&quot; where you can enjoy the main night life in Milan."
//           price="$459/night"
//           statIcon={Place}
//           statText="Milan, Italy"
//           hover
//           underImage={
//             <div>
//               <Tooltip
//                 id="tooltip-top"
//                 title="View"
//                 placement="bottom"
//                 classes={{ tooltip: classes.tooltip }}
//               >
//                 <Button color="defaultNoBackground" justIcon>
//                   <ArtTrack className={classes.underChartIcons} />
//                 </Button>
//               </Tooltip>
//               <Tooltip
//                 id="tooltip-top"
//                 title="Edit"
//                 placement="bottom"
//                 classes={{ tooltip: classes.tooltip }}
//               >
//                 <Button color="successNoBackground" justIcon>
//                   <Refresh className={classes.underChartIcons} />
//                 </Button>
//               </Tooltip>
//               <Tooltip
//                 id="tooltip-top"
//                 title="Remove"
//                 placement="bottom"
//                 classes={{ tooltip: classes.tooltip }}
//               >
//                 <Button color="dangerNoBackground" justIcon>
//                   <Edit className={classes.underChartIcons} />
//                 </Button>
//               </Tooltip>
//             </div>
//           }
//         />
//       </ItemGrid>
//     </GridContainer>
//   </div>
// );
//
// Dashboard.propTypes = {
//   classes: PropTypes.object.isRequired,
// };
//
// export default withStyles(style)(Dashboard);
