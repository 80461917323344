import types from '../actions/types';
import abilities from '../abilities';
import createReducer from '../utils/createReducer';

const initialState = {
  token: null,
  isLoggingIn: false,
  user: {
    name: 'Visitante',
    email: 'Visitante',
    accessProfile: 'Visitante',
  },
};

const loginRequestCase = state => ({
  ...state,
  isLoggingIn: true,
});

const loginSuccessCase = (state, action) => ({
  token: action.response.data.token,
  user: {
    id: action.response.data.id,
    name: action.response.data.name,
    email: action.response.data.email,
    accessProfile: action.response.data.accessProfile,
  },
  isLoggingIn: false,
});

const loginFailureCase = state => ({
  ...state,
  isLoggingIn: false,
});

const logoutCase = () => initialState;

const apiCallFailureCase = (state, action) => {
  const { httpStatus } = action.response;
  if (httpStatus === 401) {
    return initialState;
  }
  return state;
};

const meUpdatedCase = state => ({
  ...state,
  /* TODO: atualizar quando meus dados são atualizados
  user: {
    name: action.response.data.entities.employee.name,
    email: action.response.data.email,
    accessProfile: action.response.data.accessProfile,
  },
  */
});

const sessionReducer = createReducer(initialState, {
  [types.LOGIN_REQUEST]: loginRequestCase,
  [types.LOGIN_SUCCESS]: loginSuccessCase,
  [types.LOGIN_FAILURE]: loginFailureCase,
  [types.LOGOUT]: logoutCase,
  [types.API_CALL_FAILURE]: apiCallFailureCase,
  [types.ME_UPDATED]: meUpdatedCase,
});

export const getToken = state => state.session.token;
export const isLoggedIn = state => !!getToken(state);
export const isLoggingIn = state => state.session.isLoggingIn;
export const getLoggedUser = state => state.session.user || {};
export const getLoggedUserName = state => getLoggedUser(state).name;
export const getLoggedUserFirstName = state => getLoggedUserName(state).split(' ')[0];
export const getAccessProfile = state => getLoggedUser(state).accessProfile;
export const getAbility = state => abilities[getAccessProfile(state)] || abilities.Visitante;

export default sessionReducer;
