import { store } from '../../../store';

import internEnterpriseActions from '../../../actions/internEnterpriseActions';
import partnerEnterpriseActions from '../../../actions/partnerEnterpriseActions';
import sortObjectsArrayByProperty from '../../../utils/sortObjectsArrayByProperty';

const spreadsheetInputFields = (onPartnerEnterpriseChange) => {
  return [
    {
      name: 'internEnterpriseId',
      label: 'Empresa',
      type: 'object-select',
      meta: { valueKey: 'id', labelKey: 'name' },
      options: [],
      async: {
        fetch: () => store.dispatch(internEnterpriseActions.fetchAll()),
        fetchDelay: 500,
        mapSuccessfulResult: response => sortObjectsArrayByProperty(Object.values(response.data.entities.internEnterprises), 'name'),
        mapProps: ({ response, ...others }) => ({ ...others, options: response }),
      },
      onChange: onPartnerEnterpriseChange,
      width: 300,
    },
    {
      name: 'partnerEnterpriseId',
      label: 'Fornecedor',
      type: 'object-select',
      meta: { valueKey: 'id', labelKey: 'name' },
      options: [],
      async: {
        fetch: () => store.dispatch(partnerEnterpriseActions.fetchAll()),
        fetchDelay: 500,
        mapSuccessfulResult: response => sortObjectsArrayByProperty(Object.values(response.data.entities.partnerEnterprises), 'name'),
        mapProps: ({ response, ...others }) => ({ ...others, options: response }),
      },
      onChange: onPartnerEnterpriseChange,
      width: 300,
    },
    {
      name: 'headersLine',
      label: 'Linha dos cabeçalhos',
      width: 180,
    },
    {
      name: 'sheetName',
      label: 'Nome da aba',
      width: 180,
    },
    {
      name: 'referenceMonth',
      label: 'Mês de referência',
      type: 'number',
      width: 180,
    },
    {
      name: 'referenceYear',
      label: 'Ano de referência',
      type: 'number',
      width: 180,
    },
    {
      name: 'file',
      label: 'Arquivo',
      type: 'file',
    },
    {
      name: 'headers',
      label: 'Cabeçalhos',
      type: 'group',
      fields: [
        { name: 'clientIdentifier', label: 'Identificador', width: 200 },
        { name: 'partnerGrossProfit', label: 'Receita bruta (forn)', width: 200 },
        { name: 'partnerNetProfit', label: 'Receita líquida (forn)', width: 200 },
        { name: 'companyGrossProfit', label: 'Receita bruta (Confiança)', width: 200 },
        { name: 'companyTransferFee', label: 'Repasse (Confiança)', width: 200 },
        { name: 'product', label: 'Produto', width: 200 },
        { name: 'productCategory', label: 'Categoria do produto', width: 200 },
        { name: 'insurancePremium', label: 'Prêmio de seguro', width: 200 },
      ],
    },
  ];
}


export default spreadsheetInputFields;
