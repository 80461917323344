import { store } from '../../../store';
import employeeActions from '../../../actions/employeeActions';
import { getAccessProfile } from '../../../reducers/sessionReducer';
import sortObjectsArrayByProperty from '../../../utils/sortObjectsArrayByProperty';

const clientSpreadsheetTableColumns = [
  {
    name: 'assessorId',
    title: 'Assessor',
    editable: false,
    notRenderable: getAccessProfile(store.getState()) !== 'Gestor',
    type: 'object-select',
    meta: { valueKey: 'id', labelKey: 'name' },
    options: [],
    async: {
      fetch: () => store.dispatch(employeeActions.fetchAll()),
      fetchDelay: 500,
      mapSuccessfulResult: response => sortObjectsArrayByProperty(Object.values(response.data.entities.employees), 'name'),
      mapProps: ({ response, ...others }) => ({ ...others, options: response }),
    },
    getCellValue: row => (row.assessor || {}).name,
    width: 159,
  },
  {
    name: 'year',
    title: 'Ano',
    type: 'number',
    width: 65,
  },
  {
    name: 'month',
    title: 'Mês',
    type: 'number',
    width: 65,
  },
  {
    name: 'headersLine',
    title: 'Linha dos cabeçalhos',
    width: 160,
  },
  {
    name: 'sheetName',
    title: 'Nome da aba',
    width: 250,
  },
  {
    name: 'status',
    title: 'Status',
    width: 90,
  },
  {
    name: 'errorMessage',
    title: 'Detalhes',
    width: 300,
  },
];

export default clientSpreadsheetTableColumns;
