import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { TextField } from 'redux-form-material-ui';

import dateMask from '../../utils/masks/dateMask';

const DateRangeFilter = ({ name, title }) => (
  <FormControl>
    <FormLabel>{title}</FormLabel>
    <Field name={`${name}.minValue`} label="De" component={TextField} {...dateMask} />
    <Field name={`${name}.maxValue`} label="Até" component={TextField} {...dateMask} />
  </FormControl>
);

DateRangeFilter.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default DateRangeFilter;
