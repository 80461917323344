import { getLoggedUser } from '../../../reducers/sessionReducer';
import _ from 'lodash';

const clientMailInputFields = (formValues, dispatch, state) => {
  let fields = [
    {
      name: 'subject',
      label: 'Assunto da mensagem',
    },
    {
      name: 'domainFrom',
      label: 'Domínio do remetente',
      type: 'select',
      options: [
        'confiancaplanejamento.com',
        'regatainvestimentos.com',
      ],
    },
    {
      name: 'body',
      label: 'Texto do email',
      type: 'textarea',
      rows: 10
    },
    {
      name: 'file1',
      label: 'Anexo 1',
      type: 'file',
    },
    {
      name: 'file2',
      label: 'Anexo 2',
      type: 'file',
    },
    {
      name: 'file3',
      label: 'Anexo 3',
      type: 'file',
    },
    {
      name: 'file4',
      label: 'Anexo 4',
      type: 'file',
    },
  ]

  let permittedChangeDomain = ['julio.oliveira@confiancaplanejamento.com','nathaniel@confiancaplanejamento.com','fillipe.ferrari@confiancaplanejamento.com']
  if(!permittedChangeDomain.includes(getLoggedUser(state).email)) {
    _.remove(fields, (field) => field.name == 'domainFrom')
  }
  return fields;
};

export default clientMailInputFields;
