import React from 'react';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import RegularCard from '../../mui-components/Card/RegularCard';
import MyTasksTable from '../../containers/domain/task/MyTasksTable';

const TasksListingView = () => (
  <GridContainer>
    <ItemGrid xs={12}>
      <RegularCard
        title="Minhas tarefas"
        content={<MyTasksTable />}
      />
    </ItemGrid>
  </GridContainer>
);

export default TasksListingView;
