import myClientActions from '../../../actions/myClientActions';
import clientTableColumns, {
  clientTableSummaries,
  clientTableLeftFixedColumns,
  clientSelectionOperations,
} from './clientTableColumns';
import generateEntityTable from '../../../hocs/generateEntityTable';

const clientEditorRoutePath = '/my-client-edition';

const MyClientsTable = generateEntityTable({
  entityPluralString: 'clients',
  entityActions: myClientActions,
  editorRoutePath: clientEditorRoutePath,
  columns: clientTableColumns,
  leftFixedColumns: clientTableLeftFixedColumns,
  summaries: clientTableSummaries,
  selectionOperations: clientSelectionOperations,
  enableSelection: true,
});

export default MyClientsTable;
