import Fingerprint from '@material-ui/icons/Fingerprint';

import LoginPage from '../../views/pages/LoginPage/index';
import ForgotPasswordPage from '../../views/pages/ForgotPasswordPage/index';
import ChangePasswordPage from '../../views/pages/ChangePasswordPage/index';

const pagesRoutes = [
  {
    path: '/pages/login-page',
    name: 'Login Page',
    short: 'Login',
    mini: 'LP',
    icon: Fingerprint,
    component: LoginPage,
  },
  {
    path: '/pages/esqueci-a-senha',
    name: 'Esqueci a senha',
    short: 'Esqueci a senha',
    mini: 'SN',
    icon: Fingerprint,
    component: ForgotPasswordPage,
  },
  {
    path: '/pages/reset-password',
    name: 'Mudar minha senha',
    short: 'Mudar minha senha',
    mini: 'SN',
    icon: Fingerprint,
    component: ChangePasswordPage,
  },
  {
    redirect: true,
    path: '/pages',
    pathTo: '/pages/login-page',
    name: 'Login Page',
  },
];

export default pagesRoutes;
