import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import InputAdornment from '@material-ui/core/InputAdornment';
import Email from '@material-ui/icons/Email';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/core/Icon';

import Card from '../../../../mui-components/Card/Card';
import CardHeader from '../../../../mui-components/Card/CardHeader';
import CardBody from '../../../../mui-components/Card/CardBody';
import CardFooter from '../../../../mui-components/Card/CardFooter';
import Button from '../../../miscellaneous/Button';
import { NavLink } from 'react-router-dom';
import style from './style';

class ChangePasswordForm extends React.Component {

  state = {
    cardAnimaton: 'cardHidden',
  };

  componentDidMount() {
    setTimeout(() => this.setState({ cardAnimaton: '' }), 700);
  }

  render() {
    const { classes, handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Card login className={classes[this.state.cardAnimaton]}>
          <CardHeader
            className={`${classes.cardHeader} ${classes.textCenter}`}
            color="primary"
          >
            <h4 className={classes.cardTitle}>Alterar minha senha</h4>
          </CardHeader>
          <CardBody>
            {this.renderPasswordInput()}
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            {this.renderButton()}
          </CardFooter>
        </Card>
      </form>
    );
  }

  renderPasswordInput = () => {
    const { classes } = this.props;
    return (
      <div className={classes.fieldWrapper}>
        <Field
          component={TextField}
          type="password"
          name="password"
          label="Senha"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon className={classes.inputAdornmentIcon}>
                  lock_outline
                </Icon>
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  };

  renderButton = () => {
    const { classes } = this.props;
    return (
      <Button
        customClass={classes.button}
        color="primary"
        variant="raised"
        type="submit"
      >
        Alterar
      </Button>
    );
  };
}

ChangePasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm()(withStyles(style)(ChangePasswordForm));
