
import partnerEnterpriseActions from '../../../actions/partnerEnterpriseActions';
import partnerEnterpriseInputFields from './partnerEnterpriseInputFields';
import generateEntityEditor from '../../../hocs/generateEntityEditor';

const PartnerEnterpriseEditor = generateEntityEditor({
  entitySingularString: 'partnerEnterprise',
  entityPluralString: 'partnerEnterprises',
  entityActions: partnerEnterpriseActions,
  returnRoutePath: '/partner-enterprises-listing',
  fields: partnerEnterpriseInputFields,
});

export default PartnerEnterpriseEditor;
