import myTaskActions from '../../../actions/myTaskActions';
import taskTableColumns, {
  taskTableLeftFixedColumns,
  taskTableDataSortingByColumns,
  taskTableSummaries,
  TaskTableCellComponent,
} from './taskTableColumns';
import generateEntityTable from '../../../hocs/generateEntityTable';

const taskEditorRoutePath = '/my-task-edition';

const MyTasksTable = generateEntityTable({
  entityPluralString: 'tasks',
  entityActions: myTaskActions,
  editorRoutePath: taskEditorRoutePath,
  columns: taskTableColumns,
  leftFixedColumns: taskTableLeftFixedColumns,
  dataSortingByColumns: taskTableDataSortingByColumns,
  cellComponent: TaskTableCellComponent,
  summaries: taskTableSummaries,
});

export default MyTasksTable;
