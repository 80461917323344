import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import authActions from '../../../actions/authActions';
import ForgotPasswordForm from '../../../components/domain/user/ForgotPasswordForm/index';
import notificationActions from '../../general/notification/NotificationsManager/actions';
import { store } from '../../../store';
import { push } from 'react-router-redux';

class ForgotPassword extends React.Component {

  submit = ({ email }) => (
    this.getPasswordToken(email).then(response => {
      // console.log(response);
      store.dispatch(notificationActions.showNotification('Email enviado com sucesso', 'success'));
      store.dispatch(push('/pages/login-page'));
    }).catch(this.onForgotPasswordFail)
  );

  getPasswordToken = (email) => {
    const { dispatch } = this.props;
    const action = authActions.getPasswordToken(email);
    return dispatch(action);
  };

  onForgotPasswordFail = () => {
    const { dispatch } = this.props;
    const message = 'Operação falhou. Não foi possível realizar a autenticação para acesso ao sistema.';
    const color = 'danger';
    const place = 'tc';
    dispatch(notificationActions.showNotification(message, color, place));
  };

  render() {
    return (
      <ForgotPasswordForm form="password" onSubmit={this.submit} />
    );
  }
}

ForgotPassword.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ForgotPassword);
