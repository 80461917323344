import mySpreadsheetIncomeActions from '../../../actions/mySpreadsheetIncomeActions';
import spreadsheetIncomeTableColumns, {
  spreadsheetIncomeTableSummaries,
  spreadsheetIncomeTableLeftFixedColumns,
} from './spreadsheetIncomeTableColumns';
import generateEntityTable from '../../../hocs/generateEntityTable';

const spreadsheetIncomeEditorRoutePath = '/my-spreadsheet-income-edition';

const MySpreadsheetIncomesTable = generateEntityTable({
  entityPluralString: 'spreadsheetIncomes',
  entityActions: mySpreadsheetIncomeActions,
  editorRoutePath: spreadsheetIncomeEditorRoutePath,
  columns: spreadsheetIncomeTableColumns,
  leftFixedColumns: spreadsheetIncomeTableLeftFixedColumns,
  summaries: spreadsheetIncomeTableSummaries,
  operations: [],
});

export default MySpreadsheetIncomesTable;
