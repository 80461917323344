const secondaryStageOptions = (primaryStage) => {
  if (primaryStage === '1 - Primeiro contato') {
    return [
      '1.1 - Agendar a reunião de apresentação dos Planos Confiança',
      '1.2 - Solicitar indicação, agradecer e registrar no CRM',
      '1.3 - Outros',
    ];
  }

  if (primaryStage === '2 - Habilitação') {
    return [
      '2.1 - Apresentar os Planos Confiança',
      '2.1.1 - Solicitar pagamento do Plano Confiança escolhido',
      '2.1.1.1 - Coletar as informações do cliente',
      '2.1.1.2 - Agendar a apresentação do Plano de Ação',
      '2.1.2 - Solicitar indicação, agradecer e registrar no CRM',
      '2.2 - Outros',
    ];
  }

  if (primaryStage === '3 - Diagnóstico do Plano de ação') {
    return [
      '3.1 - Elaborar o Plano de Ação',
      '3.2 - Elaborar estudo de investimentos',
      '3.3 - Elaborar estudo de seguros',
      '3.4 - Elaborar estudo de previdência',
      '3.5 - Elaborar estudo diversos',
      '3.6 - Apresentar os estudos e solicitar indicação',
      '3.7 - Outros',
    ];
  }

  if (primaryStage === '4 - Implementação do Plano de Ação') {
    return [
      '4.1 - Abrir conta na corretora ou transferência de assessor',
      '4.1.1 - Implementar estudo de investimentos',
      '4.1.2 - Contratar / portabilidade da previdência privada',
      '4.1.2.1 - Monitorar a implementação da previdência privada',
      '4.2 - Preencher e enviar a proposta do seguro pessoal',
      '4.2.1 - Monitorar a implementação do seguro pessoal',
      '4.3 - Outros',
    ];
  }

  if (primaryStage === '5 - Acompanhamento') {
    return [
      '5.1 - Contato ativo mensal',
      '5.2 - Revisão da Carteira/Plano e solicitar indicação',
      '5.3 - Reunião de revisão do plano ( presencial ) e solicitar indicação',
      '5.4 - Outros',
    ];
  }

  return [];
};

const clientTaskInputFields = (formValues, dispatch) => ([
  {
    name: 'contactMethod',
    label: 'Forma de contato',
    type: 'select',
    options: [
      'E-mail',
      'Telefone',
      'Mensagem de Texto',
      'Presencial',
      'Videoconferência',
    ],
    width: 200,
  },
  {
    name: 'contactDate',
    label: 'Data',
    type: 'date',
    width: 150,
  },
  {
    name: 'startTime',
    label: 'Hora de início',
    type: 'hour',
    width: 150,
  },
  {
    name: 'endTime',
    label: 'Hora fim',
    type: 'hour',
    width: 150,
  },
  {
    name: 'location',
    label: 'Local',
    type: 'select',
    options: ['Sala 1', 'Sala 2', 'Externo'],
    width: 150,
  },
  {
    name: 'description',
    label: 'Descrição',
  },
  {
    name: 'primaryStage',
    label: 'Etapa do funil',
    type: 'select',
    options: [
      '1 - Primeiro contato',
      '2 - Habilitação',
      '3 - Diagnóstico do Plano de ação',
      '4 - Implementação do Plano de Ação',
      '5 - Acompanhamento',
    ],
  },
  {
    name: 'secondaryStage',
    label: 'Subetapa do funil',
    type: 'select',
    options: secondaryStageOptions(formValues.primaryStage),
  },
]);

export default clientTaskInputFields;
