import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import withStyles from '@material-ui/core/styles/withStyles';

import GenericField from './ConfFormGenericField';
import Button from '../miscellaneous/Button';

const style = {
  button: {
    marginTop: '36px',
    marginRight: '18px',
  },
};

class ConfForm extends Component {

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit}>
          {this.renderFields()}
          {this.renderButtons()}
        </form>
      </div>
    );
  }

  renderFields = () => {
    const { fields } = this.props;
    return fields.map((field, index) => (
      <div key={index}>
        <GenericField {...field} />
      </div>
    ));
  };

  renderButtons = () => (
    <div>
      {this.renderSubmitButton()}
      {this.renderPristineButton()}
    </div>
  );

  renderSubmitButton = () => {
    const { classes, submitLabel } = this.props;
    return (
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        type="submit"
      >
        {submitLabel}
      </Button>
    );
  };

  renderPristineButton = () => {
    const { classes, resetLabel, reset } = this.props;
    return (
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={reset}
      >
        {resetLabel}
      </Button>
    );
  };
}

ConfForm.propTypes = {
  classes: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  submitLabel: PropTypes.string,
  resetLabel: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

ConfForm.defaultProps = {
  submitLabel: 'Enviar',
  resetLabel: 'Restaurar',
};

export default reduxForm()(withStyles(style)(ConfForm));
