const internEnterpriseTableColumns = [
  {
    name: 'name',
    title: 'Nome',
    editable: true,
    width: 170,
  },
  {
    name: 'cnpj',
    title: 'CNPJ',
    editable: true,
    width: 133,
  },
  {
    name: 'municipalRegistration',
    title: 'Inscrição municipal',
    editable: true,
    width: 145,
  },
  {
    name: 'taxPercentage',
    title: 'Imposto',
    editable: true,
    type: 'percentage',
    width: 87,
  },
  {
    name: 'street',
    title: 'Rua',
    editable: true,
    width: 127,
  },
  {
    name: 'number',
    title: 'Número',
    editable: true,
    width: 85,
  },
  {
    name: 'complement',
    title: 'Complemento',
    editable: true,
    width: 119,
  },
  {
    name: 'neighborhood',
    title: 'Bairro',
    editable: true,
    width: 90,
  },
  {
    name: 'city',
    title: 'Cidade',
    editable: true,
    width: 96,
  },
  {
    name: 'state',
    title: 'Estado',
    editable: true,
    type: 'select',
    options: ['AC','AL','AM','AP','BA','CE','DF','ES','GO','MA','MG','MS','MT','PA','PB','PE','PI','PR','RJ','RN','RO','RR','RS','SC','SE','SP','TO'],
    width: 81,
  },
  {
    name: 'country',
    title: 'País',
    editable: true,
    type: 'select',
    options: ['Brasil', 'Estados Unidos'],
    width: 84,
  },
  {
    name: 'cep',
    title: 'CEP',
    editable: true,
    width: 85,
  },
];

export default internEnterpriseTableColumns;
