import myClientActions from '../../../actions/myClientActions';
import prospectInputFields from './prospectInputFields';
import generateEntityCreator from '../../../hocs/generateEntityCreator';

const MyProspectCreator = generateEntityCreator({
  entityActions: myClientActions,
  returnRoutePath: 'my-clients-listing',
  fields: prospectInputFields,
});

export default MyProspectCreator;
