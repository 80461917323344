import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import {
  GroupingState,
  IntegratedGrouping,
  SortingState,
  IntegratedSorting,
  SummaryState,
  IntegratedSummary,
  SelectionState,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  TableFixedColumns,
  TableHeaderRow,
  TableGroupRow,
  TableColumnReordering,
  TableColumnResizing,
  TableSummaryRow,
  ColumnChooser,
  TableColumnVisibility,
  Toolbar,
  GroupingPanel,
  DragDropProvider,
} from '@devexpress/dx-react-grid-material-ui';

import FiltersPanel from '../../../components/filters-panel/FiltersPanel';
import SimpleMenu from '../../../components/miscellaneous/SimpleMenu';
import ConfTableRow from './ConfTableRow';
import ConfTableBaseCell from './ConfTableBaseCell';
import ConfTableFilteringHelper from './ConfTableFilteringHelper';
import ConfTableToolbarRoot from './ConfTableToolbarRoot';
import ConfTableSelection from './ConfTableSelection';
import confTableSummaryCalculator, { confTableSummaryMessages } from './confTableSummaryCalculator';
import dateUtils from '../../../utils/dateUtils';

class ConfTable extends Component {

  state = {
    filters: [],
    filteredDataList: [],
    rowsIndexes: [],
  };

  componentWillMount() {
    this.initializeFilters();
    this.updateDataList();
  }

  componentWillReceiveProps() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(this.updateDataList, 1000);
  }

  initializeFilters = () => {
    const { columns } = this.props;
    const filters = [];
    columns.forEach((col) => {
      const { hasFilter = true } = col;
      if (hasFilter) {
        filters.push(col);
      }
    });
    this.setState({ filters });
  };

  updateDataList = () => {
    const filteredDataList = this.getFilteredDataList();
    this.setState({ filteredDataList });
  }

  getFilteredDataList = () => {
    const { dataList, filterValues } = this.props;
    const { filters, rowsIndexes } = this.state;
    const filteringHelper = new ConfTableFilteringHelper(filters, filterValues, rowsIndexes);
    return filteringHelper.filterDataList(dataList);
  };

  formatColumns(columns) {
    const { operations, onFieldUpdate } = this.props;
    const newColumns = [];

    columns.forEach((col) => {
      if (!col.notRenderable) {
        // eslint-disable-next-line no-param-reassign
        col.onFieldUpdate = onFieldUpdate;
        newColumns.push(col);
      }
    });

    const operationsColumn = this.createOperationsColumn(operations);
    if (operationsColumn) {
      newColumns.unshift(operationsColumn);
    }

    return newColumns;
  }

  createOperationsColumn = (operations) => {
    if (operations && operations.length > 0) {
      return {
        name: 'operations',
        type: 'operations',
        title: 'Operações',
        width: 114,
        operations,
      };
    }
    return null;
  };

  getColumnsOrder = columns => columns.map(col => col.name);

  getColumnWidths = columns => (
    columns.map(col => ({
      columnName: col.name,
      width: col.width || 200,
    }))
  );

  getHiddenColumnNames = (columns) => {
    const hiddenColumnNames = [];
    columns.forEach((col) => {
      if (col.visible === false) {
        hiddenColumnNames.push(col.name);
      }
    });
    return hiddenColumnNames;
  };

  getSortingDateColumnExtensions = (columns) => {
    const sortingDateColumnExtensions = [];
    columns.forEach((col) => {
      if (col.type === 'date') {
        sortingDateColumnExtensions.push({
          columnName: col.name,
          compare: dateUtils.compareDates,
        });
      }
    });
    return sortingDateColumnExtensions;
  }

  getFilterInitialValues = (columns) => {
    const filterInitialValues = {};
    columns.forEach((col) => {
      filterInitialValues[col.name] = col.filterInitialValue;
    });
    return filterInitialValues;
  }

  getSelectedRows = (list, rowsIndexes) => {
    return rowsIndexes.map(i => list[i]);
  }

  getRowsIndexes = (dataList, selectedRows) => {
    return selectedRows.map(item => dataList.findIndex(f => f === item) );
  }

  getSelectedRowsIndexes = () => {
    const { filteredDataList, rowsIndexes } = this.state;
    const { dataList } = this.props;
    return rowsIndexes.map(index => filteredDataList.findIndex(f => f === dataList[index]) )
  }

  updateSelectedRowsIndexes = (selectedRowsIndexes) => {
    const { onChangeColumnSelection, maxSelections, dataList } = this.props;
    if (selectedRowsIndexes.length > maxSelections) return;
    const { filteredDataList } = this.state;
    const selectedRows = this.getSelectedRows(filteredDataList, selectedRowsIndexes);
    const rowsIndexes = this.getRowsIndexes(dataList, selectedRows)

    this.setState({ rowsIndexes });
    onChangeColumnSelection(selectedRows);
  }

  prepareSelectionOperations = () => {
    const { selectionOperations, dataList } = this.props;
    const { rowsIndexes } = this.state;
    const selectedRows = this.getSelectedRows(dataList, rowsIndexes);

    if (!selectionOperations) return null;

    return selectionOperations.reduce((result, operation) => {
      if (!operation.renderCondition
        || operation.renderCondition(selectedRows)) {
        result.push({
          label: operation.label,
          action: () => (operation.action(selectedRows)),
        });
      }
      return result;
    }, []);
  }

  render() {
    const {
      id,
      columns,
      leftFixedColumns,
      rightFixedColumns,
      summaries,
      dataSortingByColumns,
      rowComponent,
      cellComponent,
      enableSelection,
      height,
      enableFilters,
    } = this.props;
    const { filters, filteredDataList, rowsIndexes } = this.state;
    const formattedColumns = this.formatColumns(columns);
    const defaultColumnsOrder = this.getColumnsOrder(formattedColumns);
    const defaultColumnWidths = this.getColumnWidths(formattedColumns);
    const defaultHiddenColumnNames = this.getHiddenColumnNames(formattedColumns);
    const integratedSortingColumnExtensions = this.getSortingDateColumnExtensions(formattedColumns);
    const filterInitialValues = this.getFilterInitialValues(formattedColumns);
    const selectionOperations = this.prepareSelectionOperations();
    return (
      <div>
        {enableFilters
          && <FiltersPanel form={`${id}FiltersPanel`} filters={filters} initialValues={filterInitialValues} />
        }
        {selectionOperations && <SimpleMenu name="Operações" items={selectionOperations} />}
        <Grid
          rows={filteredDataList}
          columns={formattedColumns}
        >
          <DragDropProvider />
          <SortingState defaultSorting={dataSortingByColumns} />
          <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
          <GroupingState defaultGrouping={[]} />
          <SummaryState totalItems={summaries} groupItems={summaries} />
          <SelectionState selection={this.getSelectedRowsIndexes()} onSelectionChange={this.updateSelectedRowsIndexes} />
          <IntegratedGrouping />
          <IntegratedSummary calculator={confTableSummaryCalculator} />
          <IntegratedSelection />
          <VirtualTable
            rowComponent={rowComponent}
            cellComponent={cellComponent}
            height={height}
          />
          <TableColumnReordering defaultOrder={defaultColumnsOrder} />
          <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
          <TableHeaderRow showSortingControls rowComponent={ConfTableRow} />
          {enableSelection && <ConfTableSelection showSelectAll />}
          <TableGroupRow />
          <TableSummaryRow messages={confTableSummaryMessages} />
          <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
          <Toolbar rootComponent={ConfTableToolbarRoot} />
          <GroupingPanel />
          <ColumnChooser />
          <TableFixedColumns
            leftColumns={leftFixedColumns}
            rightColumns={rightFixedColumns}
          />
        </Grid>
      </div>
    );
  }
}

ConfTable.propTypes = {
  id: PropTypes.string.isRequired,
  filterValues: PropTypes.object,
  columns: PropTypes.array.isRequired,
  leftFixedColumns: PropTypes.array,
  rightFixedColumns: PropTypes.array,
  summaries: PropTypes.array,
  dataSortingByColumns: PropTypes.array,
  rowComponent: PropTypes.any,
  cellComponent: PropTypes.any,
  dataList: PropTypes.array.isRequired,
  operations: PropTypes.array,
  selectionOperations: PropTypes.array,
  onFieldUpdate: PropTypes.func,
  enableSelection: PropTypes.bool,
  onChangeColumnSelection: PropTypes.func,
  maxSelections: PropTypes.number,
  height: PropTypes.number,
  enableFilters: PropTypes.bool,
};

ConfTable.defaultProps = {
  summaries: [],
  leftFixedColumns: [],
  rightFixedColumns: [],
  dataSortingByColumns: [],
  selectionOperations: null,
  rowComponent: ConfTableRow,
  cellComponent: ConfTableBaseCell,
  operations: [],
  filterValues: {},
  onFieldUpdate: () => {},
  enableSelection: false,
  onChangeColumnSelection: () => {},
  maxSelections: Number.MAX_SAFE_INTEGER,
  height: window.innerHeight - 210,
  enableFilters: true,
};

const mapStateToProps = (state, props) => ({
  filterValues: getFormValues(`${props.id}FiltersPanel`)(state) || {},
});

export default connect(mapStateToProps)(ConfTable);
