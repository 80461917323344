import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import spreadsheetActions from '../../../actions/spreadsheetActions';
import spreadsheetInputFields from './spreadsheetInputFields';
import generateEntityCreator from '../../../hocs/generateEntityCreator';

class SpreadsheetCreator extends Component {

  state = {
    renderForm: true,
    initialValues: {}
  };

  partnerEnterpriseChangeHandler = async (event, newValue, previousValue, name) => {
    let initialValues = this.state.initialValues;
    if(name === 'partnerEnterpriseId') {
      const result = await this.props.dispatch(spreadsheetActions.defaultHeaders(newValue));
      initialValues = {...this.state.initialValues, ...result.data.result};
    }
    initialValues[name] = newValue

    this.setState({
      renderForm: false,
      initialValues: initialValues
    }, () => {
      this.updateDefaultForm();
      this.setState({renderForm: true})
    })
  }

  updateDefaultForm = () => {
    const { initialValues } = this.state;
    this.DefaultFormWithProps = generateEntityCreator({
      entityActions: spreadsheetActions,
      returnRoutePath: 'spreadsheets-listing',
      fields: spreadsheetInputFields((a,b,c,d) => this.partnerEnterpriseChangeHandler(a,b,c,d)),
      submitLabel: 'Importar',
      initialValues: initialValues
    });
  }

  componentWillMount() {
    this.updateDefaultForm()
  }

  render() {
    const { DefaultFormWithProps } = this;
    const { renderForm } = this.state;
    return (renderForm ? <DefaultFormWithProps /> : (<></>));
  }
}

SpreadsheetCreator.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(SpreadsheetCreator);
