import clientActions from '../../../actions/clientActions';
import clientInputFields from './clientInputFields';
import generateEntityCreator from '../../../hocs/generateEntityCreator';

const ClientCreator = generateEntityCreator({
  entityActions: clientActions,
  returnRoutePath: '/acompanhamento-de-tarefas',
  fields: clientInputFields,
});

export default ClientCreator;
