import QueryString from 'query-string';

import generateBasicEntityActions from './generateBasicEntityActions';
import * as schemas from '../schemas';

const mySpreadsheetIncomeActions = generateBasicEntityActions('spreadsheetIncomes', 'spreadsheetIncome', 'me/spreadsheetIncomes');

mySpreadsheetIncomeActions.fetchAll = () => (dispatch, getState) => {
  const { search } = getState().router.location;
  const queryParams = QueryString.parse(search);
  return dispatch({
    API_CALL: {
      httpMethod: 'GET',
      endpoint: 'me/spreadsheetIncomes',
      queryParams,
      schema: { spreadsheetIncomes: [schemas.spreadsheetIncome] },
    },
  });
};

export default mySpreadsheetIncomeActions;
