import React from 'react';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import RegularCard from '../../mui-components/Card/RegularCard';
import ClientsTable from '../../containers/domain/client/ClientsTable';

const ClientsListingView = () => (
  <GridContainer>
    <ItemGrid xs={12}>
      <RegularCard
        title="Lista de clientes"
        content={<ClientsTable />}
      />
    </ItemGrid>
  </GridContainer>
);

export default ClientsListingView;
