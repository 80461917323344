import createReducer from '../../../../utils/createReducer';
import types from '../../../../actions/types';

const initialState = {
  requestsInProgressCounter: 0,
};

const caseIncrementCounter = state => ({
  ...state,
  requestsInProgressCounter: state.requestsInProgressCounter + 1,
});

const caseDecrementCounter = state => ({
  ...state,
  requestsInProgressCounter: state.requestsInProgressCounter - 1,
});

const reducer = createReducer(initialState, {
  [types.API_CALL_REQUEST]: caseIncrementCounter,
  [types.API_CALL_SUCCESS]: caseDecrementCounter,
  [types.API_CALL_FAILURE]: caseDecrementCounter,
});

const getLocalState = state => state.ducks.ApiRequestLoadingManager;
export const getRequestsInProgressCounter = state => getLocalState(state).requestsInProgressCounter;
export const isLoading = state => getRequestsInProgressCounter(state) > 0;

export default reducer;
