
// accepts dd/mm/yyyy and yyyy-mm-dd
const convertStringToDate = (dateString) => {
  if (!dateString) {
    const minimumDate = new Date(-8640000000000000);
    return minimumDate;
  }
  if (dateString.includes('/')) {
    const dateParts = dateString.split('/');
    return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
  }
  return new Date(dateString);
};

const compareDates = (dateString1, dateString2) => {
  const date1 = convertStringToDate(dateString1);
  const date2 = convertStringToDate(dateString2);
  if (date1 === date2) return 0;
  return date1 < date2 ? -1 : 1;
};

export default {
  convertStringToDate,
  compareDates,
};
