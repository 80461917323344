const types = {

  API_CALL_REQUEST: 'API_CALL_REQUEST',
  API_CALL_SUCCESS: 'API_CALL_SUCCESS',
  API_CALL_FAILURE: 'API_CALL_FAILURE',

  ENTITY_CLEAN_CACHE: 'ENTITY_CLEAN_CACHE',

  ME_UPDATED: 'ME_UPDATED',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',

  NOTIFICATION_SHOW: 'NOTIFICATION_SHOW',
  NOTIFICATION_CLOSE: 'NOTIFICATION_CLOSE',

  POPUP_SHOW_ERRORS: 'POPUP_SHOW_ERRORS',
  POPUP_CLOSE: 'POPUP_CLOSE',
};

export default types;
