const contractTypeInputFields = [
  {
    name: 'name',
    label: 'Nome',
  },
  {
    name: 'registrationFee',
    label: 'Taxa de registro',
    type: 'percentage',
    width: 150,
  },
  {
    name: 'value',
    label: 'Valor',
    type: 'brCurrency',
    width: 150,
  },
  {
    name: 'recurrence',
    label: 'Recorrência',
    type: 'select',
    options: ['Anual', 'Mensal', 'Pontual'],
    width: 150,
  },
  {
    name: 'continued',
    title: 'Continuado',
    editable: true,
    type: 'checkbox',
    width: 103,
  },
];

export default contractTypeInputFields;
