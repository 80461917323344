/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import HttpsRedirect from 'react-https-redirect';

import { store, history } from './store';
import theme from './theme';
import environment from './utils/environment';
import RootRouter from './routing/RootRouter';
import NotificationsManager from './containers/general/notification/NotificationsManager';
import PopupsManager from './containers/general/popup/PopupsManager';
import ApiRequestLoadingManager from './containers/general/loading/ApiRequestLoadingManager';

import DevTools from './containers/general/debug/DevTools';

import './assets/scss/material-dashboard-pro-react.css';
import './assets/scss/custom-styles.css';

const renderDevToolsIfIsDevMode = () => (environment.isDebug() ? <DevTools /> : null);

ReactDOM.render(
  <HttpsRedirect>
    <Provider store={store}>
      <div>
        <MuiThemeProvider theme={theme}>
          <RootRouter history={history} />
          <NotificationsManager />
          <PopupsManager />
          <ApiRequestLoadingManager />
        </MuiThemeProvider>
        {renderDevToolsIfIsDevMode()}
      </div>
    </Provider>
  </HttpsRedirect>,
  document.getElementById('root'),
);
