import React, { Component } from "react";
import { connect } from "react-redux";
import PermIdentity from "@material-ui/icons/PermIdentity";

import GridContainer from "../../mui-components/Grid/GridContainer";
import ItemGrid from "../../mui-components/Grid/GridItem";
import TitleCard from "../../mui-components/Card/TitleCard";
import ClientSpreadsheetCreator from "../../containers/domain/client-spreadsheet/ClientSpreadsheetCreator";
import { getLoggedUser } from "../../reducers/sessionReducer";

class ClientSpreadsheetCreationView extends Component {
  constructor(props) {
    super(props);
    if (props.user.accessProfile === "Assessor") {
      const currDate = new Date();
      this.state = {
        initialValues: {
          assessorId: props.user.id,
          month: currDate.getMonth(),
          year: currDate.getFullYear(),
          headersLine: 1,
          sheetName: "Planilha1",
          headers: {
            name: "Nome do Cliente",
            email: "Email",
            cpf: "CPF",
            xpCode: "Código XP",
            mongeralCode: "Código Mongeral",
            telephone: "Telefone",
            investmentProfile: "Perfil XP",
            internInvestmentProfile: "Perfil Confiança",
            income: "Renda Mensal",
            incomeYear: "Renda anual",
            monthlyExpenses: "Gastos Mensais",
            net: "NET XP",
            netBtg: "NET BTG Assessoria",
            netBtgConsultancy: "NET BTG Consultoria",
            netBb: "NET BB",
            netSantander: "NET Santander",
            netItau: "NET Itaú",
            netBradesco: "NET Bradesco",
            netCaixa: "NET Caixa",
            netAvenue: "NET Avenue",
            netOutros: "NET Outros",
            responsibleBtg: "Responsável BTG",
            civilStatus: "Estado Civil",
            btgProfile: "Perfil BTG",
            btgBankAccount: "Conta corrente BTG",
            fundsBtg: "Fundos BTG",
            fixedIncomeBtg: "Renda fixa BTG",
            variableIncomeBtg: "Renda variável BTG",
            socialSecurityBtg: "Previdência BTG",
            derivativesBtg: "Derivativos BTG",
            plDeclaredBtg: "PL Declarado BTG",
            savingCapacity: "Capacidade de Poupança",
            profession: "Profissão",
            professionRole: "Cargo que exerce",
            accreditedInvestor: "É investidor Qualificador",
            city: "Cidade",
            birthday: "Data de Nascimento",
            ciCode: "Código CI",
            referral: "Indicação",
            description: "Informações importantes",
          },
        },
      };
    } else {
      this.state = { initialValues: null };
    }
  }

  render() {
    const { initialValues } = this.state;
    return (
      <GridContainer>
        <ItemGrid xs={12} sm={12} md={9} lg={7}>
          <TitleCard icon={PermIdentity} titleColor="primary" title="Importação de planilha de clientes" content={<ClientSpreadsheetCreator initialValues={initialValues} />} />
        </ItemGrid>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: getLoggedUser(state),
  };
};

export default connect(mapStateToProps)(ClientSpreadsheetCreationView);
