import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import GenericField from './ConfFormGenericField';

const style = {
  groupWrapper: {
    margin: '30px 0',
  },
  boxedGroup: {
    padding: '8px 16px',
    backgroundColor: 'rgba(200, 200, 200, 0.125)',
    borderRadius: '4px',
    border: '1px solid rgba(200, 200, 200, 0.25)',
  },
  horizontalGroup: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  horizontalGroupField: {
    marginRight: '20px',
  },
};

const ConfFormFieldGroup = ({ name, label, fields, align, classes }) => {
  let alignGroupClass = classes.verticalGroup;
  let fieldClass = classes.verticalGroupField;
  if (align === 'horizontal') {
    alignGroupClass = classes.horizontalGroup;
    fieldClass = classes.horizontalGroupField;
  }
  const groupClass = label ? cx(alignGroupClass, classes.boxedGroup) : alignGroupClass;

  return (
    <div className={classes.groupWrapper}>
      {label && <h5>{label}</h5>}
      <div className={groupClass}>
        {fields.map((field, index) => (
          <div key={index} className={fieldClass}>
            <GenericField
              {...field}
              name={`${name}.${field.name}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

ConfFormFieldGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  fields: PropTypes.array.isRequired,
  align: PropTypes.oneOf(['horizontal', 'vertical']),
};

ConfFormFieldGroup.defaultProps = {
  align: 'horizontal',
  label: '',
};

export default withStyles(style)(ConfFormFieldGroup);
