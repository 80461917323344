import _ from 'lodash';

const camelize = (collection) => {
  let parent;

  if (_.isPlainObject(collection)) {
    parent = {};
  } else if (_.isArray(collection)) {
    parent = [];
  } else {
    return collection;
  }

  _.forEach(
    collection,
    (value, key) => {
      parent[_.camelCase(key)] = camelize(value);
    },
  );

  return parent;
};

export default camelize;
