import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldArray as ReduxFormFieldArray } from 'redux-form';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import withStyles from '@material-ui/core/styles/withStyles';

import GenericField from './ConfFormGenericField';
import Button from '../miscellaneous/Button';

const style = {
  fieldArrayWrapper: {
    margin: '30px 0',
  },
  items: {
    padding: '8px 16px',
    backgroundColor: 'rgba(200, 200, 200, 0.125)',
    borderRadius: '4px',
    border: '1px solid rgba(200, 200, 200, 0.25)',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    margin: '7px 0',
    borderRadius: '4px',
  },
  temField: {
    flex: 1,
  },
  itemDeleteButton: {
    marginLeft: '12px',
    padding: 0,
    height: '36px',
    width: '36px',
    minWidth: '36px',
  },
  addIcon: {
    position: 'relative',
    left: '-6px',
  },
};

class ConfFormFieldArray extends Component {

  render = () => {
    const { name, label, itemField, classes } = this.props;
    return (
      <div className={classes.fieldArrayWrapper}>
        <h5>{label}</h5>
        <ReduxFormFieldArray
          name={name}
          props={{ fieldShape: itemField }}
          component={this.renderItems}
        />
      </div>
    );
  };

  renderItems = ({ fields, fieldShape }) => {
    const { classes } = this.props;
    if (fields.length === 0) {
      fields.push(); // TODO: colocar em outro lugar... tá dando warning com razão
    }
    return (
      <div className={classes.items}>
        {fields.map((field, index) => (
          this.renderItem({
            field,
            index,
            fieldShape,
            onRemove: () => fields.remove(index),
          })
        ))}
        <Button size="sm" color="success" onClick={() => fields.push()}>
          <AddIcon className={classes.addIcon} />
          Novo item
        </Button>
      </div>
    );
  };

  renderItem = ({ field, index, fieldShape, onRemove }) => {
    const { classes } = this.props;
    return (
      <div key={index} className={classes.item}>
        <div className={classes.itemField}>
          <GenericField
            {...fieldShape}
            name={field}
            label={`${fieldShape.label} #${index + 1}`}
          />
        </div>
        <Button
          className={classes.itemDeleteButton}
          color="danger"
          justIcon
          round
          onClick={onRemove}
        >
          <DeleteIcon />
        </Button>
      </div>
    );
  };

}

ConfFormFieldArray.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  label: PropTypes.string,
  itemField: PropTypes.object,
};

ConfFormFieldArray.defaultProps = {
  label: '',
  itemField: {},
};

export default withStyles(style)(ConfFormFieldArray);
