import generateBasicEntityActions from './generateBasicEntityActions';
import * as schemas from '../schemas';

const basicEntityActions = generateBasicEntityActions('clients');
const clientActions = {
  merge: (primaryId, secondaryId) => ({
    API_CALL: {
      httpMethod: 'PUT',
      endpoint: 'clients/merge',
      params: {
        id_primary: primaryId,
        id_secondary: secondaryId,
      },
    },
  }),
  fetchAllSimple: () => ({
    API_CALL: {
      httpMethod: 'GET',
      endpoint: 'simpleClients',
      schema: { clients: [schemas.client] },
    },
  }),
  destroyAll: (params) => ({
    API_CALL: {
      httpMethod: 'PUT',
      endpoint: 'clients/destroy_all',
      params: params,
    },
  }),

  ...basicEntityActions,
};

export default clientActions;
