import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormControl from '@material-ui/core/FormControl';
import { Select } from 'redux-form-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';

const style = {
  selectWrapper: {
    minWidth: '180px',
  },
};

const ObjectSelectFilter = ({ name, title, options, meta, classes }) => (
  <FormControl className={classes.selectWrapper}>
    <InputLabel htmlFor={name}>{title}</InputLabel>
    <Field
      name={name}
      placeholder={title}
      component={Select}
    >
      <MenuItem key={-1} value=""><em>Nenhum</em></MenuItem>
      {options && options.map && options.map(option => (
        <MenuItem
          key={option[meta.valueKey]}
          value={option[meta.valueKey]}
        >
          {option[meta.labelKey]}
        </MenuItem>
      ))}
    </Field>
  </FormControl>
);

ObjectSelectFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.array,
  meta: PropTypes.shape({
    valueKey: PropTypes.string.isRequired,
    labelKey: PropTypes.string.isRequired,
  }).isRequired,
};

ObjectSelectFilter.defaultProps = {
  options: [],
};

export default withStyles(style)(ObjectSelectFilter);
