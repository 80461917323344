import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextField as MuiTextField } from 'redux-form-material-ui';
import brCurrencyMask from '../../utils/masks/brCurrencyMask';

const ConfFormBrCurrencyField = ({ name, label, disabled }) => (
  <Field
    disabled={disabled}
    name={name}
    label={label}
    fullWidth
    component={MuiTextField}
    {...brCurrencyMask}
  />
);

ConfFormBrCurrencyField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ConfFormBrCurrencyField.defaultProps = {
  disabled: false,
};

export default ConfFormBrCurrencyField;
