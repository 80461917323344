import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: '#eec878',
      main: '#cca656',
      dark: '#aa8434',
      contrastText: '#fff',
    },
  },
});

export default theme;
