import React from 'react';
import types from '../../../../actions/types';

const openNotification = (message, color, place) => ({
  type: types.NOTIFICATION_SHOW,
  message,
  color,
  place,
});

const closeNotification = () => ({
  type: types.NOTIFICATION_CLOSE,
});

let timeoutRef;
const showNotification = (message, color, place) => (dispatch) => {
  dispatch(openNotification(message, color, place));

  clearTimeout(timeoutRef);
  timeoutRef = setTimeout(() => dispatch(closeNotification()), 7500);
};

const showSuccessNotification = message => showNotification(message, 'success');

const handleApiError = response => (dispatch) => {
  const { data, httpStatus } = response;
  const { errors } = data;
  let formattedErrors;

  if (httpStatus === 0) {
    formattedErrors = [{ detail: 'O servidor não está respondendo.' }];
  } else if (httpStatus >= 500) {
    formattedErrors = [{ detail: `Ocorreu um erro inesperado (${httpStatus})` }];
  } else if (errors && errors.length !== 0) {
    formattedErrors = errors.map((e, i) => <p key={i}>{e.detail}</p>);
  }
  dispatch(showNotification(formattedErrors, 'danger'));
};

export default {
  closeNotification,
  showNotification,
  showSuccessNotification,
  handleApiError,
};
