import generateBasicEntityActions from './generateBasicEntityActions';

const basicEntityActions = generateBasicEntityActions('tasks', 'task');
const taskActions = {
  createAll: (params) => {
    let client_ids = params.client_ids
    delete params.client_ids
    let newParams = { client_ids: client_ids, task: params}

    return {
      API_CALL: {
        httpMethod: 'POST',
        endpoint: 'tasks/create_all',
        params: newParams,
      },
    }
  },

  deleteWithoutCallback: id => ({
    API_CALL: {
      httpMethod: 'DELETE',
      endpoint: `tasks/${id}`,
    },
  }),

  ...basicEntityActions,
};

export default taskActions;
