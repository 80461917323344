import React, { Component } from 'react';
import { connect } from 'react-redux';

import clientActions from '../../../actions/clientActions';
import clientMailInputFields from './clientMailInputFields';
import generateEntityCreator from '../../../hocs/generateEntityCreator';
import { getLoggedUser } from '../../../reducers/sessionReducer';

class MultipleMailForm extends Component {
  componentWillMount() {
    let { initialValues, user  } = this.props;
    initialValues.domainFrom = 'confiancaplanejamento.com'
    const returnRoutePath = user.accessProfile === 'Gestor' ? 'clients-listing' : 'my-clients-listing'

    clientActions.create = (params) => {
      const { client_ids, file1, file2, file3, file4, ...clientParams } = params;
      return {
        API_CALL: {
          httpMethod: 'POST',
          endpoint: 'clients/send_mail',
          params: { client_ids: client_ids, client: clientParams },
          attachments: { file1: file1, file2: file2, file3: file3, file4: file4 },
        },
      };
    };

    this.DefaultFormWithProps = generateEntityCreator({
      entityActions: clientActions,
      returnRoutePath: returnRoutePath,
      fields: clientMailInputFields,
      submitLabel: 'Enviar',
      initialValues: initialValues
    });
  }

  render() {
    const { DefaultFormWithProps } = this;
    return (
      <>
        <p>Marcações disponíveis:</p>
        <ul>
          <li>Nome do cliente:<code>[nome]</code></li>
        </ul>
        <DefaultFormWithProps />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: getLoggedUser(state),
  }
};

export default connect(mapStateToProps)(MultipleMailForm);
