import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getEntitiesArray } from '../../../reducers/entitiesReducer';
import spreadsheetIncomeActions from '../../../actions/spreadsheetIncomeActions';
import cacheActions from '../../../actions/cacheActions';
import notificationActions from '../../general/notification/NotificationsManager/actions';
import DefaultTable from '../../general/conf-table/ConfTable';
import columns from './notIdentifiedSpreadsheetIncomesTableColumns';

import { confirmAlert } from 'react-confirm-alert';
import { dangerColor } from '../../../assets/jss/material-dashboard-pro-react';
import CloseIcon from '@material-ui/icons/Close';

class NotIdentifiedSpreadsheetIncomesTable extends Component {

  state = {
    id: `${Math.random()}`,
    loading: false, // tirar
  };

  componentWillMount() {
    this.onMount();
  }

  onMount = () => {
    this.props.dispatch(cacheActions.cleanCache());
    this.fetchEntities()
      .catch(this.onFetchFail);
  };

  onFetchFail = apiResponse => this.props.dispatch(notificationActions.handleApiError(apiResponse));

  fetchEntities = () => this.props.dispatch(spreadsheetIncomeActions.fetchNotIdentifiedSpreadsheetIncomes());

  confirmResolve = (id) => {
    confirmAlert({
      title: 'Confirmação de resolução',
      message: 'Deseja mesmo marcar como resolvido?',
      buttons: [{
        label: 'Sim',
        onClick: () => this.onRowResolve(id)
      },
      {
        label: 'Não',
        onClick: () => {}
      }]
    });
  }

  resolveEntity = id => this.props.dispatch(spreadsheetIncomeActions.resolve(id));

  onRowResolve = (id) => {
    this.resolveEntity(id)
      .then(this.onMount)
      .catch(this.onDeleteFail);
  };

  render() {
    const { entities } = this.props;
    const { id, loading } = this.state;
    const operations = [
      {
        title: 'Confirmar',
        content: <CloseIcon style={{fontSize: "18px"}} nativeColor={dangerColor} />,
        execute: id => this.confirmResolve(id),
      },
    ]

    return (
      <DefaultTable
        id={id}
        columns={columns}
        dataList={entities}
        operations={operations}
        loading={loading}
      />
    );
  }
}

NotIdentifiedSpreadsheetIncomesTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  entities: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  entities: getEntitiesArray('spreadsheetIncomes')(state),
});

export default connect(mapStateToProps)(NotIdentifiedSpreadsheetIncomesTable);
