import Pages from '../../layouts/PagesLayout/index';
import Dashboard from '../../layouts/DashboardLayout/index';

const rootRoutes = [
  {
    path: '/pages',
    name: 'Pages',
    component: Pages,
  },
  {
    authRequired: true,
    path: '/',
    name: 'Home',
    component: Dashboard,
  },
];

export default rootRoutes;
