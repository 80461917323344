import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import GridContainer from '../../../mui-components/Grid/GridContainer';
import ItemGrid from '../../../mui-components/Grid/GridItem';
import ChangePassword from '../../../containers/domain/user/ChangePassword';
import style from './style';

const ChangePasswordPage = ({ classes }) => (
  <div className={classes.content}>
    <div className={classes.container}>
      <GridContainer justify="center">
        <ItemGrid xs={12} sm={6} md={4}>
          <ChangePassword />
        </ItemGrid>
      </GridContainer>
    </div>
  </div>
);

ChangePasswordPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(ChangePasswordPage);
