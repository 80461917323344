import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextField, Checkbox, Select } from 'redux-form-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';

import dottedNumberMask from '../../../utils/masks/dottedNumberMask';
import brCurrencyMask from '../../../utils/masks/brCurrencyMask';
import percentageMask from '../../../utils/masks/percentageMask';
import dateMask from '../../../utils/masks/dateMask';

const style = {
  checkboxWrapper: {
    marginLeft: 0,
  },
  checkboxRoot: {
    padding: 0,
    width: 'auto',
    height: 'auto',
  },
};

class ConfTableCellEditorField extends React.Component {

  render = () => {
    const { type } = this.props;
    switch (type) {
      case 'text': return this.renderTextField();
      case 'number': return this.renderNumberField();
      case 'dottedNumber': return this.renderDottedNumberField();
      case 'brCurrency': return this.renderBrCurrencyField();
      case 'percentage': return this.renderPercentageField();
      case 'date': return this.renderDateField();
      case 'checkbox': return this.renderCheckboxField();
      case 'select': return this.renderSelectField();
      case 'object-select': return this.renderObjectSelectField();
      default: return null;
    }
  };

  renderTextField = () => (
    <Field
      name={this.props.name}
      component={TextField}
    />
  );

  renderNumberField = () => (
    <Field
      type="number"
      parse={str => parseFloat(str)}
      name={this.props.name}
      component={TextField}
    />
  );

  renderDottedNumberField = () => (
    <Field
      type="number"
      name={this.props.name}
      component={TextField}
      {...dottedNumberMask}
    />
  );

  renderBrCurrencyField = () => (
    <Field
      name={this.props.name}
      component={TextField}
      {...brCurrencyMask}
    />
  );

  renderPercentageField = () => (
    <Field
      name={this.props.name}
      component={TextField}
      {...percentageMask}
    />
  );

  renderDateField = () => (
    <Field
      name={this.props.name}
      component={TextField}
      {...dateMask}
    />
  );

  renderCheckboxField = () => (
    <FormControlLabel
      disabled={!this.props.editable}
      classes={{ root: this.props.classes.checkboxWrapper }}
      control={(
        <Field
          name={this.props.name}
          component={Checkbox}
          classes={{ root: this.props.classes.checkboxRoot }}
          color="primary"
          onChange={() => setTimeout(this.props.handleSubmit, 0)}
        />
        )}
    />
  );

  renderSelectField = () => (
    <FormControl>
      <Field
        name={this.props.name}
        component={Select}
        onChange={() => setTimeout(this.props.handleSubmit, 0)}
      >
        {this.props.options.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
      </Field>
    </FormControl>
  );

  renderObjectSelectField = () => {
    const { name, options, meta } = this.props;
    return (
      <FormControl>
        <Field
          name={name}
          component={Select}
          onChange={() => setTimeout(this.props.handleSubmit, 0)}
        >
          <MenuItem key={-1} value=""><em>Nenhum</em></MenuItem>
          {options && options.map && options.map(option => (
            <MenuItem
              key={option[meta.valueKey]}
              value={option[meta.valueKey]}
            >
              {option[meta.labelKey]}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    );
  };

}

ConfTableCellEditorField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  editable: PropTypes.bool,
  type: PropTypes.string,
  options: PropTypes.array,
  meta: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

ConfTableCellEditorField.defaultProps = {
  value: '',
  editable: false,
  type: 'text',
  options: [],
  meta: null,
};

export default withStyles(style)(ConfTableCellEditorField);
