import React from 'react';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import RegularCard from '../../mui-components/Card/RegularCard';
import MyClientsTable from '../../containers/domain/client/MyClientsTable';

const ClientsListingView = () => (
  <GridContainer>
    <ItemGrid xs={12}>
      <RegularCard
        title="Meus clientes"
        content={<MyClientsTable />}
      />
    </ItemGrid>
  </GridContainer>
);

export default ClientsListingView;
