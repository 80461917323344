const contractTypeTableColumns = [
  {
    name: 'name',
    title: 'Nome',
    editable: true,
    width: 170,
  },
  {
    name: 'registrationFee',
    title: 'Taxa de registro',
    editable: true,
    type: 'percentage',
    width: 127,
  },
  {
    name: 'value',
    title: 'Valor',
    editable: true,
    type: 'brCurrency',
    width: 77,
  },
  {
    name: 'recurrence',
    title: 'Recorrência',
    editable: true,
    type: 'select',
    options: ['Anual', 'Mensal', 'Pontual'],
    width: 103,
  },
  {
    name: 'continued',
    title: 'Continuado',
    editable: true,
    type: 'checkbox',
    width: 103,
  },
];

export default contractTypeTableColumns;
