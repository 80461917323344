import { store } from '../../../store';
import partnerEnterpriseActions from '../../../actions/partnerEnterpriseActions';
import sortObjectsArrayByProperty from '../../../utils/sortObjectsArrayByProperty';

const notIdentifiedSpreadsheetIncomesTableColumns = [
  {
    name: 'clientIdentifier',
    title: 'Identificador',
    width: 138,
  },
  {
    name: 'partnerEnterpriseId',
    title: 'Fornecedor',
    type: 'object-select',
    meta: { valueKey: 'id', labelKey: 'name' },
    options: [],
    async: {
      fetch: () => store.dispatch(partnerEnterpriseActions.fetchAll()),
      fetchDelay: 500,
      mapSuccessfulResult: response => sortObjectsArrayByProperty(Object.values(response.data.entities.partnerEnterprises), 'name'),
      mapProps: ({ response, ...others }) => ({ ...others, options: response }),
    },
    getCellValue: row => (row.partnerEnterprise || {}).name,
    width: 121,
  },
  {
    name: 'pairingCorrectionDate',
    title: 'Data',
    width: 121,
  },
  {
    name: 'extractedLine',
    title: 'Linha extraída',
    width: 4000,
  },
];

export default notIdentifiedSpreadsheetIncomesTableColumns;
