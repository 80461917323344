import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import AsyncField from '../../containers/general/form/AsyncField';
import TextFilter from './TextFilter';
import NumericRangeFilter from './NumericRangeFilter';
import DottedNumericRangeFilter from './DottedNumericRangeFilter';
import BrCurrencyRangeFilter from './BrCurrencyRangeFilter';
import PercentageRangeFilter from './PercentageRangeFilter';
import DateRangeFilter from './DateRangeFilter';
import OnOffFilter from './OnOffFilter';
import CheckboxesFilter from './CheckboxesFilter';
import ObjectSelectFilter from './ObjectSelectFilter';

const style = {
  fieldWrapper: {
    marginBottom: '15px',
    maxWidth: '600px',
  },
};

class GenericFilter extends React.Component {

  render() {
    const { async, type, width, classes, ...fieldProps } = this.props;
    if (async) {
      return (
        <AsyncField
          config={async}
          fieldProps={{ type, width, classes, ...fieldProps }}
          fieldComponent={GenericFilter}
        />
      );
    }

    return (
      <div className={classes.fieldWrapper} style={{ width }}>
        {this.getFilterByType(type, fieldProps)}
      </div>
    );
  }

  getFilterByType = (type, fieldProps) => {
    switch (type) {
      case 'text':
        return <TextFilter {...fieldProps} />;
      case 'number':
        return <NumericRangeFilter {...fieldProps} />;
      case 'dottedNumber':
        return <DottedNumericRangeFilter {...fieldProps} />;
      case 'brCurrency':
        return <BrCurrencyRangeFilter {...fieldProps} />;
      case 'percentage':
        return <PercentageRangeFilter {...fieldProps} />;
      case 'date':
        return <DateRangeFilter {...fieldProps} />;
      case 'checkbox':
        return <OnOffFilter {...fieldProps} />;
      case 'select':
        return <CheckboxesFilter {...fieldProps} />;
      case 'object-select':
        return <ObjectSelectFilter {...fieldProps} />;
      default:
        return null;
    }
  };

}

GenericFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.oneOf([
    'text',
    'number',
    'dottedNumber',
    'brCurrency',
    'percentage',
    'date',
    'checkbox',
    'select',
    'object-select',
  ]),
  async: PropTypes.object,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

GenericFilter.defaultProps = {
  type: 'text',
  async: undefined,
  width: undefined,
};

export default withStyles(style)(GenericFilter);
