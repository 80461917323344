import generateBasicEntityActions from './generateBasicEntityActions';
import * as schemas from '../schemas';

const basicEntityActions = generateBasicEntityActions('employees');
const employeeActions = {
  fetchAllSimple: () => ({
    API_CALL: {
      httpMethod: 'GET',
      endpoint: 'simpleEmployees',
      schema: { employees: [schemas.employee] },
    },
  }),
  ...basicEntityActions,
}

export default employeeActions;
