import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import withStyles from '@material-ui/core/styles/withStyles';

import FileInput from '../miscellaneous/ReduxFormFileInput';

const style = {
  fieldWrapper: {
    margin: '30px 0',
  },
};

const ConfFormFileField = ({ name, label, disabled, classes }) => (
  <div className={classes.fieldWrapper}>
    <h5>{label}</h5>
    <Field
      name={name}
      disabled={disabled}
      component={FileInput}
    />
  </div>
);

ConfFormFileField.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

ConfFormFileField.defaultProps = {
  label: '',
  disabled: false,
};

export default withStyles(style)(ConfFormFileField);
