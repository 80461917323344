import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from 'redux-form-material-ui';

const ConfFormCheckboxField = ({ name, label, disabled }) => (
  <FormControlLabel
    label={label}
    disabled={disabled}
    control={
      <Field name={name} component={Checkbox} />
    }
  />
);

ConfFormCheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ConfFormCheckboxField.defaultProps = {
  disabled: false,
};

export default ConfFormCheckboxField;
