import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import MuiButton from '../../mui-components/CustomButtons/Button';

const style = {
  buttonSpinner: {
    position: 'relative',
    left: -6,
    marginRight: 6,
    color: 'white',
  },
};

const Button = ({ classes, loading, children, ...others }) => (
  <MuiButton {...others}>
    {loading && <CircularProgress className={classes.buttonSpinner} size={18} />}
    {children}
  </MuiButton>
);

Button.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  children: PropTypes.any.isRequired,
};

Button.defaultProps = {
  loading: false,
};

export default withStyles(style)(Button);
