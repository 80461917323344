import { schema } from 'normalizr';

export const profession = new schema.Entity('professions');

export const employee = new schema.Entity('employees');
export const client = new schema.Entity('clients');

export const task = new schema.Entity('tasks');
export const contractType = new schema.Entity('contractTypes');
export const spreadsheet = new schema.Entity('spreadsheets');
export const clientSpreadsheet = new schema.Entity('clientSpreadsheets');
export const spreadsheetIncome = new schema.Entity('spreadsheetIncomes');
export const internEnterprise = new schema.Entity('internEnterprises');
export const partnerEnterprise = new schema.Entity('partnerEnterprises');
export const investmentFund = new schema.Entity('investmentFunds');
export const report = new schema.Entity('reports');
