import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

class FileInput extends React.Component {

  onChange = (files) => {
    if (this.props.input) {
      const { input: { onChange } } = this.props;
      onChange(files[0]);
    } else if (this.props.onChange) {
      this.props.onChange(files[0]);
    }
  };

  render() {
    return <Dropzone onDrop={this.onChange} {...this.props} />;
  }
}

FileInput.propTypes = {
  input: PropTypes.object,
  onChange: PropTypes.func,
};

FileInput.defaultProps = {
  input: null,
  onChange: () => {},
};

export default FileInput;
