import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextField as MuiTextField } from 'redux-form-material-ui';
import dottedNumberMask from '../../utils/masks/dottedNumberMask';

const ConfFormDottedNumberField = ({ name, label, disabled }) => (
  <Field
    disabled={disabled}
    name={name}
    label={label}
    fullWidth
    component={MuiTextField}
    {...dottedNumberMask}
  />
);

ConfFormDottedNumberField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ConfFormDottedNumberField.defaultProps = {
  disabled: false,
};

export default ConfFormDottedNumberField;
