import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormControl from '@material-ui/core/FormControl';
import { Select } from 'redux-form-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';

const style = {
  selectWrapper: {
    width: '100%',
  },
};

const ConfFormSelectField = ({ name, label, options, disabled, classes }) => (
  <FormControl className={classes.selectWrapper}>
    <InputLabel htmlFor={name}>{label}</InputLabel>
    <Field
      name={name}
      placeholder={label}
      disabled={disabled}
      component={Select}
    >
      {options.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
    </Field>
  </FormControl>
);

ConfFormSelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  disabled: PropTypes.bool,
};

ConfFormSelectField.defaultProps = {
  options: [],
  disabled: false,
};

export default withStyles(style)(ConfFormSelectField);
