import React from 'react';
import PropTypes from 'prop-types';
import ConfTableOperationsCell from './ConfTableOperationsCell';
import ConfTableEditableCell from './ConfTableEditableCell';
import ConfTableNonEditableCell from './ConfTableNonEditableCell';

const ConfTableBaseCell = (props) => {
  const { column } = props;

  if (column.type === 'operations') {
    return <ConfTableOperationsCell {...props} />;
  }

  if (column.editable) {
    return <ConfTableEditableCell {...props} />;
  } 
  return <ConfTableNonEditableCell {...props} />;
  
};

ConfTableBaseCell.propTypes = {
  column: PropTypes.object.isRequired,
};

export default ConfTableBaseCell;
