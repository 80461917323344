import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import authActions from '../../../actions/authActions';
import LoginForm from '../../../components/domain/user/LoginForm/index';
import notificationActions from '../../general/notification/NotificationsManager/actions';

class Login extends React.Component {

  submit = ({ email, password }) => (
    this.login(email, password)
      .catch(this.onLoginFail)
  );

  login = (email, password) => {
    const { dispatch } = this.props;
    const action = authActions.login(email, password);
    return dispatch(action);
  };

  onLoginFail = () => {
    const { dispatch } = this.props;
    const message = 'Operação falhou. Não foi possível realizar a autenticação para acesso ao sistema.';
    const color = 'danger';
    const place = 'tc';
    dispatch(notificationActions.showNotification(message, color, place));
  };

  render() {
    return (
      <LoginForm form="login" onSubmit={this.submit} />
    );
  }
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(Login);
