
import internEnterpriseActions from '../../../actions/internEnterpriseActions';
import internEnterpriseInputFields from './internEnterpriseInputFields';
import generateEntityEditor from '../../../hocs/generateEntityEditor';

const InternEnterpriseEditor = generateEntityEditor({
  entitySingularString: 'internEnterprise',
  entityPluralString: 'internEnterprises',
  entityActions: internEnterpriseActions,
  returnRoutePath: '/intern-enterprises-listing',
  fields: internEnterpriseInputFields,
});

export default InternEnterpriseEditor;
