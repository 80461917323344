import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextField as MuiTextField } from 'redux-form-material-ui';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import runtimeEnv from '@mars/heroku-js-runtime-env';

const getApiBaseUrl = () => {
  const env = runtimeEnv();
  const host = env.REACT_APP_API_URL;
  const protocol = env.REACT_APP_API_PROTOCOL;
  const apiPath = env.REACT_APP_API_PATH;
  return `${protocol}://${host}${apiPath}`;
};

const ConfFormTextField = ({ name, label, type, disabled }) => {
  const editorConfiguration = {
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      uploadUrl: `${getApiBaseUrl()}/upload?command=QuickUpload&type=Images&responseType=json`,

      // Define the CKFinder configuration (if necessary).
      options: {
        resourceType: 'Images'
      }
    }
  }

  const renderEditor = ({input}) => {
    return (
      <CKEditor
        editor={ ClassicEditor }
        config={ editorConfiguration }
        onChange={(event, editor) => {
          return input.onChange(editor.getData())
        }}
      />
    )
  }

  return (
    <Field
      name={name}
      label={label}
      fullWidth
      component={renderEditor}
    />
  )
}

ConfFormTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

ConfFormTextField.defaultProps = {
  type: 'text',
  disabled: false,
};

export default ConfFormTextField;
