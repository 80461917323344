import React, { Component } from 'react';

import clientSpreadsheetActions from '../../../actions/clientSpreadsheetActions';
import clientSpreadsheetInputFields from './clientSpreadsheetInputFields';
import generateEntityCreator from '../../../hocs/generateEntityCreator';

class ClientSpreadsheetCreator extends Component {
  componentWillMount() {
    const { initialValues } = this.props;
    this.DefaultFormWithProps = generateEntityCreator({
      entityActions: clientSpreadsheetActions,
      returnRoutePath: 'client-spreadsheets-listing',
      fields: clientSpreadsheetInputFields,
      submitLabel: 'Importar',
      initialValues: initialValues
    });
  }

  render() {
    const { DefaultFormWithProps } = this;
    return (<DefaultFormWithProps />);
  }
}

export default ClientSpreadsheetCreator;
