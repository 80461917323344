import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import withStyles from '@material-ui/core/styles/withStyles';

import { getPopup } from './reducer';
import buttonStyle from '../../../../assets/jss/material-dashboard-pro-react/components/buttonStyle';

const style = {
  ...buttonStyle,
  popupWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 5000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  popup: {
    position: 'relative !important',
    minWidth: '470px !important',
    maxWidth: '650px !important',
    width: 'auto !important',
    top: 'auto !important',
    left: 'auto !important',
    margin: 'auto !important',
  },
  popupContent: {
    textAlign: 'justify',
    maxHeight: window.innerHeight - 370,
    overflow: 'auto',
  },
};

const PopupsManager = ({ popup, classes }) => (
  popup.visible &&
  <div className={classes.popupWrapper}>
    <SweetAlert
      customClass={classes.popup}
      success={popup.type === 'success'}
      warning={popup.type === 'warning'}
      danger={popup.type === 'error'}
      title={popup.title}
      onConfirm={popup.onConfirm}
      onCancel={popup.onCancel}
      confirmBtnCssClass={`${classes.button} ${classes.success}`}
      cancelBtnCssClass={`${classes.button} ${classes.danger}`}
    >
      <div className={classes.popupContent}>
        {popup.content}
      </div>
    </SweetAlert>
  </div>
);

PopupsManager.propTypes = {
  popup: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  popup: getPopup(state),
});

export default connect(mapStateToProps)(withStyles(style)(PopupsManager));
