
import taskActions from '../../../actions/taskActions';
import taskTableColumns, {
  taskTableLeftFixedColumns,
  taskTableDataSortingByColumns,
  taskTableSummaries,
  TaskTableCellComponent,
} from './taskTableColumns';
import generateEntityTable from '../../../hocs/generateEntityTable';

const TasksTable = generateEntityTable({
  entityPluralString: 'tasks',
  entityActions: taskActions,
  editorRoutePath: '/task-edition',
  leftFixedColumns: taskTableLeftFixedColumns,
  dataSortingByColumns: taskTableDataSortingByColumns,
  cellComponent: TaskTableCellComponent,
  columns: taskTableColumns,
  summaries: taskTableSummaries,
});

export default TasksTable;
