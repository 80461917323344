import generateBasicEntityActions from './generateBasicEntityActions';
import * as schemas from '../schemas';

const clientSpreadsheetActions = generateBasicEntityActions('clientSpreadsheets', 'clientSpreadsheet');

clientSpreadsheetActions.create = (spreadsheet) => {
  const { file, ...spreadsheetParams } = spreadsheet;
  return {
    API_CALL: {
      httpMethod: 'POST',
      endpoint: 'clientSpreadsheets',
      params: { clientSpreadsheet: spreadsheetParams },
      attachments: { file },
      schema: { clientSpreadsheet: schemas.clientSpreadsheet },
    },
  };
};

clientSpreadsheetActions.reimport = id => ({
  API_CALL: {
    httpMethod: 'PUT',
    endpoint: `clientSpreadsheets/${id}/reimport`,
    schema: { spreadsheet: schemas.spreadsheet },
  },
});

export default clientSpreadsheetActions;
