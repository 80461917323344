import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import taskActions from '../../../actions/taskActions';
import clientTaskInputFields from './clientTaskInputFields';
import generateEntityCreator from '../../../hocs/generateEntityCreator';
import { getLoggedUser } from '../../../reducers/sessionReducer';

taskActions.create = taskActions.createAll;

class ClientsTaskCreator extends Component {
  componentWillMount() {
    const { initialValues, user } = this.props;

    this.DefaultFormWithProps = generateEntityCreator({
      formName: 'taskCreationForm',
      entityActions: taskActions,
      returnRoutePath: '/acompanhamento-de-tarefas',
      fields: clientTaskInputFields,
      initialValues: initialValues
    });
  }

  render() {
    const { DefaultFormWithProps } = this;
    return (<DefaultFormWithProps />);
  }
}

ClientsTaskCreator.propTypes = {
  initialValues: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: getLoggedUser(state),
  }
};

export default connect(mapStateToProps)(ClientsTaskCreator);
