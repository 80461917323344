
import contractTypeActions from '../../../actions/contractTypeActions';
import contractTypeInputFields from './contractTypeInputFields';
import generateEntityEditor from '../../../hocs/generateEntityEditor';

const ContractTypeEditor = generateEntityEditor({
  entitySingularString: 'contractType',
  entityPluralString: 'contractTypes',
  entityActions: contractTypeActions,
  returnRoutePath: '/contract-types-listing',
  fields: contractTypeInputFields,
});

export default ContractTypeEditor;
