import QueryString from 'query-string';

import generateBasicEntityActions from './generateBasicEntityActions';
import * as schemas from '../schemas';

const spreadsheetIncomeActions = generateBasicEntityActions('spreadsheetIncomes', 'spreadsheetIncome');

spreadsheetIncomeActions.fetchAll = () => (dispatch, getState) => {
  const { search } = getState().router.location;
  const queryParams = QueryString.parse(search);
  return dispatch({
    API_CALL: {
      httpMethod: 'GET',
      endpoint: 'spreadsheetIncomes',
      queryParams,
      schema: { spreadsheetIncomes: [schemas.spreadsheetIncome] },
    },
  });
};

spreadsheetIncomeActions.resolve = (id) => ({
  API_CALL: {
    httpMethod: 'PUT',
    endpoint: `spreadsheetIncomes/resolve/${id}`,
    schema: { spreadsheetIncomes: [schemas.spreadsheetIncome] },
  },
});

spreadsheetIncomeActions.fetchNotIdentifiedSpreadsheetIncomes = () => ({
  API_CALL: {
    httpMethod: 'GET',
    endpoint: 'spreadsheetIncomes/notIdentified',
    schema: { spreadsheetIncomes: [schemas.spreadsheetIncome] },
  },
});

export default spreadsheetIncomeActions;
