import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import withStyles from '@material-ui/core/styles/withStyles';

import GenericFilter from './GenericFilter';
import Accordion from '../../mui-components/Accordion/Accordion';
import Card from '../../mui-components/Card/Card';
import CardBody from '../../mui-components/Card/CardBody';

const style = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cardWrapper: {
    display: 'inline-block',
    margin: 8,
    maxWidth: 280,
  },
  card: {
    margin: 0,
  },
};

class FiltersPanel extends React.PureComponent {

  render() {
    return (
      <form>
        <Accordion
          collapses={[{
            title: 'Filtros',
            content: this.renderFilters(),
          }]}
        />
      </form>
    );
  }

  renderFilters() {
    const { classes, filters } = this.props;
    return (
      <div className={classes.container}>
        {filters.map(filter => (
          <div key={filter.name} className={classes.cardWrapper}>
            {this.renderFilter(filter)}
          </div>
        ))}
      </div>
    );
  }

  renderFilter = (filter) => {
    const { classes } = this.props;
    return (
      <Card className={classes.card}>
        <CardBody>
          <GenericFilter {...filter} />
        </CardBody>
      </Card>
    );
  };

}

FiltersPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
};

export default reduxForm()(withStyles(style)(FiltersPanel));
