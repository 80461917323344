import React from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';

import InfoIcon from '@material-ui/icons/Info';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import GridContainer from '../../../mui-components/Grid/GridContainer';
import ItemGrid from '../../../mui-components/Grid/GridItem';
import StatsCard from '../../../mui-components/Cards/StatsCard';
import HeaderCard from '../../../mui-components/Cards/HeaderCard';
import SimpleCard from '../../../mui-components/Cards/SimpleCard';
import RankingListItem from '../../../mui-components/Cards/RankingListItem';
import Tasks from '../../../mui-components/Tasks/Tasks';

import sanitizeValue from '../../../utils/sanitizeValue';
import cacheActions from '../../../actions/cacheActions';
import reportActions from '../../../actions/reportActions';
import taskActions from '../../../actions/taskActions';
import notificationActions from '../../../containers/general/notification/NotificationsManager/actions';
import popupActions from '../../../containers/general/popup/PopupsManager/actions';
import { getEntity } from '../../../reducers/entitiesReducer';
import { getLoggedUser } from '../../../reducers/sessionReducer';

import avatar from '../../../assets/img/faces/marc.jpg';

class MainDashboardView extends React.Component {
  componentWillMount() {
    this.onMount();
  }

  onMount = () => {
    this.props.dispatch(cacheActions.cleanCache());
    this.fetchEntities().catch(this.onFetchFail);
  };

  onFetchFail = apiResponse => this.props.dispatch(notificationActions.handleApiError(apiResponse));

  fetchEntities = () => this.props.dispatch(reportActions.dasboard());

  deleteEntity = id => this.props.dispatch(taskActions.deleteWithoutCallback(id));

  onDeleteSucceed = () => {
    const { dispatch } = this.props;
    const message = 'Operação concluída. Os dados foram excluídos com sucesso.';
    const color = 'success';
    dispatch(notificationActions.showNotification(message, color));
    this.onMount();
  };

  onDeleteFail = (apiResponse) => {
    const { dispatch } = this.props;
    const message = 'Operação falhou. Não foi possível excluir os dados.';
    const color = 'danger';
    dispatch(notificationActions.showNotification(message, color));
    dispatch(popupActions.handleApiResponse(apiResponse));
  };

  handleToggle = task => () => {
    task.done = !task.done
    this.props.dispatch(taskActions.update(task.id, task))
  };

  handleEdit = task => () => {
    const taskEditionUrl = this.props.user.accessProfile === 'Gestor' ? 'task-edition' : 'my-task-edition'
    this.props.dispatch(push(`/${taskEditionUrl}/${task.id}`));
  };

  handleDelete = task => () => {
    confirmAlert({
      title: 'Confirmação de exclusão',
      message: 'Deseja mesmo apagar o registro.',
      buttons: [{
        label: 'Sim',
        onClick: () => this.onRowDelete(task.id)
      },
      {
        label: 'Não',
        onClick: () => {}
      }]
    });
  };

  onRowDelete = (id) => {
    this.deleteEntity(id)
      .then(this.onDeleteSucceed)
      .catch(this.onDeleteFail);
  };

  render () {
    const {entity} = this.props;
    return (
      <>
        <GridContainer>
          <HeaderCard
            cardTitle="Previsão"
            headerColor="orange"
            content={
              <GridContainer>
                {entity && entity.forecasts.map((forecast, index) => {
                  let descriptionColor
                  let descriptionStyle
                  let colsLg = 2
                  let description = sanitizeValue(forecast.type, forecast.value)
                  if(index == 0 || index == 4) {
                    colsLg = 3
                  }
                  if(index <= 1) {
                    descriptionColor = 'orange'
                    descriptionStyle = 'bold'
                  }

                  return <ItemGrid key={index} xs={12} sm={6} md={6} lg={colsLg}>
                    <SimpleCard title={forecast.name} description={description} descriptionColor={descriptionColor} descriptionStyle={descriptionStyle} />
                  </ItemGrid>
                })}
              </GridContainer>
            }
          />
        </GridContainer>
        <GridContainer>
          <ItemGrid xs={12} sm={12} md={12} lg={12} className='last'>
            <GridContainer className='last'>
              {entity && entity.inlineInfos.map((info, index) => {
                const description = sanitizeValue(info.type, info.value)
                const icon = info.type === 'currency' ? AttachMoneyIcon : InfoIcon
                return <ItemGrid key={index} xs={12} sm={4} md={4} lg={4}>
                  <StatsCard icon={icon} title={info.name} description={description} />
                </ItemGrid>
              })}
            </GridContainer>
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid xs={12} sm={12} md={7} lg={7}>
            <HeaderCard
              cardTitle={`Tarefas dos próximos 7 dias (${entity ? entity.tasks.filter((task) => !task.done).length : 0})`}
              headerColor="orange"
              limitHeight={true}
              content={
                entity && <Tasks tasks={entity.tasks} handleToggle={this.handleToggle} handleEdit={this.handleEdit} handleDelete={this.handleDelete} />
              }
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={5} lg={5} className='last'>
            <GridContainer className='last'>
              {entity && entity.infos.map((info, index) => {
                const description = sanitizeValue(info.type, info.value)
                const icon = info.type === 'currency' ? AttachMoneyIcon : InfoIcon
                return <ItemGrid key={index} xs={12} sm={6} md={6} lg={6}>
                  <StatsCard icon={icon} title={info.name} description={description} />
                </ItemGrid>
              })}
            </GridContainer>
          </ItemGrid>
        </GridContainer>
        {/*<GridContainer>
          <ItemGrid xs={12} sm={12} md={12} lg={12}>
            <HeaderCard
              cardTitle="Ranking Assessores"
              headerColor="orange"
              content={
                <>
                  {entity && entity.ranking.map((ranking, index) => {
                    return <GridContainer key={index}>
                      <ItemGrid xs={12} sm={6} md={6} lg={6}>
                        <RankingListItem position={ranking.position} avatar={avatar} score={ranking.score} />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6} md={6} lg={5}>
                        <GridContainer>
                          {ranking.attributes.map((attribute, i) => {
                            return <ItemGrid key={i} xs={6} sm={4} md={4} lg={3}><SimpleCard title={attribute.name} description={attribute.value} /></ItemGrid>
                          })}
                        </GridContainer>
                      </ItemGrid>
                    </GridContainer>
                  })}
                </>
              }
            />
          </ItemGrid>
        </GridContainer>*/}
      </>
    )
  }
}

MainDashboardView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  entity: PropTypes.object,
};

const mapStateToProps = state => ({
  entity: getEntity('reports', 0)(state),
  user: getLoggedUser(state),
});

export default connect(mapStateToProps)(MainDashboardView);
