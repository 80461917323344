import React from 'react';
import PermIdentity from '@material-ui/icons/PermIdentity';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import TitleCard from '../../mui-components/Card/TitleCard';
import MyselfEditor from '../../containers/domain/employee/MyselfEditor';

const MyProfileView = () => (
  <GridContainer>
    <ItemGrid xs={12} sm={12} md={9} lg={7}>
      <TitleCard
        icon={PermIdentity}
        titleColor="primary"
        title="Atualização de assessor"
        content={<MyselfEditor id={0} />}
      />
    </ItemGrid>
  </GridContainer>
);

export default MyProfileView;
