import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { getFormValues } from 'redux-form';

import DefaultForm from '../components/conf-form/ConfForm';
import notificationActions from '../containers/general/notification/NotificationsManager/actions';
import popupActions from '../containers/general/popup/PopupsManager/actions';

const generateEntityCreator = ({
  formName = 'entityCreator',
  entityActions,
  returnRoutePath,
  fields,
  submitLabel,
  resetLabel,
  initialValues,
}) => connect(state => ({
  formValues: getFormValues(formName)(state) || {},
  state,
}))(class extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
  };

  createEntity = inputs => this.props.dispatch(entityActions.create(inputs));

  onSubmitSucceed = () => {
    const { dispatch } = this.props;
    const message = 'Operação concluída. Os dados foram cadastrados com sucesso.';
    const color = 'success';
    dispatch(notificationActions.showNotification(message, color));
    dispatch(push(returnRoutePath));
  };

  onSubmitFail = (frontendErrors, d, apiResponse) => {
    const { dispatch } = this.props;
    const message = 'Operação falhou. Não foi possível realizar o cadastro.';
    const color = 'danger';
    dispatch(notificationActions.showNotification(message, color));
    dispatch(popupActions.handleApiResponse(apiResponse));
  };

  computeFields = () => {
    const { formValues, dispatch, state } = this.props;
    if (fields instanceof Function) {
      return fields(formValues, dispatch, state);
    }
    return fields;
  }

  render() {
    const computedFields = this.computeFields();
    return (
      <DefaultForm
        form={formName}
        fields={computedFields}
        submitLabel={submitLabel || 'Cadastrar'}
        resetLabel={resetLabel || 'Limpar'}
        initialValues={initialValues}
        onSubmit={this.createEntity}
        onSubmitSuccess={this.onSubmitSucceed}
        onSubmitFail={this.onSubmitFail}
      />
    );
  }
});

export default generateEntityCreator;
