import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from 'redux-form-material-ui';

const OnOffFilter = ({ name, title }) => (
  <FormControl>
    <FormLabel>{title}</FormLabel>
    <FormControlLabel
      key="on"
      label="Marcado"
      control={<Field name={`${name}.on`} component={Checkbox} color="primary" />}
    />
    <FormControlLabel
      key="off"
      label="Não marcado"
      control={<Field name={`${name}.off`} component={Checkbox} color="primary" />}
    />
  </FormControl>
);

OnOffFilter.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default OnOffFilter;
