import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextField as MuiTextField } from 'redux-form-material-ui';

const ConfFormTextField = ({ name, label, type, disabled, multiline, rows }) => (
  <Field
    type={type}
    disabled={disabled}
    parse={type === 'number' ? str => parseFloat(str) : str => str}
    name={name}
    label={label}
    fullWidth
    rows={rows}
    multiline={multiline}
    component={MuiTextField}
  />
);

ConfFormTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

ConfFormTextField.defaultProps = {
  type: 'text',
  disabled: false,
};

export default ConfFormTextField;
