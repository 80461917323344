import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import { Card, CardContent, Typography } from '@material-ui/core';

import rankingListItemStyle from "assets/jss/material-dashboard-pro-react/components/rankingListItemStyle";

function RankingListItem({ ...props }) {
  const {
    classes,
    position,
    avatar,
    score
  } = props;
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography component="p" className={classes.cardPosition}>{position}º</Typography>
        <img src={avatar} alt="avatar" className={classes.cardAvatar} />
        <Typography component="h2" className={"headline " +classes.cardScore}>{score} pts</Typography>
      </CardContent>
    </Card>
  );
}

RankingListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  position: PropTypes.node,
  avatar: PropTypes.node,
  score: PropTypes.node,
};

export default withStyles(rankingListItemStyle)(RankingListItem);
