import * as schemas from '../schemas';

const generateBasicEntityActions = (schemaPlural, schemaSingularParam, explicitEndpointSubPath) => {
  const schemaSingular = schemaSingularParam || schemaPlural.slice(0, -1);
  const schema = { [schemaSingular]: schemas[schemaSingular] };
  const schemaArray = { [schemaPlural]: [schemas[schemaSingular]] };

  const endpointSubPath = explicitEndpointSubPath || schemaPlural;

  return {
    fetchAll: () => ({
      API_CALL: {
        httpMethod: 'GET',
        endpoint: endpointSubPath,
        schema: schemaArray,
      },
    }),

    fetch: id => ({
      API_CALL: {
        httpMethod: 'GET',
        endpoint: `${endpointSubPath}/${id}`,
        schema,
      },
    }),

    create: entity => ({
      API_CALL: {
        httpMethod: 'POST',
        endpoint: endpointSubPath,
        params: { [schemaSingular]: entity },
        schema,
      },
    }),

    update: (id, entity) => ({
      API_CALL: {
        httpMethod: 'PUT',
        endpoint: `${endpointSubPath}/${id}`,
        params: { [schemaSingular]: entity },
        schema,
      },
    }),

    delete: id => ({
      API_CALL: {
        httpMethod: 'DELETE',
        endpoint: `${endpointSubPath}/${id}`,
        schema,
        entitiesDeleted: true,
      },
    }),
  };
};

export default generateBasicEntityActions;
