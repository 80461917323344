import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import withStyles from '@material-ui/core/styles/withStyles';

import ConfFormTextField from '../../../conf-form/ConfFormTextField';
import ConfFormSelectField from '../../../conf-form/ConfFormSelectField';
import ConfFormObjectSelectField from '../../../conf-form/ConfFormObjectSelectField';
import Button from '../../../miscellaneous/Button';
import style from './style';

class SpreadsheetIncomesFilterForm extends Component {

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit}>
          {this.renderFields()}
          {this.renderButtons()}
        </form>
      </div>
    );
  }

  renderFields = () => {
    const { partnerEnterprises } = this.props;
    return (
      <div>
        <ConfFormTextField name="month" label="Mês" type="number" />
        <ConfFormTextField name="year" label="Ano" type="number" />
        <ConfFormSelectField
          name="incomeListingType"
          label="Tipo de listagem"
          options={[
            'Fechamento',
            'Financeiro',
          ]}
        />
        <ConfFormObjectSelectField
          name="partnerEnterpriseId"
          label="Fornecedor"
          options={partnerEnterprises}
          meta={{ valueKey: 'id', labelKey: 'name' }}
        />
      </div>
    );
  };

  renderButtons = () => (
    <div>
      {this.renderSubmitButton()}
      {this.renderPristineButton()}
    </div>
  );

  renderSubmitButton = () => {
    const { classes, submitLabel } = this.props;
    return (
      <Button
        muiClasses={{ root: classes.button }}
        color="primary"
        variant="raised"
        type="submit"
      >
        {submitLabel}
      </Button>
    );
  };

  renderPristineButton = () => {
    const { classes, resetLabel, reset } = this.props;
    return (
      <Button
        muiClasses={{ root: classes.button }}
        color="primary"
        variant="raised"
        onClick={reset}
      >
        {resetLabel}
      </Button>
    );
  };
}

SpreadsheetIncomesFilterForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
  resetLabel: PropTypes.string,
  partnerEnterprises: PropTypes.array.isRequired,
};

SpreadsheetIncomesFilterForm.defaultProps = {
  submitLabel: 'Buscar',
  resetLabel: 'Limpar',
};

export default reduxForm({ 
  form: 'spreadsheetIncomesFilter',
})(withStyles(style)(SpreadsheetIncomesFilterForm));
