import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ChartistGraph from 'react-chartist';

import style from '../../assets/jss/material-dashboard-pro-react/components/chartsStyle.js';
import withStyles from '@material-ui/core/styles/withStyles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';
import StatsCard from '../../mui-components/Cards/StatsCard';
import HeaderCard from '../../mui-components/Cards/HeaderCard';
import Table from '../../mui-components/Table/Table';
import {
  stackedBarChart,
  pieChart
} from "../../variables/charts";

import sanitizeValue from '../../utils/sanitizeValue';
import cacheActions from '../../actions/cacheActions';
import { getEntity } from '../../reducers/entitiesReducer';
import notificationActions from '../../containers/general/notification/NotificationsManager/actions';
import reportActions from '../../actions/reportActions';


class SpreadsheetIncomesChartsView extends React.Component {
  componentWillMount() {
    this.onMount();
  }

  onMount = () => {
    this.props.dispatch(cacheActions.cleanCache());
    this.fetchEntities().catch(this.onFetchFail);
  };

  onFetchFail = apiResponse => this.props.dispatch(notificationActions.handleApiError(apiResponse));

  fetchEntities = () => this.props.dispatch(reportActions.spreadsheetIncomes());

  render () {
    const {classes, entity} = this.props;
    const clientTableHead = ['Nome','Plano','Receita líquida'];
    let clientTableNetProfitMeanBody = []
    let clientTableNetProfitTotalBody = []
    stackedBarChart.options.axisY = {
      labelInterpolationFnc: function(value) {
        if(value >= 1000000) {
          return (value/1000000) + 'M'
        } else if (value >= 1000) {
          return (value/1000) + 'k'
        } else {
          return value
        }
      }
    }


    if(entity) {
      clientTableNetProfitMeanBody = entity.clientsByNetProfitMean.map(client => [
        client.name, ((client.activeContract || {}).contractType || {}).name, sanitizeValue('brCurrency', client.assessorNetProfitMean)
      ])
      clientTableNetProfitTotalBody = entity.clientsByNetProfitTotal.map(client => [
        client.name, ((client.activeContract || {}).contractType || {}).name, sanitizeValue('brCurrency', client.assessorNetProfitTotal)
      ])
    }

    return (
      <>
        <GridContainer>
          {entity && entity.infos.map((info, index) => {
            return <ItemGrid key={index} xs={12} sm={12} md={3} lg={3}>
              <StatsCard icon={AttachMoneyIcon} title={info.name} description={sanitizeValue(info.type, info.value)} />
            </ItemGrid>
          })}
        </GridContainer>
        <GridContainer>
          <ItemGrid xs={12} sm={12} md={12} lg={6}>
            <HeaderCard
              cardTitle="Top 10 clientes por receita média"
              headerColor="orange"
              content={<Table tableHead={clientTableHead} tableData={clientTableNetProfitMeanBody} />}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={12} lg={6}>
            <HeaderCard
              cardTitle="Top 10 clientes por receita total"
              headerColor="orange"
              content={<Table tableHead={clientTableHead} tableData={clientTableNetProfitTotalBody} />}
            />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid xs={12} sm={12} md={12} lg={12}>
            <HeaderCard
              cardTitle="Receita líquida por fornecedor no tempo"
              headerColor="orange"
              content={entity &&
                <>
                  <ChartistGraph
                    data={entity.netProfitOverTime}
                    type="Bar"
                    stackBars
                    options={stackedBarChart.options}
                    listener={stackedBarChart.animation}
                  />
                  {entity.netProfitOverTime.names.map((name, index) => {
                    return <span key={index}><i className={"fas fa-circle " + classes[`chartColor${index}`]} />{` ${name} `}</span>
                  })}
                </>
              }
            />
          </ItemGrid>
        </GridContainer>
        <GridContainer>
          <ItemGrid xs={12} sm={12} md={12} lg={12}>
            <HeaderCard
              cardTitle="Receita líquida por fornecedor nos últimos 12 meses"
              headerColor="orange"
              content={entity &&
                <>
                  <ChartistGraph
                    data={entity.netProfitByEnterprise}
                    type="Pie"
                    options={pieChart.options}
                  />
                  {entity.netProfitByEnterprise.names.map((name, index) => {
                    return <span key={index}><i className={"fas fa-circle " + classes[`chartColor${index}`]} />{` ${name} `}</span>
                  })}
                </>
              }
            />
          </ItemGrid>
        </GridContainer>
      </>
    )
  }
}

SpreadsheetIncomesChartsView.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  entity: PropTypes.object,
};

const mapStateToProps = state => ({
  entity: getEntity('reports', 1)(state),
});

export default connect(mapStateToProps)(withStyles(style)(SpreadsheetIncomesChartsView));
