/* eslint-disable react/no-string-refs */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import withStyles from '@material-ui/core/styles/withStyles';

import Header from '../../mui-components/Header/Header';
import Sidebar from '../../mui-components/Sidebar/Sidebar';
import DashboardRouter from '../../routing/DashboardRouter';
import dashboardRoutes from '../../routing/routes/dashboardRoutes';
import image from '../../assets/img/sidebar-2.jpg';
import logo from '../../assets/img/logos/ci-golden.png';
import style from './style';

class DashboardLayout extends React.Component {

  state = {
    mobileOpen: false,
    miniActive: false,
  };

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      this.scrollbar = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      this.scrollbar.destroy();
    }
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  sidebarMinimize = () => {
    this.setState({ miniActive: !this.state.miniActive });
  };

  render() {
    const { classes, ...rest } = this.props;
    const mainPanel = `${classes.mainPanel} ${ 
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
      })
    }`;

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={dashboardRoutes}
          logoText="Confiança"
          logo={logo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="blue"
          bgColor="black"
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <Header
            sidebarMinimize={this.sidebarMinimize}
            miniActive={this.state.miniActive}
            routes={dashboardRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          <div className={classes.content}>
            <div className={classes.container}>
              <DashboardRouter />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DashboardLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(DashboardLayout);
