import generateBasicEntityActions from './generateBasicEntityActions';
import * as schemas from '../schemas';

const spreadsheetActions = generateBasicEntityActions('spreadsheets', 'spreadsheet');

spreadsheetActions.defaultHeaders = id => ({
  API_CALL: {
    httpMethod: 'GET',
    endpoint: `spreadsheets/headers/${id}`,
    schema: { spreadsheet: schemas.spreadsheet },
  },
});

spreadsheetActions.create = (spreadsheet) => {
  const { file, ...spreadsheetParams } = spreadsheet;
  return {
    API_CALL: {
      httpMethod: 'POST',
      endpoint: 'spreadsheets',
      params: { spreadsheet: spreadsheetParams },
      attachments: { file },
      schema: { spreadsheet: schemas.spreadsheet },
    },
  };
};

spreadsheetActions.reimport = id => ({
  API_CALL: {
    httpMethod: 'PUT',
    endpoint: `spreadsheets/${id}/reimport`,
    schema: { spreadsheet: schemas.spreadsheet },
  },
});

export default spreadsheetActions;
