import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';

import kanbanColumnStyle from "assets/jss/material-dashboard-pro-react/components/kanbanColumnStyle";

const KanbanColumn = ({ name, content, classes }) => (
  <Card className={classes.card}>
    <Typography component="h2" className={classes.cardTitle}>{name}</Typography>
    <div className={classes.cardCards}>
      {content}
    </div>
  </Card>
);

KanbanColumn.propTypes = {
  name: PropTypes.node,
  content: PropTypes.any.isRequired,
  classes: PropTypes.any.isRequired
};

export default withStyles(kanbanColumnStyle)(KanbanColumn);
