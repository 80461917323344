import React from 'react';

import createReducer from '../../../../utils/createReducer';
import types from '../../../../actions/types';
import actions from './actions';
import { store } from '../../../../store';

const initialState = {
  visible: false,
  type: null,
  title: '',
  content: null,
  confirmBtnText: '',
  cancelBtnText: '',
  cancelBtnVisible: false,
  onConfirm: () => {},
  onCancel: () => {},
};

const buildContent = (action) => {
  if (action.error) {
    return <p>{action.error}</p>;
  }
  if (action.errors) {
    return action.errors.map((e, i) => <p key={i}>{e.detail}</p>);
  }
  return <p>Ocorreu um erro inesperado...</p>;
};

const reducer = createReducer(initialState, {

  [types.POPUP_SHOW_ERRORS]: (state, action) => ({
    ...state,
    visible: true,
    type: 'error',
    title: 'Operação falhou!',
    content: buildContent(action),
    confirmBtnText: 'OK',
    cancelBtnVisible: false,
    onConfirm: () => store.dispatch(actions.closePopup()),
    onCancel: () => store.dispatch(actions.closePopup()),
  }),

  [types.POPUP_CLOSE]: state => ({
    ...state,
    visible: false,
  }),

});

export const getPopup = state => state.ducks.PopupsManager;

export default reducer;
