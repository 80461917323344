import types from '../../../../actions/types';

const openErrorsPopup = errors => ({
  type: types.POPUP_SHOW_ERRORS,
  errors,
});

const openErrorPopup = errorMessage => ({
  type: types.POPUP_SHOW_ERRORS,
  error: errorMessage,
});

const closePopup = () => ({
  type: types.POPUP_CLOSE,
});

const handleApiResponse = response => (dispatch) => {
  const { data, httpStatus } = response;
  const { errors } = data;
  let formattedErrors;

  if (httpStatus === 0) {
    formattedErrors = [{ detail: 'O servidor não está respondendo.' }];
  } else if (httpStatus >= 500) {
    formattedErrors = [{ detail: `Ocorreu um erro inesperado (${httpStatus})` }];
  } else if (errors && errors.length !== 0) {
    formattedErrors = errors;
  }
  
  dispatch(openErrorsPopup(formattedErrors));
};

export default {
  openErrorsPopup,
  openErrorPopup,
  closePopup,
  handleApiResponse,
};
