import loginPageStyle from '../../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle';

const style = theme => ({

  ...loginPageStyle(theme),

  cardHidden: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)',
  },

  fieldWrapper: {
    marginBottom: '14px',
  },

  inputAdornment: {
    marginRight: '18px',
  },

  inputAdornmentIcon: {
    color: '#555',
  },

  button: {
    marginTop: '30px',
  },

});

export default style;
