import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withFetch from '../../../hocs/withFetch';


class AsyncField extends Component {

  componentWillMount() {
    const { config, fieldComponent } = this.props;
    this.FieldComponent = withFetch(config)(fieldComponent);
  }

  render = () => {
    const { fieldProps } = this.props;
    const { FieldComponent } = this;
    return <FieldComponent {...fieldProps} />;
  };
}

AsyncField.propTypes = {
  config: PropTypes.object.isRequired,
  fieldProps: PropTypes.object.isRequired,
  fieldComponent: PropTypes.any.isRequired,
};

export default AsyncField;
