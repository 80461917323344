import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

const AuthRoute = (props) => {
  if (props.permission) {
    return <PrivateRoute {...props} />;
  }
  return <Route {...props} />;
};

AuthRoute.propTypes = {
  permission: PropTypes.object,
};

AuthRoute.defaultProps = {
  permission: undefined,
};

export default AuthRoute;
