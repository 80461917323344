import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import AuthRoute from './AuthRoute';
import dashboardRoutes from './routes/dashboardRoutes';

const DashboardRouter = () => (
  <Switch>
    {dashboardRoutes.map((route, key) => {
      if (route.redirect) {
        return <Redirect from={route.path} to={route.pathTo} key={key} />;
      }
      if (route.collapse) {
        return route.views.map((viewRoute, index) => (
          <AuthRoute {...viewRoute} key={index} />
        ));
      }
      return <AuthRoute {...route} key={key} />;
    })}
  </Switch>
);

export default DashboardRouter;
