import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';

import AuthRoute from './AuthRoute';
import rootRoutes from './routes/rootRoutes';

const RootRouter = ({ history }) => (
  <ConnectedRouter history={history}>
    <Switch>
      {rootRoutes.map((route, key) => <AuthRoute key={key} {...route} />)}
    </Switch>
  </ConnectedRouter>
);

RootRouter.propTypes = {
  history: PropTypes.object.isRequired,
};

export default RootRouter;
