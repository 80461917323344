import { IntegratedSummary } from '@devexpress/dx-react-grid';

const confTableSummaryMessages = {
  count: 'Nº',
  sum: 'Total',
  intSum: 'Total',
  floatSum: 'Total',
  brCurrencySum: 'Total',
  brCurrencyMean: 'Média',
};

const convertNumber = (number) => {
  let result = number;
  if (isNaN(result)) {
    result = parseFloat(result);
  }
  if (isNaN(result)) {
    result = 0;
  }
  return result;
};

const confTableSummaryCalculator = (type, rows, getValue) => {
  if (type === 'brCurrencySum') {
    if (!rows.length) {
      return null;
    }
    const sum = rows.reduce((acc, row) => {
      let number = getValue(row);
      if (number) {
        number = convertNumber(number);
        return acc + number;
      }
      return acc;
    }, 0);
    return sum.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  } else if (type === 'brCurrencyMean') {
    if (!rows.length) {
      return null;
    }
    const sum = rows.reduce((acc, row) => {
      let number = getValue(row);
      if (number) {
        number = convertNumber(number);
        return acc + number;
      }
      return acc;
    }, 0);
    const count = rows.reduce((count, row) => {
      let number = getValue(row);
      return number ? count + 1 : count;
    }, 0);
    return (sum/(count || 1)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
  return IntegratedSummary.defaultCalculator(type, rows, getValue);
};

export { confTableSummaryMessages };
export default confTableSummaryCalculator;
