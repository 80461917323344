// ##############################
// // // RankingListItem styles
// #############################

import {
  card,
  defaultFont,
  grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const funnelItemStyle = {
  card: {
    ...card,
    boxShadow: "none",
  },
  cardContent: {
    textAlign: "center",
    paddingTop: "10px",
    padding: "15px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around"
  },
  cardButton: {
    background: grayColor,
    borderRadius: "10px",
    padding: "2em 10px !important"
  },
  cardTitle: {
    ...defaultFont,
    margin: "0",
    fontSize: "1.3em",
    textAlign: "left",
    fontWeight: "bold",
    textAlign: "center",
    color: "#FFF",
  },
  cardDescription: {
    ...defaultFont,
    margin: "0",
    fontSize: "1em",
    textAlign: "center"
  },
  cardDate: {
    ...defaultFont,
    margin: "0",
    fontSize: "1em",
    borderLeft: "solid 1px rgba(0,0,0,0.1)",
    padding: "1.5em 0"
  },
};

export default funnelItemStyle;
