export default {

  button: {
    marginTop: '36px',
    marginRight: '10px',
    backgroundColor: '#cca656',
  },

  input: {
    marginBottom: '5px',
  },

};
