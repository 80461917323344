import React from 'react';
import PropTypes from 'prop-types';

import { Table } from '@devexpress/dx-react-grid-material-ui';
import ConfTableCellEditor from './ConfTableCellEditor';
import ConfTableBaseCellValue from './ConfTableBaseCellValue';


class ConfTableEditableCell extends React.Component {

  state = {
    editable: false,
  };

  formName = `${Math.random()}CellEditor`;

  enableEdition = () => this.setState({ editable: true });

  finishEdition = () => this.setState({ editable: false });

  isEditable = () => {
    const { editable } = this.state;
    const { type } = this.props.column;
    return editable || type === 'checkbox';
  }

  isNotEditable = () => !this.isEditable();

  commitValueChange = (inputs) => {
    const { editorField: value } = inputs;
    const { row, column } = this.props;
    if (column.onFieldUpdate) {
      column.onFieldUpdate(row.id, column.name, value);
    }
    this.finishEdition();
  };

  render() {
    return (
      <Table.Cell {...this.props}>
        {this.isEditable() && this.renderInputToEdit()}
        {this.isNotEditable() && this.renderCellValue()}
      </Table.Cell>
    );
  }

  renderInputToEdit = () => {
    const { value, column } = this.props;
    const initialValues = { editorField: value };
    return (
      <ConfTableCellEditor
        form={this.formName}
        initialValues={initialValues}
        onSubmit={this.commitValueChange}
        column={column}
      />
    );
  };

  renderCellValue = () => {
    const { value, column } = this.props;
    return (
      <div
        style={{ minHeight: 20, color: '#059' }}
        onClick={this.enableEdition}
      >
        <ConfTableBaseCellValue value={value} column={column} />
      </div>
    );
  };

}

ConfTableEditableCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

ConfTableEditableCell.defaultProps = {
  value: '',
};

export default ConfTableEditableCell;
