const chartsStyle = {
  chartColor0: {color: '#00bcd4'},
  chartColor1: {color: '#f05b4f'},
  chartColor2: {color: '#f4c63d'},
  chartColor3: {color: '#d17905'},
  chartColor4: {color: '#453d3f'},
  chartColor5: {color: '#59922b'},
  chartColor6: {color: '#0544d3'},
  chartColor7: {color: '#6b0392'},
  chartColor8: {color: '#f05b4f'},
  chartColor9: {color: '#dda458'},
  chartColor10: {color: '#eacf7d'},
  chartColor11: {color: '#86797d'},
  chartColor12: {color: '#b2c326'},
  chartColor13: {color: '#6188e2'},
  chartColor14: {color: '#a748ca'},
  warning: {
    color: '#f4c63d'
  },
  danger: {
    color: '#00bcd4'
  },
  info: {
    color: '#f05b4f'
  }
};

export default chartsStyle;
