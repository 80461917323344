import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import AsyncField from '../../containers/general/form/AsyncField';
import ConfFormTextField from './ConfFormTextField';
import ConfFormRedactorField from './ConfFormRedactorField';
import ConfFormDottedNumberField from './ConfFormDottedNumberField';
import ConfFormBrCurrencyField from './ConfFormBrCurrencyField';
import ConfFormPercentageField from './ConfFormPercentageField';
import ConfFormDateField from './ConfFormDateField';
import ConfFormHourField from './ConfFormHourField';
import ConfFormCheckboxField from './ConfFormCheckboxField';
import ConfFormSelectField from './ConfFormSelectField';
import ConfFormObjectSelectField from './ConfFormObjectSelectField';
import ConfFormFileField from './ConfFormFileField';
import ConfFormFieldGroup from './ConfFormFieldGroup';
import ConfFormFieldArray from './ConfFormFieldArray';
import ConfFormFieldsAccordion from './ConfFormFieldsAccordion';

const style = {
  fieldWrapper: {
    marginBottom: '15px',
    maxWidth: '600px',
  },
};

class ConfFormGenericField extends React.Component {

  render() {
    const { async, type, width, notRenderable, classes, ...fieldProps } = this.props;

    if (notRenderable) {
      return <div />;
    }

    if (async) {
      return (
        <AsyncField
          config={async}
          fieldProps={{ type, width, classes, ...fieldProps }}
          fieldComponent={ConfFormGenericField}
        />
      );
    }

    return (
      <div className={classes.fieldWrapper} style={{ width }}>
        {this.getFieldByType(type, fieldProps)}
      </div>
    );
  }

  getFieldByType = (type, fieldProps) => {
    switch (type) {
      case 'text':
        return <ConfFormTextField {...fieldProps} />;
      case 'textarea':
        return <ConfFormRedactorField {...fieldProps} />;
      case 'password':
        return <ConfFormTextField {...fieldProps} type="password" />;
      case 'number':
        return <ConfFormTextField {...fieldProps} type="number" />;
      case 'dottedNumber':
        return <ConfFormDottedNumberField {...fieldProps} />;
      case 'brCurrency':
        return <ConfFormBrCurrencyField {...fieldProps} />;
      case 'percentage':
        return <ConfFormPercentageField {...fieldProps} />;
      case 'date':
        return <ConfFormDateField {...fieldProps} />;
      case 'hour':
        return <ConfFormHourField {...fieldProps} />;
      case 'checkbox':
        return <ConfFormCheckboxField {...fieldProps} />;
      case 'select':
        return <ConfFormSelectField {...fieldProps} />;
      case 'object-select':
        return <ConfFormObjectSelectField {...fieldProps} />;
      case 'file':
        return <ConfFormFileField {...fieldProps} />;
      case 'group':
        return <ConfFormFieldGroup {...fieldProps} />;
      case 'array':
        return <ConfFormFieldArray {...fieldProps} />;
      case 'accordion':
        return <ConfFormFieldsAccordion {...fieldProps} />;
      default:
        return null;
    }
  };

}

ConfFormGenericField.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.oneOf([
    'text',
    'textarea',
    'password',
    'number',
    'dottedNumber',
    'brCurrency',
    'percentage',
    'date',
    'checkbox',
    'select',
    'object-select',
    'file',
    'group',
    'array',
  ]),
  notRenderable: PropTypes.bool,
  async: PropTypes.object,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

ConfFormGenericField.defaultProps = {
  type: 'text',
  notRenderable: false,
  async: undefined,
  width: undefined,
};

export default withStyles(style)(ConfFormGenericField);
