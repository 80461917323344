const isLocalProd = () => process.env.REACT_APP_LOCAL_ENV === 'production';
const isLocalDev = () => !isLocalProd();
const isBuildDebug = () => process.env.NODE_ENV === 'development';
const isDebug = () => isBuildDebug() || isLocalDev();

export default {
  isLocalProd,
  isLocalDev,
  isBuildDebug,
  isDebug,
};
