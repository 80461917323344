import * as schemas from '../schemas';
import types from './types';

const myClientActions = {

  fetch: () => ({
    API_CALL: {
      httpMethod: 'GET',
      endpoint: 'me/employees',
      schema: { employee: schemas.employee },
    },
  }),

  update: (id, entity) => ({
    API_CALL: {
      successType: types.ME_UPDATED,
      httpMethod: 'PUT',
      endpoint: 'me/employees',
      params: { employee: entity },
      schema: { employee: schemas.employee },
    },
  }),

};

export default myClientActions;
